import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Paper,
  Popover,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LaunchIcon from "@mui/icons-material/Launch";
import { RuleEngineTestUiUrl } from "api/rulesEngine/constants";

const OpenRulesEngineTestUIButton = ({
  executeOnMenuItemSelect = false,
}: {
  executeOnMenuItemSelect?: boolean;
}) => {
  //State
  const anchorRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<number>(() => {
    // Check localStorage for the saved value, default to 0 if not found
    const saved = localStorage.getItem("productline.openRulesEngineUrlIndex");
    return saved === null ? 0 : parseInt(saved, 10);
  });

  //Define actions for the button
  const actions: string[] = ["Open Sandbox Rules UI", "Open Dev Rules UI"];

  //useEffects

  useEffect(() => {
    //persist change of button action to localstorage
    localStorage.setItem(
      "productline.openRulesEngineUrlIndex",
      selectedAction.toString()
    );
  }, [selectedAction]);

  //Private Methods

  const initiateAction = async (optionIndex: number) => {
    let url: string = RuleEngineTestUiUrl.SANDBOX;
    switch (optionIndex) {
      case 0: //Sandbox
        url = RuleEngineTestUiUrl.SANDBOX;
        break;

      case 1: //DEV
        url = RuleEngineTestUiUrl.DEV;
        break;

      default:
        break;
    }

    //Launch the url to another window
    window.open(url, "_blank", "noopener,noreferrer");
  };

  //Event Handlers

  const handleClick = () => {
    initiateAction(selectedAction);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedAction(index);
    setMenuOpen(false);

    //Execute the click action if props tell us to do that
    if (executeOnMenuItemSelect) initiateAction(index);
  };

  const handleMenuToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="outlined"
        size="small"
        color="primary"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button startIcon={<LaunchIcon />} onClick={handleClick}>
          {actions[selectedAction]}
        </Button>
        <Button size="small" onClick={handleMenuToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popover
        id="split-button-menu-popover"
        open={menuOpen}
        onClose={handleMenuClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper>
          <MenuList id="split-button-menu" variant="selectedMenu" autoFocusItem>
            {actions.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === selectedAction}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popover>
    </React.Fragment>
  );
};

export default OpenRulesEngineTestUIButton;
