import React from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { AddCircle } from "assets/logos/icons/MediumIcons";
import { ListView, CardView } from "assets/logos/icons/MicroIcons";

export default function ActionBar({
  activeTab,
  setTab,
}: {
  activeTab?: string;
  setTab?: any;
}) {
  return (
    <Row>
      <NewProgramButton>
        <AddCircle />
        <NewProgramTitle>New Program Category</NewProgramTitle>
      </NewProgramButton>
      <LayoutSwitchBar>
        <ListButton onClick={() => setTab("List")} activeTab={activeTab}>
          <ListView />
        </ListButton>
        <GridButton onClick={() => setTab("Grid")} activeTab={activeTab}>
          <CardView />
        </GridButton>
      </LayoutSwitchBar>
    </Row>
  );
}

const Row = styled.div`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
`;

const NewProgramButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;
const NewProgramTitle = styled.p`
  font-family: Roboto;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  color: ${colors.secondary};
  margin-left: 4px;
`;

const LayoutSwitchBar = styled.div`
  display: flex;
  flex-direction: row;
`;

const ListButton = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-width: 1px;
  border-color: ${colors.tables.borders};
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;
  border-style: solid;
  background-color: ${(props) =>
    props.activeTab === "List"
      ? colors.background.primaryLabels
      : colors.white};
`;

const GridButton = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-width: 1px;
  border-color: ${colors.tables.borders};
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
  border-style: solid;
  border-left-width: 0px;
  background-color: ${(props) =>
    props.activeTab === "Grid"
      ? colors.background.primaryLabels
      : colors.white};
`;
