import { Program } from "store/reducers/programs/programs.types";
import { validateOrReject } from "class-validator";

export const validateNameStep = async ({
  name,
  type,
  region,
}: Partial<Program>): Promise<true | false | Array<any>> => {
  // this avoids a Typescript error, see TS known limitation with Partials: https://stackoverflow.com/questions/54489817/typescript-partialt-type-without-undefined
  if (!name || !type || !region) return false;

  const program = new Program();

  program.name = name;
  program.type = type;
  program.region = region;

  try {
    await validateOrReject(program, {
      skipMissingProperties: true,
      forbidUnknownValues: true,
    });

    return true;
  } catch (errors) {
    return errors;
  }
};
