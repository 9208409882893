import React from "react";
import styled from "styled-components";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { InputOptionEnum } from "../../pages/Products/components/RulesTable/RulesTableCell";

export function ContextMenuComponent({
  uniqueId,
  changeAction,
  options,
}: {
  uniqueId: string;
  deleteAction?: () => void;
  changeAction: React.Dispatch<React.SetStateAction<InputOptionEnum>>;
  options: InputOptionEnum[];
}) {
  const handleClick = (e, data) => {
    changeAction(data.inputType);
  };

  return (
    <StyledContextMenu id={uniqueId}>
      {options.map((inputType) => {
        return (
          <StyledMenuItem
            data={{ inputType: inputType }}
            key={uniqueId + inputType}
            onClick={handleClick}
            disabled={false}
          >
            {inputType}
          </StyledMenuItem>
        );
      })}
    </StyledContextMenu>
  );
}

const StyledContextMenu = styled(ContextMenu)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 998;
`;

const StyledMenuItem = styled(MenuItem)<any>`
  box-sizing: border-box;
  padding: 8px;
  z-index: 999;
  background-color: ${(props) => (props.disabled ? "#AAA" : "white")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 12px;
  :hover {
    background: ${(props) =>
      props.disabled ? "#AAA" : props.theme.colors.secondary};
    color: ${(props) =>
      props.disabled ? props.theme.colors.primary : "white"};
  }
`;
