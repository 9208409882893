import React from "react";
import styled from "styled-components";
import { H3 } from "components/Typography/Headlines.component";
import { useTypedSelector } from "store";
import Grid from "@mui/material/Unstable_Grid2";
import ExportAndUploadProductLineButton from "../../../pages/Products/pages/ProductDetail/ProductOverview/ExportAndUploadProductLineButton";
import ProductLineDatabaseExportButton from "../../../pages/Products/pages/ProductDetail/ProductOverview/ProductLineDatabaseExportButton.component";
import OpenRulesEngineTestUIButton from "../../../pages/Products/pages/ProductDetail/ProductOverview/OpenRulesEngineTestUIButton";

export default function ProductLineDetailsHeader() {
  const { selectedProductLine } = useTypedSelector((state) => state.products);
  return (
    <Box>
      <Grid container spacing={2} display="flex">
        <Grid xs={3}>
          <H3 style={{ marginLeft: 20, height: "100%" }}>
            {selectedProductLine?.name || "Product Lines"}
          </H3>
        </Grid>
        <Grid xs={3} display="flex" alignItems="center" justifyContent="center">
          {selectedProductLine && (
            <ExportAndUploadProductLineButton executeOnMenuItemSelect />
          )}
        </Grid>
        <Grid xs={3} display="flex" alignItems="center" justifyContent="center">
          {selectedProductLine && <ProductLineDatabaseExportButton />}
        </Grid>
        <Grid xs={3} display="flex" alignItems="center" justifyContent="center">
          <OpenRulesEngineTestUIButton executeOnMenuItemSelect />
        </Grid>
      </Grid>
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
