import { UsersState } from "./pl-users.types";
import { SET_PL_USER, SET_PL_USERS, SET_VERB } from "./pl-users.actions";

// the users state is not a {byId: [], allIds: []} because backend only gives us an array of Ids
// matching with 'carrierUsers' would involve a race condition
// that we don't want to deal with inside reducer logic
export const plUsersInitialState: UsersState = {
  users: [],

  getting: false,
  posting: false,
  deleting: false,
};

export function plUsersReducer(
  usersState: UsersState = plUsersInitialState,
  action: any
) {
  switch (action.type) {
    case SET_PL_USERS:
      return {
        ...usersState,
        users: action.payload,
      };

    case SET_PL_USER: {
      return {
        ...usersState,
        users: usersState.users.map((user) => {
          if (user.id === action.payload.id) return action.payload;
          return user;
        }),
      };
    }

    case SET_VERB: {
      return {
        ...usersState,
        ...action.payload,
      };
    }
    default:
      return usersState;
  }
}
