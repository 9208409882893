import { useEffect, useRef } from "react";

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<(() => void) | null>(null);

  // After every render, save the latest callback into our ref.
  useEffect(() => {
    //console.log("BEGIN useInterval CALLBACK useEffect");
    savedCallback.current = callback;
    //console.log("END useInterval CALLBACK useEffect");
  }, [callback]);

  useEffect(() => {
    //console.log("BEGIN useInterval INTERVAL useEffect");
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    //Set up the interval, if there is a delay
    if (delay > 0) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    //console.log("END useInterval INTERVAL useEffect");
  }, [delay]);
};
