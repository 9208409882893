import React from "react";
import {
  setNewRuleset,
  changeRulesetAction,
} from "store/reducers/products/parameters/parameters.actions";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { RulesetKeys } from "store/reducers/products/parameters/pl-parameters.types";
import { useTypedSelector } from "store";
import { HoveredInputCell } from "components";
import { secureTrim } from "helpers/string";

export function SetNewRulesetNameInput({
  type = RulesetKeys.NAME,
  id,
}: {
  type: RulesetKeys;
  id?: string;
}) {
  const dispatch = useDispatch();
  const newProductLineRuleset = useTypedSelector(
    (state) => state.products.parameters.newRuleset
  );

  const handleInputKeyChange = (event) => {
    dispatch(setNewRuleset(type, event.target.value));
  };

  return (
    <StyledInput
      placeholder={type}
      onChange={handleInputKeyChange}
      value={newProductLineRuleset[type]}
      id={id}
    />
  );
}

export const addRulesetFields = [
  {
    content: (
      <SetNewRulesetNameInput type={RulesetKeys.NAME} id="addRulesetUUIDName" />
    ),
    style: {},
  },
  {
    content: (
      <SetNewRulesetNameInput
        type={RulesetKeys.DESCRIPTION}
        id="addRulesetUUIDDescription"
      />
    ),
    style: {},
  },
];

export const EditRulesetCell = ({
  rowData,
  type,
}: {
  rowData: any;
  type: string;
  id?: any;
}) => {
  const dispatch = useDispatch();
  const handleSubmit = (val) => {
    dispatch(
      changeRulesetAction({
        ...rowData,
        [type]: secureTrim(val),
      })
    );
  };
  return (
    <HoveredInputCell
      rows={type === RulesetKeys.DESCRIPTION ? 6 : 1}
      initialContent={rowData[type]}
      handleSubmit={handleSubmit}
      width={"146px"}
    />
  );
};

export const StyledInput = styled.input<any>`
  text-decoration: none;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  outline: none;
  border: none;
  background: none;
  padding: 0;

  :disabled {
    cursor: not-allowed;
  }

  :focus {
    outline: none; /* Remove default outline and use border or box-shadow */
  }
`;
