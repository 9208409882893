import React from "react";
import Select, { components } from "react-select";
import styled from "styled-components";

export default function SelectDropdownComponent({
  backgroundColor = "white",
  className,
  closeMenuOnSelect = true,
  customFontSize,
  defaultValue,
  getOptionLabel,
  getOptionValue,
  handleChange,
  height = "48px",
  hideControls = false,
  id,
  inputValue,
  isClearable = false,
  isDisabled = false,
  isMulti = false,
  isSearchable,
  name,
  noBorder = false,
  noHoverBorder = false,
  onBlur,
  onFocus,
  onInputChange,
  options,
  placeholder = "",
  size = "large",
  style = {},
  tabIndex,
  value,
}: {
  backgroundColor?: string;
  className?: string;
  closeMenuOnSelect?: boolean;
  customFontSize?: string;
  defaultValue?: Option;
  getOptionLabel?: any;
  getOptionValue?: any;
  handleChange: (any) => void;
  height?: string;
  hideControls?: boolean;
  id?: string;
  inputValue?: any;
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  name: string;
  noBorder?: boolean;
  noHoverBorder?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onInputChange?: () => void;
  options: any;
  placeholder?: string;
  size?: "large" | "small";
  style?: any;
  tabIndex?: number;
  value: any;
}) {
  return (
    <Wrapper
      className={className}
      id={id}
      style={style}
      backgroundColor={backgroundColor}
      tabIndex={tabIndex}
      noHoverBorder={noHoverBorder}
      customFontSize={customFontSize}
    >
      <StyledSelect
        backgroundColor={backgroundColor}
        classNamePrefix={"Select"}
        closeMenuOnSelect={closeMenuOnSelect}
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        height={height}
        hideControls={hideControls}
        inputValue={inputValue}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        name={name}
        noBorder={noBorder}
        onChange={handleChange}
        onInputChange={onInputChange}
        onMenuClose={onBlur}
        onMenuOpen={onFocus}
        options={options}
        placeholder={placeholder}
        size={size}
        value={value}
        components={{
          MultiValue: CustomMultiValue,
          SingleValue: CustomSingleValue,
        }}
      />
    </Wrapper>
  );
}

export type Option = {
  value: any;
  label: string;
};

export const stringArrayToOptions = (array: string[]) => {
  return array.map((elem) => {
    return {
      value: elem,
      label: elem,
    };
  });
};

export const getSingleValueFromOptions = (options: string[], currentValue) => {
  return stringArrayToOptions(options).filter(
    ({ value }) => value === currentValue
  );
};

export const getSelectedValueFromOptions = (isMulti, options) => {
  if (isMulti) return options.map((option) => option.value);
  return options.value;
};

const CustomMultiValue = (props) => {
  const { children, data } = props;

  //For BOOLEAN properties we want to show the text in red and green
  //Black for other properties
  let textColor = "black";
  if (data.label === "TRUE") {
    textColor = "mediumseagreen";
  } else if (data.label === "FALSE") {
    textColor = "crimson";
  }

  return (
    <components.MultiValue {...props}>
      <div
        style={{
          color: textColor,
        }}
      >
        {children}
      </div>
    </components.MultiValue>
  );
};

const CustomSingleValue = (props) => {
  const { children, data } = props;

  //For BOOLEAN properties we want to show the text in red and green
  //Black for other properties
  let textColor = "black";
  if (data.label === "TRUE") {
    textColor = "mediumseagreen";
  } else if (data.label === "FALSE") {
    textColor = "crimson";
  }

  return (
    <components.SingleValue {...props}>
      <div
        style={{
          color: textColor,
        }}
      >
        {children}
      </div>
    </components.SingleValue>
  );
};

const Wrapper = styled.div<any>`
  width: 100%;
`;

const StyledSelect = styled(Select)<any>`
  & .Select__control {
    min-height: ${(props) => props.height};
    background-color: ${(props) => props.backgroundColor};
    border: ${(props) => (props.noBorder ? "none" : "")};

    &:hover {
      cursor: pointer;
      border: ${(props) => (props.noBorder ? "none" : "")};
      box-shadow: ${(props) => (props.noBorder ? "none" : "")};
    }

    &:hover .Select__multi-value__remove {
      display: flex;
    }
  }

  & .Select__control--is-focused {
    border: ${(props) => (props.noBorder ? "none" : "")};
    box-shadow: ${(props) => (props.noBorder ? "none" : "")};

    & .Select__multi-value__remove {
      display: flex;
    }
  }

  & .Select__value-container {
    padding-left: ${(props) => (props.noBorder ? 0 : "16px")};
  }

  & .Select__input {
    font-size: ${(props) => (props.size === "small" ? "12px" : "14px")};
  }

  & .Select__placeholder {
    font-size: ${(props) => (props.size === "small" ? "12px" : "14px")};
  }

  & .Select__dropdown-indicator {
    padding-right: ${(props) => (props.noBorder ? 0 : "")};
  }

  & .Select__multi-value__remove {
    display: ${(props) => (props.hideControls ? "none" : "")};
  }

  & .Select__multi-value__label {
    padding: 3px;
  }
`;
