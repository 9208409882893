import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  @media ${(props) => props.theme.device.mobile} {
    flex-direction: row;
    align-items: center;
  }
`;
