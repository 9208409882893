import { SEPARATOR_PVA, SEPARATOR_SUBPROP } from "modules/constants/constants";

/**
 * Determines the parent property name from the supplied property name.
 * TODO: Will not properly handle all cases properly.  If a property name
 * contains a . but is NOT actually a subproperty, it will still return the
 * portion of the name before the first .
 * @param propertyName property name to retrieve the parent property name from
 * @returns The parent property name if it determines that it is a PVA or
 * subproperty.  The original name if neither of those.
 */
export const GetParentPropertyName = (propertyName: string): string => {
  // Check if the propertyName contains the PVA separator
  const pvaSeparatorIndex = propertyName.indexOf(SEPARATOR_PVA);
  if (pvaSeparatorIndex !== -1) {
    // Return the part of the string before the separator
    return propertyName.substring(0, pvaSeparatorIndex);
  }

  // If pva separator is not found, check for the first occurrence of
  //subproperty separator (".")
  const subPropSeparatorIndex = propertyName.indexOf(SEPARATOR_SUBPROP);
  if (subPropSeparatorIndex !== -1) {
    // Return the part of the string before the first "."
    return propertyName.substring(0, subPropSeparatorIndex);
  }

  // If neither pva nor subproperty is found, return the entire propertyName
  return propertyName;
};

/**
 * Separates a multiline error message into an array of strings,
 * one element for each line.
 * @param errMsg
 * @returns array of string error messages.
 */
export const ParseErrorMessageLinesToArray = (errMsg: string): string[] => {
  const messages: string[] = errMsg.split(/[\r\n]+/);
  return messages;
};
