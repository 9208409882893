import { SearchableDropdown } from "components";
import React from "react";
import styled from "styled-components";
import colors from "theme/colors";

export function SearchInputWithLabel({
  options,
  getOptions,
  multiselect,
  placeholder,
  name,
  onChange,
  label,
  value,
  className,
  disabled = false,
}: {
  options: any;
  getOptions?: any;
  multiselect: boolean;
  placeholder: string;
  name: string;
  onChange: any;
  label: string;
  value?: any;
  className?: any;
  disabled?: boolean;
}) {
  return (
    <Box>
      <Label>{label}</Label>
      <StyledDropdown
        placeholder={placeholder}
        name={name}
        options={options}
        getOptions={getOptions}
        multiple={multiselect}
        handleChange={onChange}
        value={value}
        className={className}
        disabled={disabled}
      />
    </Box>
  );
}

const StyledDropdown = styled(SearchableDropdown)`
  width: 180px;
  box-sizing: border-box;
  min-width: 180px;
  > div > div > input {
    width: 180px !important;
    height: 40px !important;
    border: 1px solid ${colors.tables.borders} !important;
    box-sizing: border-box !important;
    box-shadow: none !important;
  }
  > div > div {
    width: 180px !important;
  }
`;

const Label = styled.span`
  display: inline-block;
  padding: 4px;
  position: relative;
  margin: -47px 0 0 -10px;
  background-color: #fff;
  color: ${(props) => props.theme.colors.secondary};
  font-size: 10px;
  top: 10px;
  left: 20px;
  z-index: 10001;
`;

const Box = styled.div`
  margin-right: 30px;
  margin-bottom: 10px;
`;
