import { createContext, useContext } from "react";
import {
  DataTypeEnum,
  DomainEnum,
} from "store/reducers/products/properties/pl-properties.types";

export type ParentPropertyContextType = {
  parentId: string;
  parentPropertyName: string;
  parentPropertyRulesets: any;
  parentPropertyTags: any;
  parentDataType: DataTypeEnum;
  parentDomain: DomainEnum;
  productLineId: string;
};

export const ParentPropertyContext = createContext<ParentPropertyContextType>({
  parentId: "",
  parentPropertyName: "",
  parentPropertyRulesets: [],
  parentPropertyTags: [],
  parentDataType: DataTypeEnum.STRING,
  parentDomain: DomainEnum.OPEN,
  productLineId: "",
});
export const useParentPropertyContext = () => useContext(ParentPropertyContext);
