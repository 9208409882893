import React from "react";
import styled from "styled-components";
import { H4 } from "components/Typography/Headlines.component";
import sizes, { Size } from "./sizes";

export function Avatar({
  firstName = "",
  lastName = "",
  size = "normal",
  className,
}: {
  firstName?: string;
  lastName?: string;
  profilePicture?: string;
  size?: Size;
  className?: any;
}) {
  const { width, fontSize } = sizes[size];
  return (
    <Wrapper className={className} style={{ width, height: width }}>
      <H4 style={{ margin: 0, fontSize }} color="white">
        {(firstName && firstName[0]) || ""}
        {(lastName && lastName[0]) || ""}
      </H4>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};

  /* Primary / Slate */
  background: ${(props) => props.theme.colors.background.avatars};
  border-radius: 64px;
`;
