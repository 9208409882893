import { setRestVerb } from "../generalActions";
import { fetchCarrierUsers } from "api/carrierAuthApi/carrierAuthApi";

// Actions constants
export const SET_USERS = "users/SET_USERS";
export const SET_VERB = "users/REST_VERB";

// Action creators
export const getUsersAction = () => {
  return async (dispatch) => {
    try {
      dispatch(setRestVerb("users", true, "getting"));
      const users = await fetchCarrierUsers();
      if (!users) throw null;

      dispatch({
        type: SET_USERS,
        payload: users,
      });
    } catch (e) {
      console.error({ e });
    }
    dispatch(setRestVerb("users", false, "getting"));
  };
};
