import React, { useEffect } from "react";
import styled from "styled-components";
import { Input, DropdownWithLabel } from "components";
import {
  DataTypeEnum,
  DomainEnum,
} from "store/reducers/products/properties/pl-properties.types";
import {
  getPropertiesAction,
  setPropertiesInitialState,
} from "store/reducers/products/properties/pl-properties.actions";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useTypedSelector } from "store";
import {
  getSelectedValueFromOptions,
  stringArrayToOptions,
} from "../../../components/Forms/SelectDropdown/SelectDropdown.component";
import { selectPropertiesPagination } from "../../../store/reducers/products/properties/pl-properties.selectors";
import { debounce } from "lodash";

export default function PLFilters() {
  const queryParams = queryString.parse(useLocation<any>().search);
  const history = useHistory();
  const { productLineId } = useParams<{ productLineId: string }>();

  const ruleSets =
    useTypedSelector((state) => state.products?.parameters.ruleSets) || [];
  const tags =
    useTypedSelector((state) => state.products?.parameters.tags) || [];
  const { page } = useTypedSelector((state) =>
    selectPropertiesPagination(state)
  );

  const dispatch = useDispatch();

  const getPropertiesActionArgumentsObject = {
    productLineId: productLineId,
    name: queryParams.name,
    dataTypes: queryParams.dataTypes,
    tags: queryParams.tags,
    domains: queryParams.domains,
    ruleSets: queryParams.ruleSets,
    items: 10,
    page: page,
    regexNameMatch: true,
  };

  useEffect(() => {
    dispatch(getPropertiesAction(getPropertiesActionArgumentsObject));
  }, [page]);

  // This is a layout structure, put logic and components inside other files pls
  const defaultValue = { label: "Any", value: "" };
  const formattedDataType: Array<any> = [];
  const formattedDomain: Array<any> = [];
  const formattedTags: Array<any> = [];
  const formattedRulesets: Array<any> = [];

  Object.entries(DataTypeEnum).map((item) => formattedDataType.push(item[1]));
  Object.entries(DomainEnum).map((item) => formattedDomain.push(item[1]));

  if (ruleSets && ruleSets.length > 0) {
    ruleSets.map((ruleset) => formattedRulesets.push(ruleset.name));
  }
  if (tags && tags.length > 0) {
    tags.map((tag) => {
      // @ts-ignore
      formattedTags.push(tag.name);
    });
  }

  const setFilters = (category, value) => {
    dispatch(setPropertiesInitialState());

    const newFilters = { ...queryParams, [category]: value };
    const newParamsString = queryString.stringify(newFilters);
    history.push({
      search: `?${newParamsString}`,
    });

    //If we are updating the name, then we will debounce the call
    //to getProperties so we don't send to api after every character
    //is typed.  We'll wait for a pause in the typing.
    //If updating anything else, just immediately call getProperties.
    if (category === "name") {
      debouncedGetProperties(category, value);
    } else {
      getProperties(category, value);
    }
  };

  const getProperties = (category, value) => {
    dispatch(
      getPropertiesAction({
        ...getPropertiesActionArgumentsObject,
        [category]: value,
      })
    );
  };

  //This function wraps getProperties which calls the api
  //It debounces the function so that it waits for a pause
  //in user typing to actually call the function so we
  //don't waste calls to the api.
  const debouncedGetProperties = React.useRef(
    debounce((category, value) => {
      getProperties(category, value);
    }, 300)
  ).current;

  const getValue = (queryParams, options) => {
    return stringArrayToOptions(options).filter(({ value }) => {
      const valuesArray = Array.isArray(queryParams)
        ? queryParams
        : [queryParams] || [];
      return valuesArray.includes(value);
    });
  };

  return (
    <Filters>
      <CustomInput
        size="short"
        required={false}
        label="Name"
        onChange={(el) => setFilters("name", el.target.value)}
        placeholder="Any"
        value={queryParams.name}
      />
      <DropdownWithLabel
        options={stringArrayToOptions(formattedTags)}
        label="Tags"
        name="tags"
        multiple
        placeholder="Any"
        onChange={(el) =>
          setFilters("tags", getSelectedValueFromOptions(true, el))
        }
        defaultValue={defaultValue}
        value={getValue(queryParams.tags, formattedTags)}
      />
      <DropdownWithLabel
        options={stringArrayToOptions(formattedDataType)}
        label="Data Type"
        name="dataType"
        placeholder="Any"
        onChange={(el) =>
          setFilters("dataTypes", getSelectedValueFromOptions(true, el))
        }
        multiple
        defaultValue={defaultValue}
        value={getValue(queryParams.dataTypes, formattedDataType)}
      />
      <DropdownWithLabel
        options={stringArrayToOptions(formattedRulesets)}
        label="Rulesets"
        name="ruleSets"
        placeholder="Any"
        multiple
        onChange={(el) =>
          setFilters("ruleSets", getSelectedValueFromOptions(true, el))
        }
        value={getValue(queryParams.ruleSets, formattedRulesets)}
      />
      <DropdownWithLabel
        options={stringArrayToOptions(formattedDomain)}
        name="domains"
        label="Domain"
        placeholder="Any"
        multiple
        onChange={(el) =>
          setFilters("domains", getSelectedValueFromOptions(true, el))
        }
        value={getValue(queryParams.domains, formattedDomain)}
      />
    </Filters>
  );
}

const Filters = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  background-color: ${(props) => props.theme.colors.white};
  flex-wrap: wrap;
`;

const CustomInput = styled(Input)<any>`
  height: 40px;
  width: 178px;

  > input {
    color: ${(props) => props.theme.colors.dropdowns};
    font-size: 16px;
    margin-top: -4px;
    ::placeholder {
      color: ${(props) => props.theme.colors.dropdowns};
      font-size: 16px;
    }
  }

  @media (max-width: 1690px) {
    width: 130px;
  }
`;
