export function getUniqueValuesFrom2Arrays(array1, array2): string[] {
  const hashMap = {};
  const concat = array1.concat(array2);

  concat.forEach((value) => {
    hashMap[value] = hashMap[value] ? hashMap[value] + 1 : 1;
  });

  const uniqueValues = Object.keys(hashMap).filter((key) => {
    if (hashMap[key] > 1) return false;
    return true;
  });
  return uniqueValues;
}

export function sanitizeUsers(users) {
  return (
    users
      // exclude user without a first name or a last name
      .filter(
        ({ lastName, firstName, userID }) => lastName && firstName && userID
      )
      // create a name variable that will be used by various components in the app
      .map((user) => {
        return {
          ...user,
          name: user.firstName + " " + user.lastName,
          userID: user.userID.toString(),
        };
      })
  );
}
