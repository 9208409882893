import {
  ErrorObject,
  ValidationById,
} from "store/reducers/products/validation/validation.types";

export interface SortedValidationObject {
  [id: string]: ErrorObject[];
}

export const sortValidationByObject = (
  validationById: ValidationById
): SortedValidationObject => {
  const sortedValidation: SortedValidationObject = {};

  const objectList = Object.keys(validationById).reduce(
    (acc: string[], validationId: string) => {
      const currentObjectId = validationById[validationId].objectId;
      if (!acc.find((objectId) => objectId === currentObjectId))
        return [...acc, currentObjectId];
      return acc;
    },
    []
  );

  objectList.forEach((obj) => {
    sortedValidation[obj] = Object.keys(validationById).reduce(
      (acc: ErrorObject[], curr: string) => {
        if (validationById[curr].objectId === obj)
          return [...acc, validationById[curr]];
        return acc;
      },
      []
    );
  });

  return sortedValidation;
};
