import React from "react";
import { SearchableDropdown, TypeConstrainedCell } from "components";
import { DomainEnum } from "store/reducers/products/properties/pl-properties.types";
import { useDispatch } from "react-redux";
import { editStandardSubPropertyPL } from "store/reducers/products/subproperties/pl-subproperties.actions";
import { useParentPropertyContext } from "../../pages/ProductDetail/ProductLineProperties/ParentPropertyContext";
import colors from "theme/colors";

export function StandardSubPropertiesInput({
  options,
  currentValue,
  propertyData,
  type,
}: {
  options?: any;
  currentValue: any;
  propertyData: any;
  type: "rulesets" | "default";
}) {
  const dispatch = useDispatch();
  const { parentId } = useParentPropertyContext();

  //Read the domain values of the subproperty and format them
  const values = propertyData.propertyValues.map((propertyValue) => {
    const value = propertyValue?.valueAttributeCells.find((cell) => {
      if (cell.propertyValueAttribute.name === "Value") return cell;
    });
    return value.value;
  });

  const formattedValues = values.map((value) => {
    return {
      name: value,
      value: value,
    };
  });

  const handleChange = (e) => {
    let newValue = e;

    if (
      (type === "default" && currentValue === e) ||
      (currentValue === "True" && e === true) ||
      (currentValue === "False" && e === false)
    ) {
      // when the value selected is the same
      // "deselect" the default value by setting it to empty string
      newValue = "";
    }

    dispatch(
      editStandardSubPropertyPL({
        propertyId: propertyData.id,
        changedProperty:
          type === "rulesets"
            ? { rulesetids: newValue }
            : { default: newValue },
        parentId,
      })
    );
  };

  const parseValue = () => {
    switch (propertyData.default) {
      case "true":
      case "True":
        return "true";

      case "false":
      case "False":
        return "false";

      case null:
        return "";

      default:
        return propertyData.default;
    }
  };

  if (type === "rulesets") {
    return (
      <SearchableDropdown
        options={options || []}
        name="ruleset"
        noShadow
        search={false}
        noHoverBorder
        handleChange={handleChange}
        value={currentValue}
        placeholder={"select rulesets" || currentValue}
        backgroundColor={colors.tables.hoveredCell}
        multiple={true}
      />
    );
  }

  if (type === "default" && propertyData.domain === DomainEnum.DISCRETE) {
    return (
      <SearchableDropdown
        options={formattedValues}
        name="default"
        noShadow
        search={false}
        noHoverBorder
        handleChange={handleChange}
        value={currentValue}
        placeholder={"" || currentValue}
        backgroundColor={colors.tables.hoveredCell}
      />
    );
  }

  return (
    <TypeConstrainedCell
      dataType={propertyData["dataType"]}
      type="editCell"
      handleSubmit={handleChange}
      value={parseValue()}
    />
  );
}
