import styled from "styled-components";

interface HProps {
  bold?: boolean;
}

export const H1 = styled.h1<HProps>`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 48px;
  color: ${(props) => props.color || props.theme.colors.slate};
`;

export const H2 = styled.h2<HProps>`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 40px;
  color: ${(props) => props.color || props.theme.colors.slate};
`;

export const H3 = styled.h3<HProps>`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 24px;
  color: ${(props) => props.color || props.theme.colors.slate};

  @media ${(props) => props?.theme?.device?.mobile} {
    font-size: 28px;
  }
`;

export const H4 = styled.h4<HProps>`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 20px;
  color: ${(props) => props.color || props.theme.colors.slate};
`;

export const H5 = styled.h5<HProps>`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 16px;
  color: ${(props) => props.color || props.theme.colors.slate};
`;

export const H6 = styled.h6<HProps>`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 12px;
  color: ${(props) => props.color || props.theme.colors.slate};
`;
