import React, { useState } from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";
import { H5, H6 } from "components";
import { chartData } from "store/reducers/mocks/chartData";

export default function Chart() {
  const [currentDot, setCurrentDot] = useState<any>();
  const data = chartData;
  function CustomTooltip({
    payload,
    active,
  }: {
    payload?: any;
    label?: any;
    active?: any;
  }) {
    let value;
    let color;
    if (currentDot?.dataKey === "completed") {
      value = payload[0]?.payload?.completed;
      color = colors.projectComplete;
    } else if (currentDot?.dataKey === "total") {
      value = payload[1]?.payload?.total;
      color = colors.muttedHeadlines;
    } else if (currentDot?.dataKey === "estimation") {
      value = payload[2]?.payload?.estimation;
      color = colors.secondary;
    } else if (currentDot?.dataKey === "original") {
      value = payload[3]?.payload?.original;
      color = colors.tables.borders;
    }
    if (active) {
      return (
        <div className="custom-tooltip">
          <StyledCustomTooltip color={color}>
            <TooltipLabel bold color={colors.white}>
              {value}
            </TooltipLabel>
          </StyledCustomTooltip>
        </div>
      );
    }

    return null;
  }
  return (
    <Box>
      <LegendBox>
        <LegendLabel>
          <LegendDot color={colors.tables.borders} />
          <LegendLabelTitle bold color={colors.tables.borders}>
            Original Plan
          </LegendLabelTitle>
        </LegendLabel>
        <LegendLabel>
          <LegendDot color={colors.muttedHeadlines} />
          <LegendLabelTitle bold color={colors.muttedHeadlines}>
            Total
          </LegendLabelTitle>
        </LegendLabel>
        <LegendLabel>
          <LegendDot color={colors.projectComplete} />
          <LegendLabelTitle bold color={colors.projectComplete}>
            Completed
          </LegendLabelTitle>
        </LegendLabel>
        <LegendLabel>
          <LegendDot color={colors.secondary} />
          <LegendLabelTitle bold color={colors.secondary}>
            Estimation of completion
          </LegendLabelTitle>
        </LegendLabel>
      </LegendBox>
      <LineChart
        width={613}
        height={270}
        data={data}
        margin={{ right: 40, top: 40 }}
      >
        <Line
          type="monotone"
          strokeWidth={2}
          dataKey="completed"
          stroke={colors.projectComplete}
          isAnimationActive={false}
          dot={{
            stroke: colors.projectComplete,
            strokeWidth: 4,
            fill: colors.projectComplete,
          }}
          activeDot={{
            onMouseEnter: (event, payload) => setCurrentDot(payload),
            strokeWidth: 4,
            fill: colors.projectComplete,
            stroke: colors.projectComplete,
          }}
        />
        <Line
          type="monotone"
          strokeWidth={2}
          dataKey="total"
          stroke={colors.muttedHeadlines}
          isAnimationActive={false}
          dot={{
            stroke: colors.muttedHeadlines,
            strokeWidth: 4,
            fill: colors.muttedHeadlines,
          }}
          activeDot={{
            onMouseEnter: (event, payload) => setCurrentDot(payload),
            stroke: colors.muttedHeadlines,
            strokeWidth: 4,
            fill: colors.muttedHeadlines,
          }}
        />
        <Line
          type="monotone"
          strokeWidth={2}
          dataKey="estimation"
          stroke={colors.secondary}
          isAnimationActive={false}
          dot={{
            stroke: colors.secondary,
            strokeWidth: 4,
            fill: colors.secondary,
          }}
          activeDot={{
            onMouseEnter: (event, payload) => setCurrentDot(payload),
            stroke: colors.secondary,
            strokeWidth: 4,
            fill: colors.secondary,
          }}
        />
        <Line
          type="monotone"
          strokeWidth={2}
          dataKey="original"
          isAnimationActive={false}
          stroke={colors.tables.borders}
          dot={{
            stroke: colors.tables.borders,
            strokeWidth: 4,
            fill: colors.tables.borders,
          }}
          activeDot={{
            onMouseEnter: (event, payload) => setCurrentDot(payload),
            stroke: colors.tables.borders,
            strokeWidth: 4,
            fill: colors.tables.borders,
          }}
        />
        <XAxis dataKey="label" />
        <YAxis dataKey="total" />
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
    </Box>
  );
}

const Box = styled.div<any>`
  width: 680px;
  height: 344px;
  background-color: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.tables.borders};
  margin-right: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LegendBox = styled.div`
  width: 613px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
`;

const LegendLabelTitle = styled(H6)`
  text-transform: uppercase;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

const LegendLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-left: 12px;
`;

const LegendDot = styled.div<any>`
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: ${(props) => props.color};
  margin-right: 8px;
`;

const TooltipLabel = styled(H5)`
  margin-block-start: 0px;
  margin-block-end: 0px;
  line-height: 16px;
`;

const StyledCustomTooltip = styled.div<any>`
  background-color: ${(props) => props.color};
  width: 48px;
  height: 28px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 4px 4px 4px;
`;
