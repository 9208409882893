import React, { useState } from "react";
import styled from "styled-components";
import AddPropertyButton from "./AddPropertyButton";
//import BatchAddPropertyButton from "./BatchAddProperties/BatchAddPropertyButton";
import PLFilters from "../../../components/PLFilters.component";
import colors from "theme/colors";
import PropertiesList from "./PropertiesList";

export default function ProductLineProperties() {
  const [closedSections, setClosedSection] = useState<boolean>();

  return (
    <Wrapper>
      <FilterAddWrapper>
        <PLFilters />
        <AddPropertyButton />
        {/* <BatchAddPropertyButton /> */}
      </FilterAddWrapper>
      <PropertiesListWrapper>
        <PropertiesList
          closeSections={closedSections}
          changeSectionsStatus={setClosedSection}
        />
      </PropertiesListWrapper>
    </Wrapper>
  );
}

const PropertiesListWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background.pageTable};
  padding: 0 24px 32px 32px;
  min-height: 90vh;
  box-sizing: border-box;
`;

const FilterAddWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.tables.borders};
  align-items: center;
  min-height: 102px;
  max-height: 150px;
  width: 100%;
  padding: 16px 32px 16px 32px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background: white;
  z-index: 3;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
