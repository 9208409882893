import { H3, H5 } from "components/Typography/Headlines.component";
import React from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { Button, Input, FileInput } from "components";

export default function DocumentsAddNew() {
  return (
    <Box>
      <CustomH3 color={colors.secondary}>Add a new Document</CustomH3>
      <CustomH5 color={colors.muttedHeadlines}>
        Add a new document via upload or link
      </CustomH5>
      <CustomInput type="text" placeholder="Name Of Document (Optional)" />
      <FileInput placeholder="Upload Document" />
      <SeparatorRow>
        <Line />
        <CustomBlueH5 bold color={colors.secondary}>
          OR
        </CustomBlueH5>
        <Line />
      </SeparatorRow>
      <CustomInput type="text" placeholder="Name Of Document (Optional)" />
      <CustomInput placeholder="Document URL..." />
      <CustomButton type="primary" color="secondary">
        Add document
      </CustomButton>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 90vh;
  margin-top: -10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CustomH3 = styled(H3)`
  margin-block-start: 32px;
  margin-block-end: 8px;
  line-height: 40px;
`;

const CustomH5 = styled(H5)`
  margin-block-start: 0px;
  margin-block-end: 32px;
  margin-right: 24px;
  margin-left: 24px;
  line-height: 28px;
`;

const CustomBlueH5 = styled(H5)`
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-right: 24px;
  margin-left: 24px;
  line-height: 28px;
`;

const SeparatorRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
`;
const Line = styled.div`
  width: 312px;
  height: 1px;
  background-color: ${colors.tables.borders};
`;

const CustomInput = styled(Input)`
  margin-bottom: 16px;
  > input {
    margin-top: 10px;
  }
`;

const CustomButton = styled(Button)`
  margin-top: 24px;
  text-transform: uppercase;
`;
