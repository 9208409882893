import React, { useState } from "react";
import styled from "styled-components";
import { IconButton, Tooltip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
//import StraightenIcon from "@mui/icons-material/Straighten";
import { H4, Table } from "components";
import { getTableData } from "./helpers";
import { PropertiesDefinitionTable } from "../PropertiesDefinitionTable/PropertiesDefinitionTable.component";
import AddStandardSubPropertyButton from "../../pages/ProductDetail/ProductLineProperties/AddStandardSubpropertyButton.component";
import {
  //DomainEnum,
  StandardSubProperty,
} from "store/reducers/products/properties/pl-properties.types";
import colors from "theme/colors";
import { Ruleset } from "../../../../store/reducers/products/parameters/pl-parameters.types";
import Validator from "../../../../components/Validator/Validator.component";
import { ObjectAssignedEnum } from "../../../../store/reducers/products/validation/validation.types";
import * as _ from "lodash";
//import { useParentPropertyContext } from "../../pages/ProductDetail/ProductLineProperties/ParentPropertyContext";
import { UnitPickerDialog } from "../UnitPickerDialog/UnitPickerDialog";

export function StandardSubPropertiesTableComponent({
  ruleSets = [],
  propertyData,
  tags,
}: {
  propertyData: StandardSubProperty[];
  ruleSets: Ruleset[];
  tags: any;
}) {
  // state
  const [advancedView, setAdvancedView] = useState<boolean>(false);
  const [openUnitDialog, setOpenUnitDialog] = useState<boolean>(false);

  // context variables
  //const { parentDomain } = useParentPropertyContext();

  const data = getTableData(propertyData, ruleSets);

  //handlers
  const handleToggleAdvancedView = () => {
    setAdvancedView(!advancedView);
  };

  const validations = Object.keys(propertyData)
    .map((propertyId) => propertyData[propertyId].validations)
    .flat();

  return (
    <Wrapper>
      <HeaderWrapper>
        <H4 bold color={colors.muttedHeadlines}>
          Standard Subproperties
        </H4>
        <AddStandardSubPropertyButton standardProperties={propertyData} />
        <Tooltip title="Standard subproperties advanced view" arrow>
          <IconButton
            size="medium"
            color="primary"
            aria-label="clone property"
            onClick={handleToggleAdvancedView}
          >
            <MenuIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>

        {/* Configure Unit Subproperties button if parent is a RANGE property */}
        {/* {parentDomain === DomainEnum.RANGE && (
          <Tooltip title="Configure Unit Subproperties Macro" arrow>
            <IconButton
              size="medium"
              color="secondary"
              aria-label="configure unit subproperties"
              onClick={() => setOpenUnitDialog(true)}
            >
              <StraightenIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )} */}
      </HeaderWrapper>
      {!advancedView && (
        <BasicTableWrapper>
          {!_.isEmpty(propertyData) && (
            <Validator
              objectType={ObjectAssignedEnum.PROPERTY}
              validations={validations}
              isStandardSubProperty
              readOnly
            />
          )}
          <Table data={data} size={"small"} />
        </BasicTableWrapper>
      )}
      {advancedView &&
        Object.keys(propertyData).map((property) => {
          return (
            <PropertiesDefinitionTable
              key={propertyData[property].id}
              propertyData={propertyData[property]}
              type={"standardSubProperty"}
              ruleSets={ruleSets}
              tags={tags}
            />
          );
        })}
      {openUnitDialog && (
        <UnitPickerDialog open={openUnitDialog} setOpen={setOpenUnitDialog} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const BasicTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  //Children margins
  & > * {
    margin-top: 0px; //will not be 0, but will go to a min value
    margin-bottom: 0px; //will not be 0, but will go to a min value
  }
`;
