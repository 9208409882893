import React, { useEffect } from "react";
import SubLeftMenu from "containers/SubLeftMenu/SubLeftMenu.container";
import ProductOverview from "./ProductOverview/ProductOverview";
import styled from "styled-components";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";
import {
  clearSelectedProductLine,
  getProductLine,
} from "../../../../store/reducers/products/products.actions";
import {
  getProductValueAttributes,
  getProductLineTagsCount,
  getProductRulesets,
} from "../../../../store/reducers/products/parameters/parameters.actions";
import { useDispatch } from "react-redux";
import ProductLinePVAs from "./ProductLineParameters/ProductLinePVAs";
import ProductLineRulesets from "./ProductLineParameters/ProductLineRulesets";
import ProductLineTags from "./ProductLineParameters/ProductLineTags";
import ProductLineProperties from "./ProductLineProperties/ProductLineProperties";
import ProductLineRulesTables from "./ProductLineRulesTables/ProductLineRulesTables";
import { setPropertiesInitialState } from "../../../../store/reducers/products/properties/pl-properties.actions";
import { setRulesTablesInitialState } from "../../../../store/reducers/products/rulestables/pl-rulestables.actions";
import ProductCandidateSelection from "./ProductCandidateSelection/ProductCandidateSelection.page";
import ProductMetadataPage from "./ProductMedatada/ProductMetadata.page";
import { PropertyPrioritiesList } from "./PropertyPrioritiesList/PropertyPrioritiesList.component";
import { clearPropertyPrioritiesList } from "../../../../store/reducers/products/propertyPriorities/pl-propertyPriorities.action";
import { useTypedSelector } from "../../../../store";

const menuItems = [
  {
    path: "overview",
    label: "Overview",
    content: <ProductOverview />,
  },
  {
    path: "rulesets",
    label: "Rulesets",
    content: <ProductLineRulesets />,
  },
  {
    path: "tags",
    label: "Tags",
    content: <ProductLineTags />,
  },
  {
    path: "pvas",
    label: "Property Value Attributes",
    content: <ProductLinePVAs />,
  },
  // {
  //   path: "logic-groups",
  //   label: "Logic Groups",
  //   content: <ProductLineLogicGroups />,
  // },
  {
    path: "properties",
    label: "Properties",
    content: <ProductLineProperties />,
  },
  {
    path: "rules-tables",
    label: "Rules Tables",
    content: <ProductLineRulesTables />,
  },
  {
    path: "property-priorities-list",
    label: "Property Priorities",
    content: <PropertyPrioritiesList />,
  },
  {
    path: "selection-candidate-list",
    label: "Selection Candidate Config",
    content: <ProductCandidateSelection />,
  },
  {
    path: "Metadata",
    label: "Metadata Config",
    content: <ProductMetadataPage />,
  },
];

export default function ProductDetail() {
  const { path: rootPath } = useRouteMatch();
  const { productLineId } = useParams();
  const dispatch = useDispatch();

  const gettingProductLine = useTypedSelector(
    (state) => state.products.getting
  );

  useEffect(() => {
    const fetchProductLineData = async () => {
      await dispatch(getProductLine(productLineId));
      dispatch(getProductValueAttributes());
      dispatch(getProductLineTagsCount());
      dispatch(getProductRulesets());
    };

    fetchProductLineData();

    return () => {
      dispatch(clearSelectedProductLine());
      dispatch(setPropertiesInitialState());
      dispatch(setRulesTablesInitialState());
      dispatch(clearPropertyPrioritiesList());
    };
  }, []);

  return (
    <Wrapper>
      <StyledSubLeftMenu menuItems={menuItems} />
      <PageContent id="product-lines-wrapper">
        <Switch>
          {menuItems.map(({ path, content }) => {
            return (
              <Route key={path} exact path={`${rootPath}/${path}`}>
                {/* put the corresponding page into the "content" property of the menuItems object */}
                {!gettingProductLine && content}
              </Route>
            );
          })}
        </Switch>
      </PageContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  overflow-y: hidden;
  height: 90vh;
`;

const StyledSubLeftMenu = styled(SubLeftMenu)`
  overflow: hidden;
  flex: 0 0 auto;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
`;

const PageContent = styled.div`
  flex: 1 1 auto;
  min-width: 1050px;
  overflow: auto;
`;
