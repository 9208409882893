import React from "react";
import colors from "theme/colors";
import styled from "styled-components";
import ImpactedBox from "../components/ImpactedBox.component";
import SectionTitle from "../components/SectionTitle.component";
import { H6 } from "components/Typography/Headlines.component";
import { PlusActionIcon } from "assets/logos/icons/QuickActions";

export default function ProductsAndPartsImpacted({
  productsImpacted,
  partsImpacted,
}: {
  productsImpacted?: any;
  partsImpacted?: any;
}) {
  const displayBoxes = (impactedElements, isAddNewModelVisible) => {
    return (
      <Box>
        {impactedElements?.map((impactedElement, index) => {
          return <ImpactedBox impactedElement={impactedElement} key={index} />;
        })}
        {isAddNewModelVisible && (
          <AddNewModelBox>
            <PlusActionIcon />
            <CustomH6 bold>Add new model</CustomH6>
          </AddNewModelBox>
        )}
      </Box>
    );
  };
  return (
    <Row>
      <Container>
        <SectionTitle>Product Models Impacted</SectionTitle>
        {displayBoxes(productsImpacted, true)}
      </Container>
      <Container>
        <SectionTitle>Parts Impacted</SectionTitle>
        {displayBoxes(partsImpacted, false)}
      </Container>
    </Row>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
`;

const Container = styled.div`
  width: 474px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const AddNewModelBox = styled.div`
  background-color: ${colors.white};
  width: 225px;
  height: 208px;
  border-radius: 4px;
  border: 1px solid ${colors.tables.borders};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  flex-direction: column;
  &:hover circle {
    fill: ${colors.secondary};
  }
  &:hover path {
    fill: ${colors.white};
  }
  &:hover {
    border: 1px solid transparent;
    box-shadow: inset 0 0 0 2px ${colors.secondary};
  }
`;

const CustomH6 = styled(H6)`
  text-transform: uppercase;
  color: ${colors.secondary};
  margin-top: 16px;
  margin-block-end: 0px;
`;
