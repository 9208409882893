import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, LoaderSpinner2, Modal } from "components";
import { api } from "../../../../../api/api";
import styled from "styled-components";
import { useTypedSelector } from "store";
import { ADD_NEW_RULES_TABLE } from "store/reducers/products/rulestables/pl-rulestables.actions";

enum ImportStates {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}

export default function ImportTableFromTSVButton() {
  const fileInputRef = useRef(null);
  const [importStatus, setImportStatus] = useState(ImportStates.IDLE);
  const [importError, setImportError] = useState<Array<string>>([]);
  const dispatch = useDispatch();

  //Get the product line and the list of active rules tables from redux store
  const { selectedProductLine: productLine, rulestables } = useTypedSelector(
    (state) => state.products
  );

  const closeImportErrorModal = () => {
    setImportStatus(ImportStates.IDLE);
    setImportError([]);
  };

  const handleClick = () => {
    //Open the file input dialog
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    //Get a reference to the file object from the input component
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    //reset file input
    event.target.value = null;

    //Import the file
    handleImport(fileObj);
  };

  const handleImport = async (fileObj) => {
    setImportStatus(ImportStates.PENDING);

    try {
      //Create a form data object containing the file
      const formData = new FormData();
      formData.append("file", fileObj);

      //Call the api to export the table
      const { data } = await api.importRuleTableFromTSV(
        productLine?.id,
        formData
      );

      //Add the new table to the redux store of active rules tables so the UI can render it.
      //allIds is an array of rule table ids
      //byId is a dictionary of rule table objects keyed by the rule table id
      //we are going to put the new table at the top of the active tables.
      dispatch({
        type: ADD_NEW_RULES_TABLE,
        payload: {
          byId: { [data.id]: data, ...rulestables.byId },
          allIds: rulestables.allIds.concat(data.id),
          total: rulestables.total,
        },
      });

      setImportStatus(ImportStates.SUCCESS);
    } catch (e) {
      setImportError(parseErrorMessage(e?.response?.data || e.toString()));
      setImportStatus(ImportStates.ERROR);
      console.error("Error importing table:", e);
    }
  };

  const parseErrorMessage = (errMsg) => {
    //const messages: Array<string>;

    console.log("parseErrorMessage errMsg = ", errMsg);
    const messages = errMsg.split(/[\r\n]+/);
    console.log("parseErrorMessage messages = ", messages);
    return messages;
  };

  return (
    <Wrapper>
      <StyledButton size="small" type="secondary" onClick={handleClick}>
        Import New Table from TSV
      </StyledButton>
      <input
        type="file"
        accept=".tsv"
        name="importfile"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      {importStatus === ImportStates.PENDING && <LoaderSpinner2 />}
      {importStatus === ImportStates.ERROR && (
        <Modal
          dialogType={"error"}
          open={importStatus === ImportStates.ERROR}
          closeModal={closeImportErrorModal}
          title={"Import Table Error"}
          height="600px"
          width="600px"
        >
          {typeof importError !== undefined &&
            importError.map((item, index) => {
              return <div key={index}>{item}</div>;
            })}
        </Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 200px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;
