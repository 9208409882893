import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Row, H3 } from "components";
import { useTypedSelector } from "store";
import { HeaderAddNewProgram } from "./CustomHeaders/HeaderAddNewProgram";
import ProgramDetailsHeader from "./CustomHeaders/ProgramDetailsHeader";
import ProductLineDetailsHeader from "./CustomHeaders/ProductLineDetailsHeader";
import LabelsBar from "./CustomHeaders/LabelsBar/LabelsBar";
import { findLabelByMatchingUrl } from "./helpers";
import { menuItems } from "./SideMenu/menuItems";

export default function Header() {
  const { navigationOpen } = useTypedSelector(
    (state) => state.user.preferences
  );
  const { name } = useTypedSelector((state) => state.user);
  const history = useHistory();
  const pathname = history.location.pathname;

  // TODO: shall be using the constants inside ROUTES folders, but the concatenation is terrible
  // Need to find a proper way of doing it
  const isAddingNewProgram = pathname.match(/add-new-program/);
  const greyBorderStyle = pathname.match(/add-new-program|products\/product/);
  const isProgramDetails = pathname.match(/programs\/[0-9]+/);
  const isProductLineDetails = pathname.match(/products/);
  const isAddNewDocument = pathname.match(/add-new-document/);
  const isTaskModal = pathname.match(/task/);
  const setCustomHeader = () => {
    if (isAddingNewProgram) return <HeaderAddNewProgram />;

    if (isProgramDetails) return <ProgramDetailsHeader />;

    if (isProductLineDetails) return <ProductLineDetailsHeader />;

    const label = findLabelByMatchingUrl(pathname, menuItems);

    return (
      <CustomHeader>
        <H3 style={{ marginLeft: 16 }}>
          {label || `Good Afternoon, ${name}!`}
        </H3>
        {label === "Programs" && <LabelsBar />}
      </CustomHeader>
    );
  };
  if (isAddNewDocument || isTaskModal) {
    return null;
  } else {
    return (
      <StyledHeader
        navigationOpen={navigationOpen}
        greyBorderStyle={greyBorderStyle}
      >
        <CustomRow>{setCustomHeader()}</CustomRow>
      </StyledHeader>
    );
  }
}

const StyledHeader = styled.header<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //position: fixed;
  flex: 1 0 auto;
  top: 0;
  left: 56px;
  width: 100%;
  height: auto;
  z-index: 99999;

  overflow: hidden;
  min-height: 5vh;
  background-color: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.tables.borders};
  box-sizing: border-box;
  transition: left 0.5s;
`;

const CustomRow = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline;

  @media ${(props) => props.theme.device.mobile} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const CustomHeader = styled.div`
  max-height: 10vh;
  padding-left: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
