import React, { useEffect, useState } from "react";
import RulesTableSearchInput from "./RulesTableSearchInput";
import styled from "styled-components";
import { Button } from "../../../../components";
import { TablesTypeEnum } from "../../../../store/reducers/products/rulestables/pl-rulestables.types";
import { Close } from "../../../../assets/logos/icons/MicroIcons";
import { api } from "../../../../api/api";
import { useParams } from "react-router-dom";

export default function RulesTableAddProperty({
  newPropertyId,
  setNewPropertyId,
  newPropertyPvaId,
  setNewPropertyPvaId,
  addNewProperty,
  ruleTableType,
}: {
  newPropertyId: any;
  setNewPropertyId: any;
  newPropertyPvaId: any;
  setNewPropertyPvaId: any;
  addNewProperty: () => void;
  ruleTableType: TablesTypeEnum;
}) {
  const [showDropDowns, setShowDropDowns] = useState(false);
  const [propertyPvasOptions, setPropertyPvasOptions] = useState([]);

  const { productLineId } = useParams<{ productLineId: string }>();

  useEffect(() => {
    if (!newPropertyId) return;
    const getPropertyPvas = async () => {
      const res = await api.getProductLineProperty(
        productLineId,
        newPropertyId
      );
      if (!res) return null;
      return res.data.propertyValueAttributes.map((pva) => {
        return { name: pva.name, value: pva.id };
      });
    };

    getPropertyPvas().then((data) => {
      const filteredPvas = data.filter(
        (pva) => !["Value", "RULESET"].includes(pva.name)
      );
      setPropertyPvasOptions(filteredPvas);
    });
  }, [newPropertyId]);

  const handleAddPropertyButton = () => {
    if (showDropDowns) {
      addNewProperty();
      return;
    }
    setShowDropDowns(true);
  };

  const handleClose = () => {
    setShowDropDowns(false);
    setNewPropertyId("");
    setNewPropertyPvaId("");
  };

  const isLookup = [TablesTypeEnum.ATTR_LOOKUP, TablesTypeEnum.LOOKUP].includes(
    ruleTableType
  );

  return (
    <AddPropertyWrapper>
      {showDropDowns && (
        <RulesTableSearchInput
          newProperty={newPropertyId}
          type={"property"}
          setSelectedProperty={setNewPropertyId}
          setSelectedPva={setNewPropertyPvaId}
        />
      )}
      {showDropDowns && isLookup && propertyPvasOptions.length > 0 && (
        <>
          <SemiColon>::</SemiColon>
          <RulesTableSearchInput
            filteredOptionsFormatted={propertyPvasOptions}
            newProperty={newPropertyPvaId}
            type={"pva"}
            setSelectedPva={setNewPropertyPvaId}
          />
        </>
      )}
      <ButtonsWrapper>
        <StyledAddButton onClick={handleAddPropertyButton} buttonType="submit">
          {showDropDowns ? "Add" : "Add Property"}
        </StyledAddButton>
        {showDropDowns && <StyledClose onClick={handleClose} />}
      </ButtonsWrapper>
    </AddPropertyWrapper>
  );
}

const AddPropertyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  margin-top: 6px;
  max-height: 30px;
`;

const StyledAddButton = styled(Button)`
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: 8px;

  /* for smooth transition */
  white-space: nowrap;
`;

const SemiColon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 8px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledClose = styled(Close)<any>`
  margin-left: 16px;
  margin-right: 16px;
`;
