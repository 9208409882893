import React from "react";
import { StyledTable } from "../../../../../components";
import styled from "styled-components";

export default function PropertyPrioritiesHeader({
  rulesetName,
}: {
  rulesetName: string;
}) {
  return (
    <StyledTable>
      <tr>
        <LabelCell>RULESET</LabelCell>
        <td style={{ width: "133px" }}>{rulesetName}</td>
      </tr>
      <tr>
        <LabelCell>PROPERTY</LabelCell>
        <LabelCell>FIXED</LabelCell>
      </tr>
    </StyledTable>
  );
}

const LabelCell = styled.td<any>`
  background-color: #f4f8fc;
  color: ${(props) => props.theme.colors.muttedHeadlines};
`;
