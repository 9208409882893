import React from "react";
import styled from "styled-components";

export default function ListElement({ children }: { children?: any }) {
  return <CustomLi>{children}</CustomLi>;
}

const CustomLi = styled.li`
  margin-bottom: 8px;
  line-height: 28px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.muttedHeadlines};
  &:before {
    content: "\\2022";
    font-size: 30px;
    vertical-align: middle;
    line-height: 20px;
    color: ${(props) => props.theme.colors.secondary};
    margin-right: 8px;
  }
`;
