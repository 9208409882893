import { setRestVerb } from "store/reducers/generalActions";
import { DomainEnum, Property } from "./pl-properties.types";
import { api } from "api/api";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import { propertyErrorHandler } from "../../../helpers";
import {
  addNewParentProperty,
  getSubPropertiesAction,
} from "../subproperties/pl-subproperties.actions";
import { ObjectAssignedEnum } from "../validation/validation.types";
import { reorder } from "../../../../components/Table/helper";

export const SET_PL_PROPERTIES = "products/plProperties/SET_PL_PROPERTIES";
export const SET_NEW_PROPERTY = "products/plProperties/SET_NEW_PL_PROPERTY";
export const SET_VERB = "products/plProperties/REST_VERB";
export const GET_MAIN_PROPERTY_VALIDATION_BY_OBJECT_ID =
  "products/plProperties/GET_MAIN_PROPERTY_VALIDATION_BY_OBJECT_ID";
export const SET_PL_SUB_PROPERTY = "products/plProperties/SET_PL_SUB_PROPERTY";
export const GET_PL_PROPERTY = "products/plProperties/GET_PL_PROPERTY";
export const SET_PL_PROPERTY = "products/plProperties/GET_PL_PROPERTY";
export const EDIT_PL_PROPERTY = "products/plProperties/EDIT_PL_PROPERTY";
export const REMOVE_PL_PROPERTY = "products/plProperties/REMOVE_PL_PROPERTY";
export const SET_PROPERTIES_INITIAL_STATE =
  "products/plProperties/SET_PROPERTIES_INITIAL_STATE";
export const SET_PL_PROPERTY_PAGINATION =
  "products/plProperties/SET_PL_PAGINATION";

export const addPropertyToProductLineAction = (property: Property) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plProperties", true, "posting"));

    try {
      //Get current properties and productline data from redux store
      const { properties, selectedProductLine } = getState().products;

      //Call the API to create the property
      const { data } = await api.createProductLineProperty(
        property,
        selectedProductLine.id
      );

      data.isNewProperty = true; //This will cause the cloned property to auto-expand in properties list

      const newById = { [data.id]: data, ...properties.byId };
      const newAllIds = properties.allIds.concat(data.id);
      const newTotal = properties.total + 1;

      dispatch(addNewParentProperty(data.id));

      dispatch({
        type: SET_PL_PROPERTIES,
        payload: {
          byId: newById,
          allIds: newAllIds,
          total: newTotal,
        },
      });
      setTimeout(
        () =>
          window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: "smooth",
          }),
        300
      );
      if (property.domain === DomainEnum.RANGE)
        setTimeout(
          () =>
            dispatch(
              getSubPropertiesAction({
                productLineId: selectedProductLine.id,
                PLPropertyId: data.id,
              })
            ),
          1500
        );
    } catch (e) {
      toast.error(e);
      console.error(e, "error while adding a property to product line");
    }
    dispatch(setRestVerb("products/plProperties", false, "posting"));
  };
};

export const clonePropertyAction = (propertyId: string, newName: string) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plProperties", true, "posting"));

    try {
      //Get current properties and productline data from redux store
      const { properties, selectedProductLine } = getState().products;

      //Call the API to clone the property and all its sub-properties
      const { data } = await api.cloneProperty(
        selectedProductLine.id,
        propertyId,
        newName
      );

      //Get the first property off the response.  This will be the cloned parent property
      // Ensure data is not null, is an array, and has at least one element
      let clonedProperty: any = null;
      if (data && Array.isArray(data) && data.length > 0) {
        clonedProperty = data[0];
      } else {
        console.error("Invalid clone property api response data: ", data);
        return;
      }

      //This will cause the cloned property to auto-expand in properties list
      clonedProperty.isNewProperty = true;

      //Build the replacement property state, adding the clone property
      const newById = {
        [clonedProperty.id]: clonedProperty,
        ...properties.byId,
      };
      const newAllIds = properties.allIds.concat(clonedProperty.id);
      const newTotal = properties.total + 1;

      //Add the new cloned top level property to the redux store of property info
      dispatch({
        type: SET_PL_PROPERTIES,
        payload: {
          byId: newById,
          allIds: newAllIds,
          total: newTotal,
        },
      });

      //Add the new cloned top level property to the subproperties info for parent prop
      dispatch(addNewParentProperty(clonedProperty.id));

      //Get the subproperties of the clone and add to redux state which will cause them to render
      setTimeout(
        () =>
          dispatch(
            getSubPropertiesAction({
              productLineId: selectedProductLine.id,
              PLPropertyId: clonedProperty.id,
            })
          ),
        500
      );

      //Scroll to the top of the page where the new property will appear
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 300);

      //Notify user of success
      toast.success(`'${newName}' property succesfully cloned`);
    } catch (e) {
      toast.error(e);
      console.error(e, "Error while cloning a property in a product line");
    }

    dispatch(setRestVerb("products/plProperties", false, "posting"));
  };
};

export const setNewProperty = (newProperty: Property) => {
  return {
    type: SET_NEW_PROPERTY,
    payload: newProperty,
  };
};

export const getPropertiesAction = ({
  name,
  dataTypes,
  tags,
  ruleSets,
  domains,
  productLineId,
  includePVAProperties = false,
  includeSubProperties = false,
  items = 10,
  page = 0,
  regexNameMatch = false,
}: {
  name?: any;
  dataTypes?: any;
  tags?: any;
  ruleSets?: any;
  domains?: any;
  productLineId?: string;
  includePVAProperties?: boolean;
  includeSubProperties?: boolean;
  items?: number;
  page?: number;
  regexNameMatch?: boolean;
}) => {
  return async (dispatch) => {
    dispatch(setRestVerb("products/plProperties", true, "getting"));
    try {
      const { data } = await api.getProperties(
        productLineId,
        name,
        dataTypes,
        tags,
        ruleSets,
        domains,
        includePVAProperties,
        includeSubProperties,
        items,
        page,
        false,
        regexNameMatch
      );

      const newAllIds: Array<any> = [];
      const newById = {};
      data.properties.map((property) => {
        newAllIds.push(property.id);
        newById[property.id] = { ...property };
      });

      dispatch({
        type: SET_PL_PROPERTIES,
        payload: {
          byId: newById,
          allIds: newAllIds,
          page,
          total: data.total,
        },
      });
    } catch (e) {
      console.error(e, "error while getting all properties to product line");
    }
    dispatch(setRestVerb("products/plProperties", false, "getting"));
  };
};

export const getPropertyAction = ({ propertyId }: { propertyId: string }) => {
  return async (dispatch, getState) => {
    const prevState = getState();
    const selectedProductLineId = prevState?.products?.selectedProductLine?.id;

    if (!selectedProductLineId) return;

    try {
      dispatch(setRestVerb("products/plProperties", true, "getting"));
      const res = await api.getProductLineProperty(
        selectedProductLineId,
        propertyId
      );

      if (!res) throw null;

      const { data } = res;

      dispatch({ type: SET_PL_PROPERTY, payload: { data, propertyId } });
    } catch (e) {
      console.error(e, "error while getting property data");
    }
    dispatch(setRestVerb("products/plProperties", false, "getting"));
  };
};

export const editPropertyAction = ({
  propertyId,
  property,
  updatePayload,
}: {
  propertyId?: string;
  property: any;
  updatePayload: any;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plProperties", true, "posting"));
    const { selectedProductLine, properties } = getState().products;
    const hasRulesetPva = property.propertyValueAttributes.find(
      (pva) => pva.name === "RULESET"
    );

    try {
      // validation
      if (!propertyId) return;

      if ("rulesetids" in updatePayload && hasRulesetPva) {
        const selectedRulesetsInPvas = property.propertyValues
          .map((row) => row.valueAttributeCells)
          .flat()
          .filter((pva) => pva.propertyValueAttribute.name === "RULESET")
          .reduce((pV, cV) => {
            try {
              if (!cV.value.length) return pV;
              return [...pV, ...JSON.parse(cV.value)];
            } catch (e) {
              console.error(e, "error while parsing pva ruleset pvas");
            }
          }, []);

        const checker = selectedRulesetsInPvas.every((rulesetId) =>
          updatePayload.rulesetids.includes(rulesetId)
        );

        if (!checker) {
          toast.error(
            "Can't remove a ruleset which is used in property values"
          );
          throw null;
        }
      }

      if ("rulesetids" in updatePayload && !updatePayload.rulesetids.length) {
        throw null;
      }

      const newByIdOptimistic = {
        ...properties.byId,
        [propertyId]: property,
      };

      dispatch({
        type: EDIT_PL_PROPERTY,
        payload: newByIdOptimistic,
      });

      const res = await api.editProperty(
        selectedProductLine.id,
        propertyId,
        updatePayload
      );

      if (!res) throw null;

      const { data } = res;

      const newByIdRealistic = { ...properties.byId, [data.id]: data };

      dispatch({
        type: EDIT_PL_PROPERTY,
        payload: newByIdRealistic,
      });
    } catch (e) {
      console.error(e, "error while editing a main property of a product line");

      // rollback if it fails
      dispatch({
        type: EDIT_PL_PROPERTY,
        payload: { ...properties.byId },
      });
    }

    if ("domain" in updatePayload || "tagids" in updatePayload) {
      //Refresh the subproperties from database
      dispatch(
        getSubPropertiesAction({
          productLineId: selectedProductLine.id,
          PLPropertyId: propertyId,
        })
      );
    }
    dispatch(setRestVerb("products/plProperties", false, "posting"));
  };
};

export const addPvaToPropertyAction = (
  propertyId: string,
  propertyPva: any,
  property: any
) => {
  return async (dispatch, getState) => {
    try {
      const { selectedProductLine } = getState().products;

      if (!propertyId || !propertyPva.id) return;

      const newProperty = {
        ...property,
        propertyValueAttributes: property.propertyValueAttributes.concat(
          propertyPva
        ),
      };
      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: newProperty, propertyId },
      });

      const { data } = await api.addPvasToProperty(
        propertyId,
        [propertyPva.id],
        selectedProductLine.id
      );

      if (!data) throw null;

      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: data, propertyId },
      });
    } catch (e) {
      console.error(e, "error while editing a main property of a product line");

      // rollback
      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: property, propertyId },
      });
    }
  };
};

export const removePvaFromPropertyAction = (
  propertyId: string,
  propertyPva: any,
  property: any
) => {
  return async (dispatch, getState) => {
    try {
      const { selectedProductLine } = getState().products;

      if (!propertyId || !propertyPva.id) return;
      const newProperty = {
        ...property,
        propertyValueAttributes: property.propertyValueAttributes.filter(
          (pva) => pva.id !== propertyPva.id
        ),
      };

      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: newProperty, propertyId },
      });
      const { data } = await api.removePvasFromProperty(
        propertyId,
        [propertyPva.id],
        selectedProductLine.id
      );

      if (!data) throw null;

      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: data, propertyId },
      });
    } catch (e) {
      console.error(e, "error while editing a main property of a product line");

      // rollback
      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: property, propertyId },
      });
    }
  };
};

export const addPvaRowToProperty = (newPvaRow: any, property: any) => {
  return async (dispatch, getState) => {
    try {
      const { selectedProductLine } = getState().products;

      const body = {
        valueAttributeCells: Object.keys(newPvaRow).map((key: string) => {
          return {
            PropertyValueAttributeId: key,
            value: newPvaRow[key],
          };
        }),
      };

      const { data } = await api.addPvaRowToProperty(
        property.id,
        body,
        selectedProductLine.id
      );

      const newProperty = { ...property };
      newProperty.propertyValues = newProperty.propertyValues.concat(data);

      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: newProperty, propertyId: property.id },
      });

      toast.dismiss();
    } catch (e) {
      toast.error(e);
      console.error(e, "error while adding a pva row to a property");
    }
  };
};

export const removePropertyAction = ({
  propertyId,
  force = false,
}: {
  propertyId: string;
  force?: boolean;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("productLine/plProperties", true, "deleting"));
    const { selectedProductLine, properties } = getState().products;

    try {
      const res = await api.removeProperty(selectedProductLine.id, propertyId, {
        force: force,
      });

      if (!res) throw null;

      const newById = { ...properties.byId };
      delete newById[propertyId];

      const newAllIds = properties.allIds.filter((id) => id !== propertyId);

      dispatch({ type: REMOVE_PL_PROPERTY, payload: { newAllIds, newById } });
    } catch (e) {
      propertyErrorHandler(e, "error while deleting property");
    }

    dispatch(setRestVerb("products/plProperties", false, "deleting"));
  };
};

export const removePropertyWithoutApiCall = ({
  propertyId,
}: {
  propertyId: string;
}) => {
  return async (dispatch, getState) => {
    const { properties } = getState().products;

    const newById = { ...properties.byId };
    delete newById[propertyId];

    const newAllIds = properties.allIds.filter((id) => id !== propertyId);

    dispatch({ type: REMOVE_PL_PROPERTY, payload: { newAllIds, newById } });
  };
};

export const editPropertyPvaCell = (
  {
    propertyId,
    propertyValueAttributeId,
    propertyValueId,
  }: {
    propertyId: string;
    propertyValueAttributeId: string;
    propertyValueId: string;
  },
  newValue,
  property
) => {
  return async (dispatch, getState) => {
    try {
      const { selectedProductLine } = getState().products;
      const productLineId = selectedProductLine.id;
      const newProperty = cloneDeep(property);
      const newPropertyValue = newProperty.propertyValues.find(
        (elem) => elem.id === propertyValueId
      );
      const newValueAttributeCell = newPropertyValue.valueAttributeCells.find(
        (elem) => elem.propertyValueAttribute.id === propertyValueAttributeId
      );

      newValueAttributeCell.value = newValue;
      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: newProperty, propertyId },
      });

      const propertyValueAttribute = property.propertyValueAttributes.find(
        (pva) => pva.id === propertyValueAttributeId
      );

      if (propertyValueAttribute.name === "Value" && !newValue) {
        console.log("cant add empyty to value");
        throw null;
      }

      const res = await api.editPropertyPvaCell(
        {
          propertyId,
          productLineId,
          propertyValueId,
          propertyValueAttributeId,
        },
        { value: newValue }
      );

      if (!res) throw null;
    } catch (e) {
      console.error(e, "error while editing a property value cell");
      // rollback
      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: property, propertyId },
      });
      return e;
    }

    dispatch(setRestVerb("products/plProperties", false, "deleting"));
  };
};

export const getMainPropertyValidation = ({
  objectId,
  objectAssigned,
}: {
  objectId: string;
  objectAssigned: ObjectAssignedEnum;
}) => {
  return async (dispatch, getState) => {
    const selectedProductLineId = getState().products?.selectedProductLine?.id;

    try {
      const { data } = await api.getValidation(
        selectedProductLineId,
        objectId,
        objectAssigned
      );

      dispatch({
        type: GET_MAIN_PROPERTY_VALIDATION_BY_OBJECT_ID,
        payload: { validation: data, objectId },
      });
    } catch (e) {
      console.error(e, "error while getting validation for specific object");
    }
  };
};

export const removePropertyValueRowFromProperty = (
  property,
  propertyValueId
) => {
  return async (dispatch, getState) => {
    const { selectedProductLine } = getState().products;
    const productLineId = selectedProductLine.id;

    try {
      const newProperty = cloneDeep(property);

      newProperty.propertyValues = newProperty.propertyValues.filter(
        (row) => row.id !== propertyValueId
      );

      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: newProperty, propertyId: property.id },
      });

      await api.removePropertyValueRowFromProperty(
        productLineId,
        property.id,
        propertyValueId
      );
    } catch (e) {
      console.error(
        e,
        "error while removing a property value row from property"
      );

      // rollback
      dispatch({
        type: SET_PL_PROPERTY,
        payload: { data: property, propertyId: property.id },
      });
    }
  };
};

export const reOrderPropertyValueAction = ({
  propertyId,
  propertyValueId,
  destinationPosition,
  sourcePosition,
}) => {
  return async (dispatch, getState) => {
    const { selectedProductLine, properties } = getState().products;
    const property = { ...properties.byId[propertyId] };
    const { propertyValues } = property;
    property.propertyValues = reorder(
      propertyValues,
      sourcePosition,
      destinationPosition
    );

    try {
      const newByIdOptimistic = {
        ...properties.byId,
        [propertyId]: property,
      };
      dispatch({
        type: EDIT_PL_PROPERTY,
        payload: newByIdOptimistic,
      });
      await api.reOrderPropertyValueRows(
        propertyId,
        selectedProductLine.id,
        propertyValueId,
        destinationPosition
      );
    } catch (e) {
      // optimistic rollback
      dispatch({
        type: EDIT_PL_PROPERTY,
        payload: { ...properties.byId },
      });

      console.error(e);
    }
  };
};

export const getNextPropertyPage = () => {
  return async (dispatch) => {
    dispatch({ type: SET_PL_PROPERTY_PAGINATION });
  };
};

export const setPropertiesInitialState = () => {
  return {
    type: SET_PROPERTIES_INITIAL_STATE,
    payload: {},
  };
};
