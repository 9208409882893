import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

export function HoveredInputCell({
  initialContent,
  handleSubmit,
  rows = 5,
  className,
  id,
  validationRegex,
  backgroundColor,
  width,
  isNewCell,
}: {
  initialContent: any;
  handleSubmit: any;
  rows?: number;
  className?: any;
  id?: any;
  validationRegex?: RegExp;
  backgroundColor?: string;
  width?: string;
  isNewCell?: boolean;
}) {
  //State
  const [value, setValue] = useState(initialContent);
  const [inputIsFocused, setInputIsFocused] = useState(false);

  //References
  const parentDivRef = useRef<any>(null);
  const textAreaRef = useRef<any>(null);

  //useEffects
  useEffect(() => {
    setValue(initialContent);
  }, [initialContent]);

  //handlers
  const handleTextChange = (e) => {
    const { value } = e.target;
    if (!validationRegex || value.match(validationRegex)) {
      setValue(e.target.value);
    }
  };

  const onBlur = () => {
    setInputIsFocused(false);
    try {
      handleSubmit(value);
      setValue(initialContent);
    } catch (e) {
      console.error(e, "error detected in onBlur");
    }
  };

  //Intercept key strokes
  //On enter key, treat like a click, giving the typeable text area focus and selecting text
  //On escape key, return focus to the background cell
  const handleParentDivKeyDown = (e) => {
    if (e.key === "Enter") {
      handleClickInput();
    } else if (e.key === "Escape") {
      e.preventDefault();
      parentDivRef.current.focus();
    }
  };

  const handleClickInput = () => {
    if (!inputIsFocused) {
      setInputIsFocused(true);

      //Give the text area focus and select the text
      if (textAreaRef.current) {
        setTimeout(() => {
          textAreaRef.current.focus();
          textAreaRef.current.select();
        }, 100);
      }
    }
  };

  const uuid = isNewCell
    ? Math.random().toString()
    : "HoveredInputCellUUID" + initialContent + id;

  return (
    <div
      style={{
        position: "relative",
        width: width || "100%",
        boxSizing: "border-box",
        height: 24,
      }}
      className={className}
      id={uuid}
      tabIndex={0}
      onKeyDown={handleParentDivKeyDown}
      onClick={handleClickInput}
      ref={parentDivRef}
    >
      <StyledDiv
        inputIsFocused={inputIsFocused}
        backgroundColor={backgroundColor}
        width={width}
      >
        {value}
      </StyledDiv>
      <TextArea
        value={value}
        onChange={handleTextChange}
        rows={rows}
        inputIsFocused={inputIsFocused}
        onBlur={onBlur}
        ref={textAreaRef}
        width={width}
      />
    </div>
  );
}

const TextArea = styled.textarea<any>`
  display: ${(props) => (props.inputIsFocused ? "block" : "none")};
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  position: absolute;
  top: 0px;
  z-index: 100;
  width: ${(props) => props.width || "100%"};
  box-sizing: border-box;
  font-size: 14px;
`;

const StyledDiv = styled.div<any>`
  font-size: 14px;
  min-height: 24px;

  width: ${(props) => props.width || "100%"};
  border: 1px solid transparent;
  border-radius: 4px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  :hover {
    border: 1px solid ${(props) => props.theme.colors.secondary};
    white-space: break-spaces;
    position: absolute;
    z-index: 50;
  }

  :focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.secondary};
  }

  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.tables.hoveredCell};
  box-sizing: border-box;
`;
