import React from "react";
import { H4 } from "components/Typography/Headlines.component";
import DashboardQuickActions from "./DashboardQuickActions.component";
import Notifications from "./DashboardNotifications.component";
import Programs from "./DashboardPrograms.component";
import { Row, Button } from "components";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import routes from "modules/router/routes";
import { useTypedSelector } from "store";

export default function DashboardPage() {
  const history = useHistory();
  const { navigationOpen } = useTypedSelector(
    (state) => state.user.preferences
  );

  return (
    <Wrapper navigationOpen={navigationOpen}>
      <StyledRow>
        <H4 style={{ marginBottom: 0, marginTop: 0 }} bold={true}>
          Quick Actions
        </H4>
      </StyledRow>
      <DashboardQuickActions />

      <StyledRow>
        <H4 style={{ marginBottom: 0 }} bold={true}>
          Notifications
        </H4>
        <StyledButton
          size="small"
          color="primary"
          type="secondary"
          onClick={() => history.push(routes.NOTIFICATIONS)}
          disabled
        >
          ALL NOTIFICATIONS
        </StyledButton>
      </StyledRow>
      <Notifications />

      <StyledRow>
        <H4 style={{ marginBottom: 0 }} bold={true}>
          Programs
        </H4>
        <StyledButton
          size="small"
          color="primary"
          type="secondary"
          onClick={() => history.push(routes.PROGRAMS)}
        >
          ALL PROGRAMS
        </StyledButton>
      </StyledRow>
      <Programs />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>`
  padding-left: 10px;
  padding-top: 20px;
  padding-right: 40px;

  @media ${(props) => props.theme.device.mobile} {
    padding-left: ${(props) => (props.navigationOpen ? "50px" : "10px")};
  }
`;

const StyledRow = styled<any>(Row)`
  justify-content: space-between;
  margin-top: 24px;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-top: 1.33em;
`;
