import React, { useState } from "react";
import styled from "styled-components";
import { Button, LoaderSpinner2 } from "components";
import {
  ErrorObject,
  ObjectAssignedEnum,
} from "store/reducers/products/validation/validation.types";
import RulesTable from "../../../../components/RulesTable/RulesTable.component";
import { useDispatch } from "react-redux";
import { getRuleTableAction } from "store/reducers/products/rulestables/pl-rulestables.actions";
import { useTypedSelector } from "store";
import { GetParentPropertyName } from "modules/utility/utility";

export default function ValidationItem({
  validationItemData,
}: {
  validationItemData: ErrorObject[];
}) {
  const [showRuleTable, setShowRuleTable] = useState(false);

  const dispatch = useDispatch();

  const getting = useTypedSelector(
    (state) => state.products.rulestables.getting
  );
  const productLineId = useTypedSelector(
    (state) => state.products.selectedProductLine?.id ?? ""
  );

  const { objectId } = validationItemData[0];
  const name = validationItemData[0]?.objectInfo?.Name ?? "";
  const { objectAssigned } = validationItemData[0];

  const ruletableData =
    useTypedSelector((state) => state.products.rulestables.byId[objectId]) ||
    null;

  const goToProperty = () => {
    // Get the parent property name based on the provided 'name'
    const parentPropertyName = GetParentPropertyName(name);

    // Construct the URL
    const url = `/products/${productLineId}/properties?name=${parentPropertyName}`;

    // Open the URL in a new tab
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleShowRuleTableButton = () => {
    dispatch(getRuleTableAction({ tableId: objectId }));
    setShowRuleTable((prevState) => !prevState);
  };

  return (
    <Wrapper>
      <ValidationObjectWrapper>
        <ValidationItemWrapper>
          <ObjectName>{objectAssigned + " " + (name || "")}</ObjectName>
          {validationItemData.map((errorObject) => {
            const { message, id } = errorObject;
            return <Message key={id}>{"- " + message}</Message>;
          })}
        </ValidationItemWrapper>
        {objectAssigned === ObjectAssignedEnum.RULETABLE && (
          <Button
            size={"small"}
            height={"30px"}
            width={"120px"}
            onClick={handleShowRuleTableButton}
          >
            {showRuleTable ? "hide table" : "show table"}
          </Button>
        )}
        {objectAssigned === ObjectAssignedEnum.PROPERTY && (
          <Button
            size={"small"}
            height={"30px"}
            width={"150px"}
            onClick={goToProperty}
          >
            {"go to property"}
          </Button>
        )}
      </ValidationObjectWrapper>
      {showRuleTable && ruletableData && !getting && (
        <RulesTableWrapper>
          <RulesTable
            id={objectId}
            ruleTableData={ruletableData}
            hideCommentsAndSources
          />
        </RulesTableWrapper>
      )}
      {showRuleTable && getting && (
        <SpinnerWrapper>
          <LoaderSpinner2 />
        </SpinnerWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.tables.borders};
  margin-bottom: 32px;
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RulesTableWrapper = styled.div`
  margin-top: 16px;
`;

const ValidationObjectWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ObjectName = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const ValidationItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;
