import Fuse from "fuse.js";

export default function fuzzySearch(options) {
  const fuse = new Fuse(options, {
    keys: ["name"],
    threshold: 0.3,
  });

  return (value) => {
    if (!value.length) {
      return options;
    }

    const search = fuse.search(value);
    return search.map((r) => r.item);
  };
}
