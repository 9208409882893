import React, { createContext, useEffect } from "react";
import { useTypedSelector } from "store";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Button, Loader1, Modal } from "components";
import { setRulesTablesInitialState } from "store/reducers/products/rulestables/pl-rulestables.actions";
import { sortValidationByObject } from "./helpers";
import ValidationList from "./ValidationList.component";

// Create a context.  This will be used by child components to understand if they
//are being rendered inside a ValidationModal or not
export const ValidationModalContext = createContext(false);

export default function ValidationModal({
  open,
  closeModal,
  handleExport,
}: {
  open: boolean;
  closeModal: () => void;
  handleExport: any;
}) {
  const { getting, byId } = useTypedSelector(
    (state) => state.products.validation
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRulesTablesInitialState());
  }, []);

  const sortedValidationObjects = sortValidationByObject(byId);

  const handleRefreshValidation = () => {
    handleExport();
  };

  return (
    <ValidationModalContext.Provider value={true}>
      <Modal
        width={"999px"}
        height={"80vh"}
        open={open}
        closeModal={closeModal}
        title={"Validation"}
      >
        {getting ? (
          <Loader1 />
        ) : (
          <Wrapper>
            <Info>
              Before exporting the Product Line, fix the errors below:
            </Info>
            <ValidationList validationData={sortedValidationObjects} />
            <RefreshButtonWrapper>
              <Button onClick={handleRefreshValidation} size={"small"}>
                Refresh list
              </Button>
            </RefreshButtonWrapper>
          </Wrapper>
        )}
      </Modal>
    </ValidationModalContext.Provider>
  );
}

const Wrapper = styled.div`
  text-align: left;
  width: 100%;
  height: 100%;
`;

const RefreshButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 32px;
  padding-bottom: 32px;
`;

const Info = styled.div`
  font-size: 14px;
  margin-bottom: 32px;
`;
