// TODO: in no way this is a security feature, this is only to avoid
// strange rendering difference when copy/pasting and to allow proper trimming
// to avoid <script> injection, do it in the backend

export function secureTrim(input) {
  try {
    if (typeof input === "string") {
      const sanitized = input.replace("/&nbsp;/g", " ");
      return sanitized.trim();
    }
    return input;
  } catch (e) {
    console.error(e);
    return input;
  }
}
