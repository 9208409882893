import React from "react";
import styled from "styled-components";

export interface Position {
  left: string;
  center: string;
}

const flexAlignItems: Position = {
  left: "flex-start",
  center: "center",
};

export function CardCustom({
  children,
  className,
  onClick,
  itemAlignment = "left",
  contentEditable = false,
  onBlur,
  textRef,
  dangerouslySetInnerHTML,
}: {
  children?: any;
  className?: any;
  onClick?: any;
  itemAlignment: "left" | "center";
  contentEditable?: boolean;
  onBlur?: any;
  textRef?: any;
  dangerouslySetInnerHTML?: any;
}) {
  return (
    <Wrapper
      onClick={onClick}
      className={className}
      itemAlignment={itemAlignment}
      contentEditable={contentEditable}
      onBlur={onBlur}
      ref={textRef}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => flexAlignItems[props.itemAlignment]};
  justify-content: center;
  text-align: ${(props) => props.itemAlignment};
  padding: 16px;

  width: 348px;
  height: 248px;
  max-width: 100%;
  cursor: pointer;

  background: ${(props) => props.theme.colors.white};

  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  box-sizing: border-box;
  border-radius: 4px;
`;
