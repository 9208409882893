import React, { useState } from "react";
import { Button, LoaderSpinner2, Modal } from "components";
import { api } from "../../../../api/api";
import {
  exportToTSVFile,
  getFilenameFromContentDisposition,
} from "../../../../api/helpers";
import { useTypedSelector } from "store";
import styled from "styled-components";

enum ExportStates {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}

export default function ExportRuleTableToTSVButton({
  ruleTableId,
}: {
  ruleTableId: string;
}) {
  const [exportStatus, setExportStatus] = useState(ExportStates.IDLE);
  const [exportError, setExportError] = useState<any>(undefined);

  const closeExportErrorModal = () => {
    setExportStatus(ExportStates.IDLE);
  };

  const { selectedProductLine: productLine } = useTypedSelector(
    (state) => state.products
  );

  const handleExport = async () => {
    setExportStatus(ExportStates.PENDING);

    try {
      //Call the api to export the table
      const res = await api.exportRuleTableToTSV(productLine?.id, ruleTableId);
      if (res?.response?.status === 400) {
        setExportError(res.response.data);
        setExportStatus(ExportStates.ERROR);
        throw null;
      }

      setExportStatus(ExportStates.SUCCESS);

      const filename = getFilenameFromContentDisposition(
        // it is lowercase in local and capitalized when deployed
        res.headers["content-disposition"] || res.headers["Content-Disposition"]
      );
      exportToTSVFile(res.data, filename || productLine?.name);
    } catch (e) {
      console.error(e, "error while exporting product line");
    }
  };

  return (
    <Wrapper>
      <Button
        size="small"
        type="secondary"
        disabled={exportStatus === ExportStates.PENDING}
        onClick={() => handleExport()}
      >
        Export Table to TSV
      </Button>
      {exportStatus === ExportStates.PENDING && <LoaderSpinner2 />}
      {exportStatus === ExportStates.ERROR && (
        <Modal
          open={exportStatus === ExportStates.ERROR}
          closeModal={closeExportErrorModal}
          title={"Export tool error"}
        >
          {typeof exportError !== undefined && exportError}
        </Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
