import React from "react";
import colors from "../../../../theme/colors";
import { RulesTableCell, IDropDownValue } from "./RulesTableCell";
import {
  TablesTypeEnum,
  tablesTypes,
} from "store/reducers/products/rulestables/pl-rulestables.types";
import RulesTablePropertyHeader from "./RulesTablePropertyHeader";
import { RulesTableSettingsInput } from "./RulesTableSettingsInput.component";
import { ContextMenuTrigger } from "react-contextmenu";

const dataCellStyles = {
  minHeight: "40px",
  minWidth: "200px",
  fontSize: "14px",
  backgroundColor: colors.white,
};

const headerCellStyles = {
  backgroundColor: colors.tables.columnsAndRowsTitles,
  color: colors.primary,
  minWidth: "180px",
  minHeight: "40px",
  padding: "12px 20px",
  fontWeight: "700",
};

const contentCellStyles = {
  ...headerCellStyles,
  fontWeight: "400",
  backgroundColor: colors.white,
};

const propertiesCellStyles = {
  ...dataCellStyles,
  backgroundColor: colors.tables.hoveredCell,
};

const isLookupItemCheck = (propertyIndex, tableLookupIndex, tableType) => {
  if ([TablesTypeEnum.ATTR_LOOKUP, TablesTypeEnum.LOOKUP].includes(tableType))
    return propertyIndex <= tableLookupIndex;
  return false;
};

export const findRulesetIntersectionInProperties = (ruleTableProperties) => {
  if (!ruleTableProperties) return null;
  const rulesetsByProperty = ruleTableProperties.map(
    (property) => property.ruleSets
  );

  const uniqueRulesets = rulesetsByProperty.flat().reduce((acc, curr) => {
    if (!acc.find((item) => item.id === curr.id)) return [...acc, curr];
    return acc;
  }, []);

  return uniqueRulesets.reduce((acc, curr) => {
    if (
      rulesetsByProperty.every((set) =>
        set.map((ruleset) => ruleset.id).includes(curr.id)
      )
    )
      return [...acc, curr];
    return acc;
  }, []);
};

export const getTableData = (
  ruleSets?: any,
  ruleTableData?: any,
  ruleSetsList?: any
) => {
  const currentRulesets: Array<any> = [];
  if (ruleSets && ruleSets.length > 0) {
    ruleSets.map((ruleset) => {
      if (ruleSetsList && ruleSetsList.length > 0) {
        ruleSetsList.map((listEl) => {
          if (listEl.id === ruleset?.id) {
            currentRulesets.push({ value: listEl.id, name: listEl.name });
          }
        });
      }
    });
  }

  const availableRulesetFromProperties = findRulesetIntersectionInProperties(
    ruleTableData.properties
  );

  const formattedRulesets: Array<any> = [];

  if (availableRulesetFromProperties && availableRulesetFromProperties.length) {
    availableRulesetFromProperties.map((ruleset) =>
      formattedRulesets.push({ value: ruleset.id, name: ruleset.name })
    );
  }

  if (
    ruleSetsList &&
    ruleSetsList.length > 0 &&
    !availableRulesetFromProperties.length
  ) {
    ruleSetsList.map((ruleset) =>
      formattedRulesets.push({ value: ruleset.id, name: ruleset.name })
    );
  }

  const formattedTablesTypesOptions: Array<any> = [];
  if (tablesTypes && tablesTypes.length > 0) {
    tablesTypes.map((type) => {
      formattedTablesTypesOptions.push({ value: type, name: type });
    });
  }

  return {
    header: [],
    rows: [
      [
        {
          content: "TABLE",
          style: headerCellStyles,
          id: "tableTypeTitleColumn",
        },
        {
          content: (
            <RulesTableSettingsInput
              rulesTableParameterType={"tableType"}
              options={formattedTablesTypesOptions}
              currentValue={{
                value: ruleTableData.tableType,
                name: ruleTableData.tableType,
              }}
              placeholder="select table type"
              multiselect={false}
              ruleTableData={ruleTableData}
            />
          ),
          id: "tableTypeEditCell",
          style: contentCellStyles,
        },
      ],
      (ruleTableData.tableType === "LOOKUP" ||
        ruleTableData.tableType === "ATTR_LOOKUP") && [
        {
          content: "LOOKUP PROPERTIES",
          style: headerCellStyles,
          id: "lookupItemTitleCellUUID",
        },
        {
          content: (
            <RulesTableSettingsInput
              rulesTableParameterType={"lookUpFieldIndex"}
              currentValue={ruleTableData.lookUpFieldIndex}
              ruleTableData={ruleTableData}
            />
          ),
          style: dataCellStyles,
        },
      ],
      [
        {
          content: "RULESETS",
          style: headerCellStyles,
          id: "rulesetTitleCellUUID",
        },
        {
          content: (
            <RulesTableSettingsInput
              rulesTableParameterType={"rulesetIds"}
              options={formattedRulesets}
              currentValue={currentRulesets}
              placeholder="select rulesets"
              multiselect={true}
              ruleTableData={ruleTableData}
            />
          ),
          style: dataCellStyles,
          id: "RulesetsUUIDDropdown",
        },
      ],
      ruleTableData.ownerTool !== "" && [
        {
          content: "OWNER TOOL",
          style: headerCellStyles,
          id: "OwnerToolUUID",
        },
        {
          content: ruleTableData?.ownerTool ?? "empty",
          style: dataCellStyles,
          id: "ownerToolCellUUID",
        },
      ],
    ],
  };
};

export const getPropertiesData = (ruleTableData: any) => {
  let index = 0;
  const selectedProperties = ruleTableData?.properties.map((property) => {
    index += 1;
    return {
      content: (
        <RulesTablePropertyHeader
          propertyName={property.name}
          propertyId={property.id}
          ruleTableId={ruleTableData.id}
          index={index}
        />
      ),
      style: headerCellStyles,
      id: property.id,
      isDraggable: true,
    };
  });

  const ruleTableRows =
    ruleTableData?.rows.length > 0
      ? ruleTableData?.rows.map((row) => {
          return row.cells
            .filter((cell) => cell.propertyId)
            .map((cell, index) => {
              const property = ruleTableData?.properties.find(
                (property) => property.id === cell.propertyId
              );

              const { lookUpFieldIndex, tableType } = ruleTableData;

              const isLookupItem = isLookupItemCheck(
                index + 1,
                lookUpFieldIndex,
                tableType
              );

              //Build the info we need to populate drop drop option value
              const cellOptions: Array<IDropDownValue> = property.propertyValues
                .length
                ? property.propertyValues.map((pv) => {
                    return {
                      value: pv.id,
                      label: pv?.valueAttributeCells[0].value, //TODO: assumes Value is first value attribute cell.  Refactor?
                      description: "", //TODO add description pva here
                      isInTableRulesets: pv.isInTableRulesets,
                    };
                  })
                : null;

              //Build drop down option info object for each value in the cell
              const cellValues: Array<IDropDownValue> = cell.value.map((v) => {
                //If we have cell options (discrete property, try to find value and use its info)
                let valueOption = cellOptions?.find(
                  (option) => option.value === v
                );

                //If we have no cell option available, just use our value to populate the object
                if (!valueOption)
                  valueOption = {
                    value: v,
                    label: v,
                    description: "",
                    isInTableRulesets: false,
                  };
                return valueOption;
              });

              const { dataType, domain } = property;

              return {
                content: (
                  <ContextMenuTrigger id={row.id + cell.propertyId}>
                    <RulesTableCell
                      domain={domain}
                      dataType={dataType}
                      options={cellOptions?.filter((o) => o.isInTableRulesets)}
                      originalValue={cellValues}
                      placeholder="select properties"
                      rowId={row.id}
                      cellId={cell.propertyId}
                      ruleTableId={ruleTableData.id}
                      isLookupItem={isLookupItem}
                    />
                  </ContextMenuTrigger>
                ),
                style: propertiesCellStyles,
                id: "PropertiesRLCell" + cell.propertyId,
                rowId: row.id,
              };
            });
        })
      : [];

  return {
    header: selectedProperties.filter((property) => property?.id),
    rows: ruleTableRows,
  };
};
