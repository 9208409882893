import React from "react";
import { Table } from "components/Table/Table.component";
import colors from "theme/colors";
import TitleBar from "./TitleBar.component";
import styled from "styled-components";
import { convertTimestampToDate } from "helpers/datesConverter";
import { membersDeclination } from "helpers/declinations";
import { useHistory } from "react-router-dom";
import { mappedStatusToElement } from "store/reducers/programs/programs.mocks";

export default function ListView({ mockedPrograms }: { mockedPrograms?: any }) {
  const history = useHistory();

  const tableData = {
    header: [
      {
        content: "NAME",
      },
      {
        content: "DUE DATE",
      },
      {
        content: "TASKS",
      },
      {
        content: "STATUS",
      },
      {
        content: "MEMBERS",
      },
    ],
    rows: mockedPrograms.map((el) => {
      const daysLeft = convertTimestampToDate(el.daysLeft);
      return [
        {
          content: (
            <div onClick={() => history.push(`programs/${el.id}/overview`)}>
              <NameCell>{el.name}</NameCell>
            </div>
          ),
          style: {
            fontWeight: 700,
            width: "374px",
          },
        },
        {
          content: daysLeft,
          style: {
            width: "179.5px",
          },
        },
        {
          content: el.tasksRemaining,
          style: {
            width: "179.5px",
          },
        },
        {
          content: mappedStatusToElement[el.status].label,
          style: {
            fontWeight: 700,
            color: colors.white,
            backgroundColor: mappedStatusToElement[el.status].color,
            textAlign: "center",
            width: "179.5px",
          },
        },
        {
          content:
            el.teamMembers.length +
            " " +
            membersDeclination(el.teamMembers.length),
          style: {
            width: "179.5px",
          },
        },
      ];
    }),
  };
  return (
    <>
      <TitleBar title={"Unsorted Programs"} amount={mockedPrograms.length} />
      <ListBox>
        <Table canAddRow={false} data={tableData} />
      </ListBox>
    </>
  );
}

const ListBox = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const NameCell = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
