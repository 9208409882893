import styled from "styled-components";

export const MobileOnly = styled.div<any>`
  display: block;
  @media ${(props) => props.theme.device.mobile} {
    display: ${(props) => (props.show ? "block" : "none")};
  }
`;

export const DesktopOnly = styled.div<any>`
  display: none;
  @media ${(props) => props.theme.device.mobile} {
    display: ${(props) => (props.hide ? "none" : "block")};
  }
`;
