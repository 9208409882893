import { ValidationState } from "./validation.types";
import {
  SET_VALIDATION,
  CLEAR_VALIDATION,
  ADD_VALIDATION,
  SET_VERB,
  DELETE_VALIDATION,
} from "./validation.action";

export const plValidationInitialState: ValidationState = {
  byId: {},
  allIds: [],

  getting: false,
  deleting: false,
  posting: false,
};

export function plValidationReducer(
  validationState: ValidationState = plValidationInitialState,
  action: any
) {
  switch (action.type) {
    case SET_VALIDATION:
      return {
        ...validationState,
        ...action.payload,
      };

    case ADD_VALIDATION:
      return {
        ...validationState,
        byId: {
          ...validationState.byId,
          [action.payload.data.id]: action.payload.data,
        },
        allIds: [...validationState.allIds, action.payload.data.id],
      };

    case DELETE_VALIDATION:
      return {
        ...validationState,
        byId: action.payload.newById,
        allIds: action.payload.newAllIds,
      };

    case CLEAR_VALIDATION:
      return plValidationInitialState;

    case SET_VERB: {
      return {
        ...validationState,
        ...action.payload,
      };
    }

    default:
      return validationState;
  }
}
