import React from "react";
import { Button } from "../Buttons/Button.component";

export function LoadMoreButton({
  onClick,
  moreItems,
}: {
  onClick: () => void;
  moreItems: boolean;
}) {
  const handleClick = () => {
    if (moreItems) onClick();
  };

  return (
    <Button
      type={"secondary"}
      size={"small"}
      onClick={handleClick}
      disabled={!moreItems}
    >
      Load more
    </Button>
  );
}
