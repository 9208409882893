import React from "react";
import colors from "../../../../theme/colors";
import { StandardSubProperty } from "store/reducers/products/properties/pl-properties.types";
import { StandardSubPropertiesInput } from "./RulesetsDropdown.component";
import { Ruleset } from "store/reducers/products/parameters/pl-parameters.types";

export const getTableData = (
  propertyData: StandardSubProperty[],
  ruleSetsOptions: Ruleset[] = []
) => {
  const headerStyles = { color: colors.muttedHeadlines };

  const currenValueFormatted = (ruleSets) => {
    return ruleSets.map((ruleSet) => ({
      value: ruleSet.id,
      name: ruleSet.name,
      key: ruleSet.id,
    }));
  };

  const rows = Object.keys(propertyData).map((subProperty) => {
    return [
      { content: propertyData[subProperty].name, key: subProperty + ".name" },
      {
        content: (
          <StandardSubPropertiesInput
            propertyData={propertyData[subProperty]}
            options={currenValueFormatted(ruleSetsOptions)}
            currentValue={currenValueFormatted(
              propertyData[subProperty]?.ruleSets ?? []
            )}
            type="rulesets"
          />
        ),
      },
      {
        content: (
          <StandardSubPropertiesInput
            propertyData={propertyData[subProperty]}
            currentValue={propertyData[subProperty]?.default ?? ""}
            type="default"
          />
        ),
        key: subProperty + ".default",
        style: { "max-width": "200px" },
      },
    ];
  });

  return {
    header: [
      { content: "Name", style: headerStyles },
      { content: "Rulesets", style: headerStyles },
      { content: "Static Value", style: headerStyles },
    ],
    rows: rows,
  };
};
