import styled from "styled-components";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export default function ToastWrapper({ children }: { children?: any }) {
  return (
    <Wrapper>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        draggable={true}
        pauseOnHover={true}
      />
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  /** Used to define container behavior: width, position: fixed etc... **/
  .Toastify__toast-container {
    /* nothing more important than a toast, and a stupid value for a z-index */
    z-index: 9999999999999;
  }

  /** Used to define the position of the ToastContainer **/
  .Toastify__toast-container--top-left {
  }
  .Toastify__toast-container--top-center {
  }
  .Toastify__toast-container--top-right {
  }
  .Toastify__toast-container--bottom-left {
  }
  .Toastify__toast-container--bottom-center {
  }
  .Toastify__toast-container--bottom-right {
  }

  /** Classes for the displayed toast **/
  .Toastify__toast {
  }
  .Toastify__toast--rtl {
  }
  .Toastify__toast--dark {
  }
  .Toastify__toast--default {
  }
  .Toastify__toast--info {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast--warning {
    background-color: #ffb400;
  }
  .Toastify__toast--error {
    background-color: ${(props) => props.theme.colors.error};
  }
  .Toastify__toast-body {
  }

  /** Classes for the close button. Better use your own closeButton **/
  .Toastify__close-button {
  }
  .Toastify__close-button--default {
  }
  .Toastify__close-button > svg {
  }
  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
  }

  /** Classes for the progress bar **/
  .Toastify__progress-bar {
  }
  .Toastify__progress-bar--animated {
  }
  .Toastify__progress-bar--controlled {
  }
  .Toastify__progress-bar--rtl {
  }
  .Toastify__progress-bar--default {
  }
  .Toastify__progress-bar--dark {
  }
`;
