import React from "react";
import styled from "styled-components";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button } from "../Buttons/Button.component";
import { useConfirmActionModalContext } from "./ConfirmActionContext";

export default function ConfirmActionButton({
  message,
  action,
  buttonType,
  className,
}: {
  message: string;
  action: () => void;
  buttonType: "trashIcon" | "button";
  className?: string;
}) {
  const handleClick = () => {
    setConfirmAction(() => handleConfirm);
    setMessage(message);
    setDisplay(true);
  };

  const handleConfirm = () => {
    action();
    setDisplay(false);
  };

  const {
    setDisplay,
    setConfirmAction,
    setMessage,
  } = useConfirmActionModalContext();

  return (
    <Wrapper className={className}>
      {buttonType === "trashIcon" && (
        <StyledTrashIcon fontSize="small" onClick={handleClick} />
      )}
      {buttonType === "button" && (
        <Button
          color="error"
          size="small"
          type="secondary"
          onClick={handleClick}
        >
          Delete Table
        </Button>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const StyledTrashIcon = styled(DeleteOutlineIcon)<any>`
  color: rgb(2, 123, 253);
  cursor: pointer;
  opacity: 0.25;
  position: relative;
  top: 2px;
  :hover {
    opacity: 1;
  }
`;
