export interface ParametersState {
  ruleSets: Array<Ruleset>;
  propertyValueAttributes: Array<PropertyValueAttribute>;
  tags: Array<Tag>;

  newPropertyValueAttribute?: PropertyValueAttribute;
  newRuleset: Ruleset;
  newTag: Tag;

  getting: boolean;
  deleting: boolean;
  posting: boolean;
}

export enum RulesetKeys {
  NAME = "name",
  DESCRIPTION = "description",
}

export interface Ruleset {
  [RulesetKeys.NAME]: string;
  [RulesetKeys.DESCRIPTION]: string;
  id?: string;
}

export enum PropertyValueAttributeKeys {
  NAME = "name",
  TYPE = "type",
}

export interface PropertyValueAttribute {
  [PropertyValueAttributeKeys.NAME]: string;
  [PropertyValueAttributeKeys.TYPE]: string;
  id?: string;
}

export enum TagKeys {
  NAME = "name",
  DESCRIPTION = "description",
  CATEGORY = "category",
}

export interface Tag {
  [TagKeys.NAME]: string;
  [TagKeys.DESCRIPTION]: string;
  [TagKeys.CATEGORY]: string;
  id?: string;
}
