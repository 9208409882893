import React from "react";
import { useTypedSelector } from "store";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import LoaderWrapper from "containers/LoaderWrapper/LoaderWrapper.container";
import ProductLineCardContainer from "pages/Products/components/ProductLineCard/ProductLineCard.container";

export default function ProductsGridView({
  doNotDisplayLoader,
}: {
  doNotDisplayLoader?: boolean;
}) {
  const { products, getting } = useTypedSelector((state) => state.products);
  const history = useHistory();

  if (products?.length === 0 && !getting)
    return <span>No product lines to display</span>;

  return (
    <LoaderWrapper loading={getting && !doNotDisplayLoader}>
      {products.map((item) => {
        return (
          <StyledProductLineCard
            key={item.id}
            productLineName={item.name}
            productLineId={item.id}
            img={item.img}
            onClick={() => history.push(`products/${item.id}/overview`)}
          />
        );
      })}
    </LoaderWrapper>
  );
}

const StyledProductLineCard = styled(ProductLineCardContainer)<any>`
  margin: 0 40px 40px 0;
`;
