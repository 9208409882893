import React from "react";
import styled from "styled-components";
import { NormalP, SmallP } from "components/Typography/Paragraphs.component";

export interface CardDashboardProps {
  title: string;
  description: string;
  icon: any;
  className?: any;
  onClick: () => void;
  disabled?: boolean;
}

export function CardDashboard({
  title,
  description,
  icon,
  className,
  onClick,
  disabled,
}: CardDashboardProps) {
  const handleClick = () => {
    if (disabled) return;
    onClick();
  };
  return (
    <Wrapper className={className} onClick={handleClick} disabled={disabled}>
      <IconContainer>{icon}</IconContainer>
      <TextContainer>
        <NormalP bold>{title}</NormalP>
        <SmallP>{description}</SmallP>
      </TextContainer>
    </Wrapper>
  );
}

const TextContainer = styled.div`
  padding: 16px;
`;

const IconContainer = styled.div``;

const Wrapper = styled.div<any>`
  :hover {
    box-shadow: ${(props) =>
      props.disabled ? "none" : "0 10px 20px 0 rgba(0, 0, 0, 0.2)"};
  }

  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;

  width: 348px;
  height: 112px;
  max-width: 100%;

  background: ${(props) =>
    props.disabled ? props.theme.colors.disabled : props.theme.colors.white};

  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  box-sizing: border-box;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
