import React, { useEffect } from "react";
import { SearchableDropdown } from "components";
import styled from "styled-components";
import colors from "theme/colors";
import PropertySearchDropdown from "./PropertySearchDropDown.component";
import { useRulesTableContext } from "../../pages/ProductDetail/ProductLineRulesTables/RulesTableContext";

export default function RulesTableSearchInput({
  filteredOptionsFormatted,
  newProperty,
  type,
  setSelectedProperty,
  setSelectedPva,
}: {
  filteredOptionsFormatted?: any;
  newProperty?: any;
  type: "property" | "pva";
  setSelectedProperty?: any;
  setSelectedPva?: any;
}) {
  useEffect(() => {
    if (newProperty) setSelectedProperty(newProperty.value);
  }, []);

  const handleChange = (e) => {
    if (type === "property") {
      setSelectedProperty(e);
      setSelectedPva(null);
    }

    if (type === "pva") {
      setSelectedPva(e);
    }
  };

  const rulesetFilter = useRulesTableContext().rulesets.map(
    (ruleset) => ruleset?.name
  );

  return (
    <>
      {type === "property" && (
        <StyledPropertySearchComponent
          placeholder={"select properties"}
          multiselect={false}
          onChange={handleChange}
          name="addNewProperty"
          rulesetFilter={rulesetFilter}
        />
      )}
      {type === "pva" && (
        <StyledInput
          options={filteredOptionsFormatted}
          value={newProperty}
          placeholder={"select pva"}
          multiple={false}
          handleChange={(e) => handleChange(e)}
          name="addNewProperty"
        />
      )}
    </>
  );
}

const StyledInput = styled(SearchableDropdown)`
  > div > div > input {
    background-color: ${colors.tables.columnsAndRowsTitles} !important;
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    height: 32px !important;
    border: 1px solid ${colors.tables.borders} !important;
  }
`;

const StyledPropertySearchComponent = styled(PropertySearchDropdown)<any>`
  > div > div > input {
    background-color: ${colors.tables.columnsAndRowsTitles} !important;
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    height: 32px !important;
    border: 1px solid ${colors.tables.borders} !important;
  }
`;
