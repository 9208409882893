import { CarrierUser } from "../../users/users.types";
import { setRestVerb } from "store/reducers/generalActions";
import { api } from "api/api";
import { UsersPL } from "./pl-users.types";

export const SET_PL_USERS = "products/plUsers/SET_USERS";
export const SET_PL_USER = "products/plUsers/SET_USER";
export const SET_VERB = "products/plUsers/REST_VERB";

export const setProductLineUsers = (users: UsersPL) => {
  return {
    type: SET_PL_USERS,
    payload: users,
  };
};

export const editUser = (productLineId: string, userId, body) => {
  return async (dispatch) => {
    try {
      const { data } = await api.updateProductLineUser(
        productLineId,
        userId,
        body
      );

      if (!data) throw null;

      dispatch({ type: SET_PL_USER, payload: { ...data } });
    } catch (e) {
      console.error(e, "error while editing user");
    }
  };
};

export const addUsersToProductLineAction = (user: CarrierUser) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plUsers", true, "posting"));

    try {
      const { products } = getState();
      const { selectedProductLine } = products;

      await api.addUserToProductLine(
        selectedProductLine.id,
        user.userID.toString()
      );
    } catch (e) {
      console.error(e, "error while adding a user to product line");
    }
    dispatch(setRestVerb("products/plUsers", false, "posting"));
  };
};

export const removeUserFromProductLineAction = (userID: string) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plUsers", true, "deleting"));

    try {
      const { products } = getState();
      const { selectedProductLine } = products;

      await api.removeUserFromProductLine(selectedProductLine.id, userID);
    } catch (e) {
      console.error(e, "error while deleting user");
    }
    dispatch(setRestVerb("products/plUsers", false, "deleting"));
  };
};
