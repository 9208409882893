//dPDR Front end UI URLs
export const URL_UI_DEV = "https://dpdr-devui.carrier.com";
export const URL_UI_PROD = "https://dpdr-produi.carrier.com";

//dPDR Back end API URLs
export const URL_API_DEV = "https://dpdr-dev.carrier.com/dpdr/";
export const URL_API_PROD = "https://dpdr.carrier.com/dpdr/";

//Carrier User Management API URL
export const URL_CARRIER_USERMANAGEMENT_API =
  "https://api.carrierappshub1.com/";

//Carrier User Management Application Names
export const UM_APP_NAME_DEV = "dPDR - (AWS - Dev)";
export const UM_APP_NAME_PROD = "dPDR - (AWS - Production)";
