import React from "react";
import { CardDashboardProps, CardDashboard } from "components";
import {
  ConfigureIcon,
  ModifyIcon,
  CreateIcon,
} from "assets/logos/icons/QuickActions";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import routes from "modules/router/routes";

interface QuickAction extends Partial<CardDashboardProps> {
  title: string;
  description: string;
  icon: any;
  path: string;
}

const quickActions: Array<QuickAction> = [
  {
    title: "Configure a Product Line",
    description: "A short description of configuring a product line.",
    icon: <ConfigureIcon />,
    path: routes.PRODUCTS,
  },
  {
    title: "Modify Component Data",
    description: "A short description of modifying a component data.",
    icon: <ModifyIcon />,
    path: routes.COMPONENT_LIBRARY,
    disabled: true,
  },
  {
    title: "Create New Product",
    description: "A short description of creating a product.",
    icon: <CreateIcon />,
    path: routes.PRODUCTS,
  },
];

export default function DashboardQuickActions() {
  const history = useHistory();
  return (
    <Wrapper>
      {quickActions.map(
        ({ title, description, icon, path, disabled }: QuickAction) => {
          return (
            <StyledCardDashboard
              title={title}
              description={description}
              icon={icon}
              onClick={() => history.push(path)}
              key={title}
              disabled={disabled}
            />
          );
        }
      )}
    </Wrapper>
  );
}

const StyledCardDashboard = styled(CardDashboard)`
  margin-top: 24px;

  @media ${(props) => props.theme.device.mobile} {
    margin-right: 24px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  flex-direction: column;
  width: 100%;
  max-width: 80vw;

  @media ${(props) => props.theme.device.mobile} {
    flex-direction: row;
  }
`;
