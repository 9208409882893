import { toast } from "react-toastify";

export function editTaskStateById(
  newValue,
  state: { byId: any; allIds: Array<string> }
) {
  const newState = { ...state, byId: { ...state.byId } };
  newState.byId[newValue.id] = newValue;

  return newState;
}

export default function filtersToString(category, elements) {
  let url = "";
  let newTableTypes: string[] = [];
  if (typeof elements === "string") {
    newTableTypes.push(elements);
  } else if (elements !== undefined && elements.length > 0) {
    newTableTypes = elements;
  }
  if (newTableTypes && newTableTypes.length > 0) {
    newTableTypes.map((tableType) => {
      url = url + category + "=" + tableType + "&";
    });
  }
  return url;
}

export function propertyErrorHandler(error: any, defaultErrorMessage: string) {
  const errorMessage = error?.response?.data?.message ?? defaultErrorMessage;

  console.error(error, defaultErrorMessage);
  toast.error(`${errorMessage}`);
}
