import { USER_SET_USER, SET_PREFERENCES } from "./user.actions";
import { User } from "./user.types";

export interface UserReducerState extends User {
  role: string;
  permissions: any;
  preferences: {
    navigationOpen: boolean;
  };
}

const initialState: UserReducerState = {
  name: "",
  firstName: "",
  lastName: "",
  role: "Lumberjack",
  id: "123e4567-e89b-12d3-a456-426614174000",
  email: "john.doe@carrier.com",
  permissions: {},
  preferences: {
    navigationOpen: false,
  },
};

export default function userReducer(
  state: UserReducerState = initialState,
  action: any
): UserReducerState {
  switch (action.type) {
    // be aware, this doesn't reset the store (...state)
    case USER_SET_USER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_PREFERENCES: {
      return {
        ...state,
        preferences: { ...state.preferences, ...action.payload },
      };
    }

    default:
      return state;
  }
}
