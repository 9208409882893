import React, { useState } from "react";
import { useTypedSelector } from "../../../../../store";
import { Button, SearchableDropdown } from "../../../../../components";
import { useDispatch } from "react-redux";
import { Close } from "../../../../../assets/logos/icons/MicroIcons";
import styled from "styled-components";
import colors from "../../../../../theme/colors";
import { addPropertyPriorityList } from "../../../../../store/reducers/products/propertyPriorities/pl-propertyPriorities.action";
import { selectUnavailableRulesets } from "../../../../../store/reducers/products/propertyPriorities/pl-propertyPriorities.selectors";

export default function AddPriorityListButton() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedRuleset, setSelectedRuleset] = useState<string | undefined>(
    undefined
  );
  const { ruleSets } = useTypedSelector((state) => state.products.parameters);

  const unavailableRulesets = useTypedSelector((state) =>
    selectUnavailableRulesets(state)
  );

  const rulesetsOptions = ruleSets.reduce((prev: any[], ruleset: any): any => {
    if (!unavailableRulesets.includes(ruleset.name))
      return [...prev, { name: ruleset.name, value: ruleset.id }];
    return prev;
  }, []);

  const dispatch = useDispatch();

  const handleAddPropertyPriorityList = () => {
    setShowDropdown(true);
  };

  const handleSelectRuleset = (e) => {
    setSelectedRuleset(e);
  };

  const handlePressClose = () => {
    setSelectedRuleset(undefined);
    setShowDropdown(false);
  };

  const handleSubmit = () => {
    if (!selectedRuleset) return;

    const propertyPriorityListObject = {
      rulesetId: selectedRuleset,
    };
    setShowDropdown(false);
    dispatch(addPropertyPriorityList({ propertyPriorityListObject }));
  };

  return (
    <>
      {!showDropdown && (
        <Button size="small" onClick={handleAddPropertyPriorityList}>
          Add priority list
        </Button>
      )}
      {showDropdown && (
        <SelectRulesetWrapper>
          <StyledSearchableDropdown
            options={rulesetsOptions}
            name={"rulesetDropdown"}
            value={selectedRuleset}
            placeholder="select ruleset"
            search={false}
            handleChange={handleSelectRuleset}
          />
          <Button size="small" disabled={!ruleSets} onClick={handleSubmit}>
            create priority list
          </Button>
          <StyledClose onClick={handlePressClose} />
        </SelectRulesetWrapper>
      )}
    </>
  );
}

const StyledSearchableDropdown = styled(SearchableDropdown)`
  width: 200px;
  margin-right: 16px;
  > div > div > input {
    background-color: ${colors.tables.columnsAndRowsTitles} !important;
    box-shadow: none !important;
    font-size: 12px !important;
    height: 32px !important;
    border: 1px solid ${colors.tables.borders} !important;
  }
`;

const SelectRulesetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledClose = styled(Close)<any>`
  margin-left: 16px;
  cursor: pointer;
`;
