import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, SearchableDropdown } from "components";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "store";
import { User } from "components/Avatar/User.component";
import { getUserList } from "./UserList";
import { Minus } from "assets/logos/icons/MediumIcons";
import colors from "theme/colors";
import {
  addUsersToProductLineAction,
  removeUserFromProductLineAction,
  setProductLineUsers,
} from "store/reducers/products/users/pl-users.actions";

export default function AddUserForm({ closeMenu }: { closeMenu: any }) {
  const dispatch = useDispatch();
  const { users: carrierUsers } = useTypedSelector((state) => state.users);
  const { selectedProductLine, users: userState } = useTypedSelector(
    (state) => state.products
  );

  const { users: currentUsers } = userState;

  const currentUsersIds: string[] =
    currentUsers?.map((user): string => user.id || "") || [];

  // allUsers are the current users of the product line + the new users that are not being added yet
  const [allUsers, setAllUsers] = useState<string[]>([]);
  const [defaultUsers, setDefaultUsers] = useState<string[]>([]);

  useEffect(() => {
    const newAllUsers = getUserList(carrierUsers, currentUsers).map(
      (user) => user.userID
    );
    setAllUsers(newAllUsers);
    setDefaultUsers(newAllUsers);
  }, [carrierUsers, currentUsers]);

  if (!carrierUsers?.byId) return <></>;

  const formattedCarrierUsers =
    carrierUsers &&
    Object.keys(carrierUsers?.byId).map((id) => {
      return {
        ...carrierUsers.byId[id],
        value: carrierUsers.byId[id].userID,
      };
    });

  const handleSubmitNewUsers = () => {
    if (!carrierUsers || !selectedProductLine) return;

    // add only users that are not already in the list
    allUsers.forEach((id: string) => {
      if (currentUsersIds.includes(id)) return;
      dispatch(addUsersToProductLineAction(carrierUsers.byId[id]));
    });

    // remove users that are not part of the list anymore
    currentUsersIds.forEach((id: string) => {
      if (allUsers.includes(id)) return;
      dispatch(removeUserFromProductLineAction(id));
    });

    dispatch(
      setProductLineUsers(
        allUsers.map((userId) => {
          const userState: any = currentUsers.find((user) => user.id == userId);
          const userRole = userState?.role ?? "VIEWER";
          return {
            id: userId,
            role: userRole,
          };
        })
      )
    );
    closeMenu();
  };

  const handleDelete = (userID) => {
    const newUsers = allUsers.filter((id) => userID !== id);

    setAllUsers(newUsers);
    setDefaultUsers(newUsers);
  };

  const handleDropdownChange = (userIDs) => {
    setAllUsers(userIDs);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <StyledInputWrapper>
        <StyledSearchableDropdown
          placeholder="Search by individual name"
          name={"searchCarrierUser"}
          options={formattedCarrierUsers}
          noShadow
          handleChange={handleDropdownChange}
          noHoverBorder
          multiple={true}
          value={defaultUsers}
        />

        <StyledUsersActionButton
          onClick={handleSubmitNewUsers}
          buttonType="submit"
          size="normal"
        >
          Update Users
        </StyledUsersActionButton>
        <UserList>
          {carrierUsers &&
            allUsers.map((userID) => {
              const user = carrierUsers.byId[userID];
              if (!user) return;
              const { firstName, lastName } = user;

              return (
                <UserLine key={userID}>
                  <StyledUser
                    role={"-"}
                    firstName={firstName}
                    lastName={lastName}
                    textWrapperWidth="200px"
                  />
                  <DeleteAction onClick={() => handleDelete(userID)}>
                    <Minus color={colors.error} />
                  </DeleteAction>
                </UserLine>
              );
            })}
        </UserList>
      </StyledInputWrapper>
    </form>
  );
}

const StyledUser = styled(User)`
  margin-top: 8px;
  margin-bottom: 8px;
  width: 85%;
`;

const DeleteAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 45px;
  border: 1px solid ${(props) => props.theme.colors.error};
  height: 15px;
  width: 15px;
  padding: 8px;
  margin-top: 8px;
  margin-left: 16px;

  :hover {
    opacity: 0.7;
  }
`;

const UserList = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.tables.borders};
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
  padding-top: 16px;
`;

const StyledInputWrapper = styled.div<any>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 30px;
  padding-left: 32px;
  padding-right: 48px;
`;

const StyledUsersActionButton = styled(Button)`
  margin-top: 40px;
  color: white;

  /* for smooth transition */
  white-space: nowrap;
`;

const StyledSearchableDropdown = styled(SearchableDropdown)<any>`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  padding: 15px;
  border-radius: 4px;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserLine = styled.div`
  display: flex;
`;
