import React, { useState } from "react";
import { HoveredInputCell } from "components";
import { Property } from "store/reducers/products/properties/pl-properties.types";
import { useDispatch } from "react-redux";
import { editPropertyAction } from "store/reducers/products/properties/pl-properties.actions";
import {
  editSubPropertyAction,
  getSubPropertiesAction,
} from "store/reducers/products/subproperties/pl-subproperties.actions";
import styled from "styled-components";
import { useParentPropertyContext } from "../../pages/ProductDetail/ProductLineProperties/ParentPropertyContext";
import { PROPERTY_NAME_REGEX } from "../../pages/ProductDetail/helpers";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

export default function NameCell({
  propertyData,
  id,
  type,
}: {
  propertyData: Property;
  id?: string;
  type: string;
}) {
  const {
    parentId,
    parentPropertyName,
    productLineId,
  } = useParentPropertyContext();
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [pendingName, setPendingName] = useState("");

  const mainPropertyNameFormatted = parentPropertyName.concat(".");
  const mainPRName = propertyData.name.split(mainPropertyNameFormatted);
  const name = type === "main" ? propertyData.name : mainPRName[1];

  const handleOpenDialog = (val: string) => {
    const newName =
      type === "main" ? val : mainPropertyNameFormatted.concat(val);

    // Open dialog only if the new name is different
    if (newName !== propertyData.name) {
      setPendingName(newName);
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = (confirm: boolean) => {
    if (confirm) {
      const newProperty = { ...propertyData, name: pendingName };

      if (type === "main") {
        dispatch(
          editPropertyAction({
            propertyId: propertyData.id,
            property: newProperty,
            updatePayload: { name: pendingName },
          })
        );

        setTimeout(
          () =>
            dispatch(
              getSubPropertiesAction({
                productLineId: productLineId,
                PLPropertyId: propertyData.id,
              })
            ),
          1500
        );
      } else if (type === "subproperties") {
        dispatch(
          editSubPropertyAction({
            propertyId: propertyData.id,
            updatePayload: { name: pendingName },
            parentId: parentId || "",
          })
        );
      }
    }
    setDialogOpen(false);
  };

  return (
    <>
      <StyledNameRow>
        {type === "subproperties" && (
          <StyledTitle>{mainPropertyNameFormatted}</StyledTitle>
        )}
        {type === "standardSubProperty" && <StyledTitle>{name}</StyledTitle>}
        {(type === "main" || type == "subproperties") && (
          <HoveredInputCell
            initialContent={name}
            handleSubmit={handleOpenDialog}
            rows={1}
            id={id}
            validationRegex={PROPERTY_NAME_REGEX}
          />
        )}
      </StyledNameRow>

      <Dialog open={dialogOpen} onClose={() => handleCloseDialog(false)}>
        <DialogTitle>Confirm Property Name Change</DialogTitle>
        <DialogContent>
          Are you sure you want to change the property name to{" "}
          <strong>{pendingName}</strong>?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog(false)} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => handleCloseDialog(true)}
            variant="contained"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const StyledNameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTitle = styled.div`
  margin-top: 3px;
`;
