import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../../../../theme/colors";
import placeholderImg from "assets/mockImages/placeholder-image.png";

export interface ProductLineCard {
  productLineName: string;
  displayImage?: string;
  onClick?: any;
  className?: any;
  productLineId: string;
  img?: string;
}

export function ProductLineCard({
  productLineName = "",
  displayImage,
  onClick,
  className,
}: ProductLineCard) {
  const [img, setImg] = useState(placeholderImg);

  useEffect(() => {
    if (!displayImage) return;
    setImg(displayImage);
  }, [displayImage]);

  return (
    <Wrapper className={className} onClick={onClick}>
      <DisplayImage
        src={img}
        onError={() => setImg(placeholderImg)}
        placeholder={placeholderImg}
      />
      <TitleWrapper>
        <TitleText>{productLineName.toUpperCase()}</TitleText>
      </TitleWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 180px;
  height: 180px;
  background-color: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.borders.width} solid
    ${(props) => props.theme.borders.primaryColor};
  border-radius: ${(props) => props.theme.borders.radius};
  cursor: pointer;

  :hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  }

  transition: 0.2s;
`;

const TitleWrapper = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  position: absolute;
  bottom: -18px;
  left: 50%;
  max-width: 100%;
  min-width: 75%;
  transform: translate(-50%);
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary};
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
`;

const TitleText = styled.span`
  box-sizing: border-box;
  min-width: 95px;
  max-width: 200px;
  padding: 10px 15px;
  text-align: center;
  color: ${colors.white};
`;

const DisplayImage = styled.img`
  width: 160px;
  height: auto;
`;

export default ProductLineCard;
