import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { Plus, Minus } from "assets/logos/icons/MediumIcons";
import { H4 } from "components";

export function ExpandableSection({
  title,
  children,
  length,
  onExpand,
  onHide,
  closeSection,
  changeSectionsStatus,
  defaultIsExpanded = false,
}: {
  title: string;
  children?: any;
  length?: number;
  onExpand?: any;
  onHide?: any;
  closeSection?: any;
  changeSectionsStatus?: any;
  defaultIsExpanded?: boolean;
}) {
  const [isSectionExpanded, setSectionExpansion] = useState(defaultIsExpanded);
  const setExpansion = () => {
    if (onExpand && !isSectionExpanded) onExpand();
    if (onHide && isSectionExpanded) onHide();
    if (!isSectionExpanded && changeSectionsStatus) changeSectionsStatus(true);
    setSectionExpansion((prevState) => !prevState);
  };

  useEffect(() => {
    if (!closeSection) setSectionExpansion(false);
  }, [closeSection]);

  // force open when default is set on mounting
  useEffect(() => {
    if (defaultIsExpanded) setTimeout(() => setSectionExpansion(true), 200);
  }, []);

  return (
    <Box>
      <Row onClick={setExpansion}>
        <TitleBox>
          <SectionName
            bold
            color={isSectionExpanded ? colors.primary : colors.muttedHeadlines}
          >
            {title}
          </SectionName>
          {length && (
            <CountBox>
              <Count>{length}</Count>
            </CountBox>
          )}
        </TitleBox>
        {!isSectionExpanded && (
          <Icon>
            <Plus color={colors.muttedHeadlines} />
          </Icon>
        )}
        {isSectionExpanded && (
          <Icon>
            <Minus color={colors.primary} />
          </Icon>
        )}
      </Row>
      {isSectionExpanded && <ExpandedSection>{children}</ExpandedSection>}
    </Box>
  );
}

const Box = styled.div`
  max-width: 100%;
  margin-top: 48px;
  padding-right: -25px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 23px;
  border-bottom: 1px solid ${colors.tables.borders};
  cursor: pointer;
`;

const SectionName = styled(H4)`
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

const ExpandedSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Icon = styled.div``;

const CountBox = styled.div`
  height: 24px;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.background.primaryLabels};
  border-radius: 4px;
  margin-left: 16px;
`;

const Count = styled.p`
  font-size: 12px;
  font-weight: 700;
  color: ${colors.secondary};
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
