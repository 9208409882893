import React from "react";
import colors from "theme/colors";
import styled from "styled-components";

export function ArrowLeft({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79289 0.792893C5.18342 0.402369 5.81658 0.402369 6.20711 0.792893C6.56759 1.15338 6.59532 1.72061 6.2903 2.1129L6.20711 2.20711L2.415 6L6.20711 9.79289C6.56759 10.1534 6.59532 10.7206 6.2903 11.1129L6.20711 11.2071C5.84662 11.5676 5.27939 11.5953 4.8871 11.2903L4.79289 11.2071L0.292893 6.70711C-0.0675905 6.34662 -0.0953204 5.77939 0.209704 5.3871L0.292893 5.29289L4.79289 0.792893Z"
        fill={color}
      />
    </svg>
  );
}

export function ArrowRight({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70711 0.792893C1.31658 0.402369 0.683418 0.402369 0.292893 0.792893C-0.0675907 1.15338 -0.0953207 1.72061 0.209704 2.1129L0.292893 2.20711L4.085 6L0.292893 9.79289C-0.0675907 10.1534 -0.0953207 10.7206 0.209704 11.1129L0.292893 11.2071C0.653378 11.5676 1.22061 11.5953 1.6129 11.2903L1.70711 11.2071L6.20711 6.70711C6.56759 6.34662 6.59532 5.77939 6.2903 5.3871L6.20711 5.29289L1.70711 0.792893Z"
        fill={color}
      />
    </svg>
  );
}

export function ArrowUp({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.04289 5.54289C0.652369 5.93342 0.652369 6.56658 1.04289 6.95711C1.40338 7.31759 1.97061 7.34532 2.3629 7.0403L2.45711 6.95711L6.25 3.165L10.0429 6.95711C10.4034 7.31759 10.9706 7.34532 11.3629 7.0403L11.4571 6.95711C11.8176 6.59662 11.8453 6.02939 11.5403 5.6371L11.4571 5.54289L6.95711 1.04289C6.59662 0.68241 6.02939 0.65468 5.6371 0.959705L5.54289 1.04289L1.04289 5.54289Z"
        fill={color}
      />
    </svg>
  );
}

export function ArrowDown({
  color = colors.secondary,
  className,
}: {
  color?: string;
  className?: any;
}) {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4571 2.45711C11.8476 2.06658 11.8476 1.43342 11.4571 1.04289C11.0966 0.682409 10.5294 0.654679 10.1371 0.959704L10.0429 1.04289L6.25 4.835L2.45711 1.04289C2.09662 0.682409 1.52939 0.654679 1.1371 0.959704L1.04289 1.04289C0.682408 1.40338 0.65468 1.97061 0.959705 2.3629L1.04289 2.45711L5.54289 6.95711C5.90338 7.31759 6.47061 7.34532 6.8629 7.0403L6.95711 6.95711L11.4571 2.45711Z"
        fill={color}
      />
    </svg>
  );
}

export function Add({
  color = colors.secondary,
  className,
  onClick,
}: {
  color?: string;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0V5H12V7H7V12H5V7H0V5H5V0H7Z"
        fill={color}
      />
    </svg>
  );
}

export function Calendar({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2H10V0H8V2H4V0H2V2H1C0.447715 2 0 2.44772 0 3V11C0 11.5523 0.447715 12 1 12H11C11.5523 12 12 11.5523 12 11V3C12 2.44772 11.5523 2 11 2ZM10 10H2V5H10V10Z"
        fill={color}
      />
    </svg>
  );
}

export function Clock({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM9 7H5V3H6.6V5.4H9V7Z"
        fill={color}
      />
    </svg>
  );
}

export function Delete({
  color = colors.secondary,
  className,
  onClick,
}: {
  color?: string;
  className?: any;
  onClick?: any;
}) {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6V10H7V6H9V11C9 11.5523 8.55228 12 8 12H2C1.44772 12 1 11.5523 1 11V6H3ZM7 0V2H10V4H0V2H3V0H7Z"
        fill={color}
      />
    </svg>
  );
}

export function Title({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5V12H1C0.447715 12 0 11.5523 0 11V5H3ZM12 5V11C12 11.5523 11.5523 12 11 12H5V5H12ZM11 0C11.5523 0 12 0.447715 12 1V3H0V1C0 0.447715 0.447715 0 1 0H11Z"
        fill={color}
      />
    </svg>
  );
}

export function UpArrow({ className }: { className: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0L0 7H4V12H8V7H12L6 0Z"
      />
    </svg>
  );
}

export const UpArrowIcon = styled(UpArrow)<any>`
  path {
    fill: ${(props) => props.theme.colors.secondary};
  }
`;

export function DownArrow({ className }: { className?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12L12 5L8 5L8 0L4 0L4 5L0 5L6 12Z"
      />
    </svg>
  );
}

export const DownArrowIcon = styled(DownArrow)<any>`
  path {
    fill: ${(props) => props.theme.colors.secondary};
  }
`;

export function UpLeft({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-2.62268e-07 6L7 12L7 8L12 8L12 4L7 4L7 -3.0598e-07L-2.62268e-07 6Z"
        fill={color}
      />
    </svg>
  );
}

export function DownRight({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6L5 8.34742e-08L5 4L4.76995e-08 4L9.5399e-08 8L5 8L5 12L12 6Z"
        fill={color}
      />
    </svg>
  );
}

export function File({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.707 3.293L6.707 0.293C6.51951 0.105451 6.26519 0 6 0H1C0.447715 0 0 0.447715 0 1V11C0 11.5523 0.447715 12 1 12H9C9.55228 12 10 11.5523 10 11V4C10 3.73481 9.89455 3.48049 9.707 3.293ZM2 10V2H5V5H8V10H2Z"
        fill={color}
      />
    </svg>
  );
}

export function Link({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.46296 4.71C2.75315 4.4193 3.22319 4.4161 3.51731 4.70282C3.81143 4.98955 3.82019 5.45951 3.53696 5.757L2.05596 7.276C1.57284 7.74663 1.37971 8.44048 1.5502 9.09303C1.72069 9.74559 2.22852 10.2563 2.8801 10.4305C3.53169 10.6047 4.22661 10.4155 4.69996 9.935L6.22696 8.446C6.52464 8.16251 6.99506 8.17146 7.28173 8.46609C7.5684 8.76071 7.56449 9.23119 7.27296 9.521L5.75496 11C4.43035 12.2851 2.31916 12.2679 1.01567 10.9614C-0.287814 9.65487 -0.300158 7.54365 0.987964 6.222L2.46296 4.71ZM7.46996 3.453C7.65836 3.25813 7.93725 3.18007 8.19944 3.24883C8.46163 3.31759 8.66632 3.52247 8.73483 3.78472C8.80334 4.04697 8.72502 4.32579 8.52996 4.514L4.52996 8.514C4.3896 8.65495 4.19888 8.73417 3.99996 8.73417C3.80105 8.73417 3.61033 8.65495 3.46996 8.514C3.32921 8.37334 3.25012 8.1825 3.25012 7.9835C3.25012 7.78451 3.32921 7.59367 3.46996 7.453L7.46996 3.453ZM6.24496 0.965004C7.58279 -0.301235 9.67787 -0.298159 11.012 0.972004C12.3267 2.29139 12.3267 4.42561 11.012 5.745L9.53696 7.257C9.35075 7.4526 9.07375 7.53298 8.81176 7.46744C8.54977 7.4019 8.34325 7.20057 8.27106 6.94033C8.19887 6.68009 8.27217 6.40114 8.46297 6.21L9.94396 4.691C10.4363 4.22236 10.6364 3.52399 10.4671 2.86572C10.2977 2.20746 9.78534 1.6924 9.12798 1.51956C8.47062 1.34672 7.7712 1.54317 7.29996 2.033L5.77296 3.521C5.47529 3.8045 5.00487 3.79554 4.7182 3.50092C4.43153 3.2063 4.43544 2.73582 4.72696 2.446L6.24496 0.965004Z"
        fill={color}
      />
    </svg>
  );
}

export function CardView({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6V10H6V6H10ZM4 6V10H0V6H4ZM4 0V4H0V0H4ZM10 0V4H6V0H10Z"
        fill={color}
      />
    </svg>
  );
}

export function Lock({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C7.93142 0 9.50759 1.56572 9.59608 3.53143L9.6 3.70588V4.94118H10.4C11.2433 4.94118 11.9345 5.61329 11.9956 6.46534L12 6.58824V12.3529C12 13.2624 11.2834 14 10.4 14H1.6C0.716572 14 0 13.2624 0 12.3529V6.58824C0 5.67882 0.716572 4.94118 1.6 4.94118H2.4V3.70588C2.4 1.65918 4.01177 0 6 0ZM10.4 6.58824H1.6V12.3529H10.4V6.58824ZM6 1.64706C4.93961 1.64706 4.07197 2.49656 4.00425 3.57051L4 3.70588V4.94118H8V3.70588C8 2.56882 7.10457 1.64706 6 1.64706Z"
        fill={color}
      />
    </svg>
  );
}

export function Close({
  color = colors.secondary,
  onClick,
  className,
}: {
  color?: string;
  onClick?: () => void;
  className?: any;
}) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.48883 0.193573L1.57579 0.270363L6 4.69385L10.4242 0.270363C10.7847 -0.0901208 11.3692 -0.0901208 11.7296 0.270363C12.0624 0.603118 12.088 1.12672 11.8064 1.48883L11.7296 1.57579L7.30615 6L11.7296 10.4242C12.0901 10.7847 12.0901 11.3692 11.7296 11.7296C11.3969 12.0624 10.8733 12.088 10.5112 11.8064L10.4242 11.7296L6 7.30615L1.57579 11.7296C1.21531 12.0901 0.630847 12.0901 0.270363 11.7296C-0.0623913 11.3969 -0.0879881 10.8733 0.193573 10.5112L0.270363 10.4242L4.69385 6L0.270363 1.57579C-0.0901208 1.21531 -0.0901208 0.630847 0.270363 0.270363C0.603118 -0.0623913 1.12672 -0.0879881 1.48883 0.193573Z"
        fill={color}
      />
    </svg>
  );
}

export function DropdownArrow({
  color = colors.secondary,
}: {
  color?: string;
}) {
  return (
    <svg
      width="7"
      height="4"
      viewBox="0 0 7 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 4L7 0H0L3.5 4Z"
        fill={color}
      />
    </svg>
  );
}

export function Error({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C2.6915 0 0 2.6915 0 6C0 9.3085 2.6915 12 6 12C9.3085 12 12 9.3085 12 6C12 2.6915 9.3085 0 6 0ZM6.8225 2.5L6.5 7H5.5L5.196 2.5H6.8225ZM6 10C5.4475 10 5 9.5525 5 9C5 8.4475 5.4475 8 6 8C6.5525 8 7 8.4475 7 9C7 9.5525 6.5525 10 6 10Z"
        fill={color}
      />
    </svg>
  );
}

export function ListView({
  color = colors.secondary,
  className,
  onClick,
}: {
  color?: string;
  className?: any;
  onClick?: () => void;
}) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8V10H0V8H10ZM10 4V6H0V4H10ZM10 0V2H0V0H10Z"
        fill={color}
      />
    </svg>
  );
}

export function Duplicate({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4C9.6 4 10 4.4 10 5V15C10 15.6 9.6 16 9 16H1C0.4 16 0 15.6 0 15V5C0 4.4 0.4 4 1 4H9ZM13 0C13.6 0 14 0.4 14 1V13H12V2H3V0H13Z"
        fill={color}
      />
    </svg>
  );
}

export function Edit({
  color = colors.secondary,
  className,
  onClick,
}: {
  color?: string;
  className?: any;
  onClick?: any;
}) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1.00001V3.00001H2V10H9V8.00001H11V11C11 11.5523 10.5523 12 10 12H1C0.447715 12 0 11.5523 0 11V2.00001C0 1.44772 0.447715 1.00001 1 1.00001H4ZM10.584 -0.000366211C10.958 -0.000366211 11.3167 0.148348 11.581 0.413007C11.8517 0.683302 12.0004 1.04198 12.0004 1.41601C12.0004 1.79003 11.8517 2.14871 11.587 2.41301L7 7.00001L4 8.00001L5 5.00001L9.587 0.413007C9.85129 0.148348 10.21 -0.000366211 10.584 -0.000366211Z"
        fill={color}
      />
    </svg>
  );
}

export function Exception({ color = colors.secondary }: { color?: string }) {
  return (
    <svg
      width="4"
      height="13"
      viewBox="0 0 4 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.159 0L2.6 7.8H0.866666L0.339733 0H3.159ZM1.73333 13C0.775666 13 0 12.2243 0 11.2667C0 10.309 0.775666 9.53333 1.73333 9.53333C2.691 9.53333 3.46667 10.309 3.46667 11.2667C3.46667 12.2243 2.691 13 1.73333 13Z"
        fill={color}
      />
    </svg>
  );
}
