import React from "react";
import styled from "styled-components";
import AsyncSelect from "react-select/async";

export default function AsyncSelectDropdown({
  placeholder,
  multiselect,
  width = 200,
  onChange,
  loadOptions,
  className,
  disabled = false,
  size = "large",
}: {
  placeholder: string;
  multiselect: boolean;
  width?: number;
  onChange: (e: any) => void;
  label?: string;
  loadOptions: any;
  className?: string;
  disabled?: boolean;
  size?: "large" | "small";
}) {
  const handleChange = (options) => {
    if (multiselect) {
      onChange(options.map((option) => option.value));
      return;
    }
    return onChange(options.value);
  };

  return (
    <Wrapper className={className}>
      <StyledAsyncSelect
        classNamePrefix={"Select"}
        cacheOptions
        loadOptions={loadOptions}
        onChange={handleChange}
        defaultOptions
        isMulti={multiselect}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        isDisabled={disabled}
        width={width}
        size={size}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>``;

const StyledAsyncSelect = styled(AsyncSelect)<any>`
  & .Select__control {
    width: ${(props) => `${props.width}px`};
  }

  & .Select__input {
    font-size: ${(props) => (props.size === "small" ? "12px" : "14px")};
  }

  & .Select__placeholder {
    font-size: ${(props) => (props.size === "small" ? "12px" : "14px")};
  }
`;
