import { H4, H3, NormalP, Button, H6, Dropdown } from "components";
import { User } from "components/Avatar/User.component";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { useDispatch } from "react-redux";
import { selectTaskById } from "store/reducers/programs/programs.selectors";
import { mappedStatusToElement } from "store/reducers/programs/programs.mocks";
import { useTypedSelector } from "store";
import { editTaskAction } from "store/reducers/programs/programs.actions";
import {
  convertTimestampToDate,
  returnDifferenceBetweenDateAndCurrentDay,
} from "helpers/datesConverter";

export default function Task({
  id,
  close,
  openAddTask,
  setId,
}: {
  id: any;
  close: () => void;
  openAddTask: () => void;
  setId: (id: any) => void;
}) {
  const taskDetails = selectTaskById(
    useTypedSelector((state) => state.programs),
    id
  );
  const dispatch = useDispatch();
  const { users } = useTypedSelector((state) => state.users);
  const formattedUsers =
    users &&
    Object.keys(users.byId).map((id) => {
      return {
        ...users.byId[id],
        value: users.byId[id].userID,
      };
    });
  const changeStatus = (newStatus) => {
    dispatch(
      editTaskAction({
        id: id,
        name: taskDetails?.name || "",
        milestone: taskDetails?.milestone || "",
        dueDate: taskDetails?.dueDate || "",
        details: taskDetails?.details || "",
        taskOwners: taskDetails?.taskOwners || [],
        taskManagers: taskDetails?.taskManagers || [],
        status: newStatus,
      })
    );
  };

  const options = Object.keys(mappedStatusToElement).map((key) => {
    const elem = mappedStatusToElement[key];
    return {
      ...elem,
      value: key,
    };
  });

  const editTask = () => {
    setId(id);
    close();
    openAddTask();
  };

  const [status, setStatus] = useState();
  useEffect(() => {
    setStatus(taskDetails.status);
  });
  const handleChangeStatus = (newStatus) => {
    setStatus(newStatus.target.value);
    changeStatus(newStatus.target.value);
  };

  const displayMembers = (teamMembers, section = "") => {
    return teamMembers !== undefined && teamMembers.length > 0
      ? teamMembers?.map((memberId) => {
          const member =
            formattedUsers && formattedUsers.length > 0
              ? formattedUsers?.find((user) => user.userID === memberId)
              : {};
          if (section === "owners") {
            return (
              <CustomUserCard
                firstName={member?.firstName}
                lastName={member?.lastName}
                role={member?.role}
                size="large"
                key={memberId}
              />
            );
          } else {
            return (
              <SmallUserCard
                firstName={member?.firstName}
                lastName={member?.lastName}
                size="small"
                key={memberId}
              />
            );
          }
        })
      : null;
  };
  return (
    <Box>
      <TaskSection>
        <Title bold color={colors.muttedHeadlines}>
          Task
        </Title>
        <MainTitle color={colors.secondary}>{taskDetails.name}</MainTitle>
        <MilestoneBox>
          <StyledLabel>Milestone</StyledLabel>
          {taskDetails.milestone}
        </MilestoneBox>
        <Title bold color={colors.muttedHeadlines}>
          Detail
        </Title>
        <CustomParagraph>{taskDetails.details}</CustomParagraph>
        <ButtonsRow>
          <CustomButton type="secondary" color="primary">
            Go to component
          </CustomButton>
          <CustomButton type="primary" color="secondary">
            Submit for approval
          </CustomButton>
        </ButtonsRow>
      </TaskSection>
      <TeamMemberSection>
        <Title bold color={colors.muttedHeadlines} marginLeft="31px">
          Owner
        </Title>
        <TeamBox>{displayMembers(taskDetails.taskOwners, "owners")}</TeamBox>
        <DataRow>
          <Labels border background>
            <LabelTitle bold>due</LabelTitle>
          </Labels>
          <Labels border width="104px" background>
            <LabelTitle bold>days remaining</LabelTitle>
          </Labels>
          <Labels width="108px" background>
            <LabelTitle bold>status</LabelTitle>
          </Labels>
        </DataRow>
        <DataRow borderBottom marginBottom>
          <Labels border>
            <LabelTitle>
              {convertTimestampToDate(taskDetails.dueDate)}
            </LabelTitle>
          </Labels>
          <Labels border width="104px">
            <LabelTitle>
              {returnDifferenceBetweenDateAndCurrentDay(taskDetails.dueDate)}
            </LabelTitle>
          </Labels>
          <Labels width="108px">
            <CustomDropdown
              options={options}
              border={"0px"}
              onChange={handleChangeStatus}
              value={status}
              backgroundColor={
                mappedStatusToElement[status ? status : taskDetails.status]
                  .color
              }
            />
          </Labels>
        </DataRow>
        <Title bold color={colors.muttedHeadlines} marginLeft="31px">
          Manager Approval
        </Title>
        <TeamBox>{displayMembers(taskDetails.taskManagers)}</TeamBox>
        <ButtonsRow marginLeft="31px">
          <SmallButton type="primary" color="primary">
            Approve
          </SmallButton>
          <SmallButton type="secondary" color="primary" onClick={editTask}>
            Edit Task
          </SmallButton>
        </ButtonsRow>
      </TeamMemberSection>
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: -49px;
`;

const TaskSection = styled.div`
  width: 464px;
  display: flex;
  flex-direction: column;
  margin-right: 74px;
  justify-content: flex-start;
  aling-items: flex-start;
`;

const TeamMemberSection = styled.div`
  width: 315px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${colors.tables.borders};
  height: 100%;
  justify-content: center;
`;

const Title = styled(H4)<any>`
  margin-block-start: 0px;
  margin-block-end: 8px;
  text-align: left;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
`;

const MainTitle = styled(H3)`
  margin-block-start: 0px;
  margin-block-end: 24px;
  text-align: left;
`;

const CustomParagraph = styled(NormalP)`
  color: ${colors.muttedHeadlines};
  line-height: 28px;
  max-height: 140px;
  overflow-y: scroll;
`;

const CustomButton = styled(Button)`
  width: 219px;
  margin-right: 20px;
  text-transform: uppercase;
  padding: 16px 39px 16px 39px;
`;

const ButtonsRow = styled.div<any>`
  display: flex;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  margin-top: 24px;
`;

const CustomUserCard = styled(User)`
  justify-content: flex-start;
  margin-bottom: 31px !important;
  margin-left: 31px !important;
`;

const SmallUserCard = styled(User)`
  justify-content: flex-start;
  margin-bottom: 16px !important;
  margin-left: 31px !important;
`;

const SmallButton = styled(Button)`
  width: 88px;
  text-transform: uppercase;
  margin-right: 10px;
  height: 32px;
  padding: 8px 10px 8px 10px;
`;

const DataRow = styled.div<any>`
  display: flex;
  border-top: 1px solid ${colors.tables.borders};
  border-bottom: ${(props) => (props.borderBottom ? "1px" : "0px")} solid
    ${colors.tables.borders};
  margin-bottom: ${(props) => (props.marginBottom ? "24px" : "0px")};
`;

const Labels = styled.div<any>`
  width: ${(props) => (props.width ? props.width : "103px")};
  height: 56px;
  align-items: center;
  border-right: ${(props) => (props.border ? "1px" : "0px")} solid
    ${colors.tables.borders};
  background-color: ${(props) =>
    props.background ? colors.tables.columnsAndRowsTitles : colors.white};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LabelTitle = styled(H6)`
  text-transform: uppercase;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

const MilestoneBox = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 72px;
  width: 464px;
  max-width: 100%;
  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  border-radius: 4px;
  padding: 15px 24px 11px 24px;
  box-sizing: border-box;
  background-color: white;
  font-size: 16px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.primary};
`;

const StyledLabel = styled.span<any>`
  color: ${(props) => props.theme.colors.secondary};
  font-size: 12px;
  margin-bottom: 4px;
`;

const TeamBox = styled.div`
  max-height: 110px;
  width: 100%;
  overflow-y: scroll;
`;

const CustomDropdown = styled<any>(Dropdown)`
  height: 56px;
  width: 108px;
  & > select {
    background-color: ${(props) => props.backgroundColor};
    height: 56px;
    border: none;
    border-radius: 0px;
    padding: 20px;
    color: ${(props) => props.theme.colors.white};
    font-size: 12px;
    font-weight: 700;
  }
  & > svg {
    top: 26px;
    & path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;
