import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import styled from "styled-components";

import colors from "theme/colors";
import { Button, H4 } from "components";
import { useTypedSelector } from "store";
import OverlayRightPanel from "containers/OverlayRightPanel/OverlayRightPanel.container";
import {
  getProductsAction,
  setAddProductPanelOpen,
  setImportProductPanelOpen,
} from "store/reducers/products/products.actions";
import ProductLinesListControl from "../../components/ProductLinesListControl.component";
import { useDispatch } from "react-redux";
import { AddProductForm } from "./AddProductForm";
import { ImportProductForm } from "./ImportProductForm";
import SearchBarProductLines from "./SearchProductLines";
import ProductsGridView from "./ProductsGridView";
import ProductLinePagination from "./ProductLinePagination";
import { ProductRegion } from "../../../../store/reducers/products/products.types";
import ImportProductLineRulesEngineJSONButton from "./ImportProductLineRulesEngineJSONButton.component";

export default function ProductsList() {
  // local state
  const [searchTermProductLine, setSearchTermProductLine] = useState("");
  const [searchRegions, setSearchRegions] = useState<ProductRegion>();
  const [searchPage, setSearchPage] = useState<number>(0);
  const handleChange = (e) => {
    setSearchTermProductLine(e.target.value);
  };

  // hooks
  const dispatch = useDispatch();

  // selectors
  const { navigationOpen } = useTypedSelector(
    (state) => state.user.preferences
  );
  const {
    showAddProductLineSidePanel,
    showImportProductLineSidePanel,
    metadata,
    getting,
  } = useTypedSelector((state) => state.products);

  // handlers
  const handleAddProductLinePress = () => {
    dispatch(setAddProductPanelOpen(!showAddProductLineSidePanel));
  };

  const handleImportProductLinePress = () => {
    dispatch(setImportProductPanelOpen(!showImportProductLineSidePanel));
  };

  const handleGetProduct = (searchTerm, regions, page = 0) =>
    dispatch(
      getProductsAction({
        items: "10",
        name: searchTerm,
        page,
        regions: regions,
        sortBy: "name",
      })
    );

  // debounce
  const debouncedGetProducts = useCallback(
    debounce(
      (searchTerm, regions, page) =>
        handleGetProduct(searchTerm, regions, page),
      500,
      {
        leading: false,
        trailing: true,
      }
    ),
    []
  );

  useEffect(() => {
    debouncedGetProducts(searchTermProductLine, searchRegions, searchPage);
  }, [searchPage]);

  // when a change in search term or region happens, reset the page to initial value
  useEffect(() => {
    setSearchPage(0);
    debouncedGetProducts(searchTermProductLine, searchRegions, 0);
  }, [searchTermProductLine, searchRegions]);

  const doNotDisplayLoader = searchTermProductLine?.length > 0;

  return (
    <Wrapper navigationOpen={navigationOpen}>
      <OverlayRightPanel
        // if posting (adding a product line), let the side panel open until it's done
        open={showAddProductLineSidePanel}
        closeNav={() => dispatch(setAddProductPanelOpen(false))}
        title="New Product Line"
      >
        <AddProductForm />
      </OverlayRightPanel>
      <OverlayRightPanel
        open={showImportProductLineSidePanel}
        closeNav={() => dispatch(setImportProductPanelOpen(false))}
        title="Import Product Line"
      >
        <ImportProductForm
          handleRefreshProductlines={debouncedGetProducts}
          visible={showImportProductLineSidePanel}
        />
      </OverlayRightPanel>
      <SearchBarProductLines
        searchTermProductLine={searchTermProductLine}
        handleChange={handleChange}
      />
      <HeadlineWrapper>
        <StyledH4 color={colors.primary} bold>
          Product lines
        </StyledH4>
        <ProductLinesListControl setSearchRegions={setSearchRegions} />
        <StyledButton
          size="small"
          type="primary"
          onClick={handleAddProductLinePress}
        >
          Add new product line
        </StyledButton>
        <StyledButton
          size="small"
          type="secondary"
          onClick={handleImportProductLinePress}
        >
          Import product line from Database JSON
        </StyledButton>
        <ImportProductLineRulesEngineJSONButton
          handleRefreshProductlines={debouncedGetProducts}
        />
      </HeadlineWrapper>
      <ProductLineListWrapper>
        <ProductsGridView doNotDisplayLoader={doNotDisplayLoader} />
      </ProductLineListWrapper>
      {!getting && (
        <ProductLinePagination
          metadata={metadata}
          setSearchPage={setSearchPage}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<any>`
  padding-left: 10px;
  padding-top: 40px;
  padding-right: 40px;
  min-width: 900px;

  overflow-y: auto;

  @media ${(props) => props.theme.device.mobile} {
    padding-left: ${(props) => (props.navigationOpen ? "50px" : "24px")};
  }
`;

const ProductLineListWrapper = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 200px;
`;

const HeadlineWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const StyledH4 = styled(H4)`
  opacity: 0.6;
  margin: 8px 0;
`;

//const StyledButton = styled(Button)``;

const StyledButton = styled(Button)`
  width: 200px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;
