export function convertTimestampToDate(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getUTCDate();
  const dueDate = day + "/" + month + "/" + year;
  return dueDate;
}

export function convertTimestampToDays(timestamp) {
  const currentDate = Date.now();
  const daysLeft = timestamp - currentDate;
  const seconds = Math.floor(daysLeft / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  return days;
}

export function convertTimestampToMonthAndDay(timestamp) {
  const date = new Date(timestamp);
  const day = date.getUTCDate();
  const month = date.toLocaleString("en-EN", { month: "short" });
  const finallFormat = month + " " + day;
  return finallFormat;
}

export function returnDifferenceBetweenDateAndCurrentDay(timestamp) {
  const currentDate = Date.now();
  const convertDiference =
    convertTimestampToDays(timestamp) - convertTimestampToDays(currentDate);
  return convertDiference;
}
