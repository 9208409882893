import React from "react";
import styled from "styled-components";
import { H2, H5, H6 } from "components/Typography/Headlines.component";
import { UpArrowIcon, DownArrowIcon } from "assets/logos/icons/MicroIcons";
import { convertTimestampToMonthAndDay } from "helpers/datesConverter";
export function CardTendencies({
  value,
  tendency,
  title,
  change,
  date,
}: {
  value?: number;
  tendency?: string;
  title?: string;
  change?: number;
  date?: number;
}) {
  return (
    <Box>
      <TendencyRow>
        {tendency === "growing" && (
          <>
            <CustomUpArrow /> <CustomH5 tendency={tendency}>{change}</CustomH5>
          </>
        )}
        {tendency === "falling" && (
          <>
            <CustomDownArrow />
            <CustomH5 tendency={tendency}>{change}</CustomH5>
          </>
        )}
      </TendencyRow>
      {value && <CustomH2>{value}</CustomH2>}
      {date && <DateTitle>{convertTimestampToMonthAndDay(date)}</DateTitle>}
      <CustomH6>{title}</CustomH6>
    </Box>
  );
}

const Box = styled.div`
  width: 150px;
  height: 104px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
  box-sizing: border-box;
`;

const TendencyRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomH2 = styled(H2)`
  margin: 0px;
  line-height: 48px;
  font-weight: 700;
`;

const CustomH6 = styled(H6)`
  margin: 0px;
`;

const CustomH5 = styled(H5)<any>`
  font-weight: 700;
  margin: 0px;
  margin-left: 6px;
  color: ${(props) =>
    props.tendency === "growing"
      ? props.theme.colors.projectComplete
      : props.theme.colors.projectLate};
`;

const CustomUpArrow = styled(UpArrowIcon)`
  path {
    fill: ${(props) => props.theme.colors.projectComplete};
  }
`;

const CustomDownArrow = styled(DownArrowIcon)`
  path {
    fill: ${(props) => props.theme.colors.projectLate};
  }
`;

const DateTitle = styled.p`
  font-size: 32px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.primary};
  line-height: 40px;
  margin: 0px;
`;
