import React, { useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";
import { AddImage } from "components/Forms/AddImage.component";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function generateBlob(canvas, crop, handleBlob = (blob) => blob) {
  if (!crop || !canvas) {
    return;
  }

  canvas.toBlob(
    (blob) => {
      handleBlob(blob);
    },
    "image/png",
    1
  );
}

export default function ImageCrop({
  aspect = 1 / 1,
  handleBlob,
}: {
  aspect?: number;
  handleBlob?: any;
}) {
  const [upImg, setUpImg] = useState<any>();
  const [imgName, setImgName] = useState("");
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect });
  const [completedCrop, setCompletedCrop] = useState<any>(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    const crop: any = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    // TODO: this may be an expensive calculation to do each time we re-size,
    // check here first if we have performance problems
    generateBlob(canvas, crop, handleBlob);
  }, [completedCrop]);

  const handleSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      if (!reader) return;
      setImgName(e.target.files[0].name);

      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <Wrapper>
      <StyledAddImage
        label="image"
        onSelectFile={handleSelectFile}
        imgName={imgName}
      />
      {imgName && (
        <>
          <br />
          <span style={{ marginTop: 32 }}>Crop and resize your image here</span>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            imageStyle={{ width: "100%" }}
          />
          <span style={{ marginTop: 32 }}>Preview</span>

          <div>
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
              }}
            />
          </div>
        </>
      )}
    </Wrapper>
  );
}

const StyledAddImage = styled(AddImage)`
  margin-top: 30px;
`;

const Wrapper = styled.div`
  height: 100%;
`;
