export function daysDeclination(daysNumber) {
  if (daysNumber === 1 || daysNumber === -1) {
    return "Day";
  } else {
    return "Days";
  }
}

export function tasksDeclination(tasksNumber) {
  if (tasksNumber === 1) {
    return "Task";
  } else {
    return "Tasks";
  }
}

export function membersDeclination(membersNumber) {
  if (membersNumber === 1) {
    return "Member";
  } else {
    return "Members";
  }
}
