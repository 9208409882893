import queryString from "query-string";
import { store } from "../store";
import JSZip from "jszip";

export function encodeParams(params: any = {}): string {
  try {
    // if property is falsy, delete it from object
    Object.keys(params).forEach((key) => params[key] || delete params[key]);

    return queryString.stringify(params);
  } catch (e) {
    return "";
  }
}

export function getFilenameFromContentDisposition(contentDisposition) {
  try {
    const result = contentDisposition.match(/filename="(.*)"/)[1];
    return result;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function getUserId() {
  return store.getState().user.id;
}

export function saveJsonToFile(jsonData: string, filename = "data.json") {
  try {
    const dataStr = JSON.stringify(jsonData);
    const dataUri =
      "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

    const linkElement = document.createElement("a");
    document.body.appendChild(linkElement); // Adding to DOM to ensure compatibility in some browsers
    linkElement.style.display = "none";
    linkElement.setAttribute("href", dataUri);
    linkElement.setAttribute("download", filename);
    linkElement.click();
    document.body.removeChild(linkElement); // Clean up
  } catch (error) {
    console.error("Failed to export JSON data: ", error);
  }
}

export function saveJsonToZipFile(jsonData, filename = "data.json") {
  const zip = new JSZip();
  const jsonStr = JSON.stringify(jsonData);
  zip.file(filename, jsonStr); // Add the JSON file to the zip

  zip
    .generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 9,
      },
      mimeType: "application/zip",
    })
    .then((content) => {
      // Generate the zip file and trigger the download
      const url = window.URL.createObjectURL(content);
      const linkElement = document.createElement("a");
      document.body.appendChild(linkElement); // Adding to DOM to ensure compatibility in some browsers
      linkElement.style.display = "none";
      linkElement.href = url;
      linkElement.download = filename.replace(".json", ".zip"); // Change the extension to .zip
      linkElement.click();
      document.body.removeChild(linkElement); // Clean up
      window.URL.revokeObjectURL(url); // Free up memory by releasing the blob URL
    })
    .catch((error) => {
      console.error("Failed to zip JSON data: ", error);
    });
}

export function exportToTSVFile(tsvData: string, filename = "data.tsv") {
  //const dataStr = JSON.stringify(tsvData);
  const dataUri = "data:text/tsv;charset=utf-8," + encodeURIComponent(tsvData);

  const linkElement = document.createElement("a");
  linkElement.setAttribute("href", dataUri);
  linkElement.setAttribute("download", filename);
  linkElement.click();
}
