import {
  SET_PRODUCTS,
  SET_NEW_PRODUCT,
  ADD_NEW_PRODUCT,
  IMPORT_NEW_PRODUCT,
  SET_VERB,
  CREATE_PRODUCT_PANEL_OPEN,
  IMPORT_PRODUCT_PANEL_OPEN,
  SET_SELECTED_PRODUCT_LINE,
  CLEAR_SELECTED_PRODUCT_LINE,
  SET_DEFAULT_RULESET,
  SET_PRODUCT_LINES_METADATA,
} from "./products.actions";
import { Product, NewProductDto } from "./products.types";
import { mockedProducts } from "./products.mocks";
import { plUsersReducer, plUsersInitialState } from "./users/pl-users.reducer";
import {
  plParametersReducer,
  plParametersInitialState,
} from "./parameters/pl-parameters.reducer";
import { UsersState } from "./users/pl-users.types";
import { ParametersState } from "./parameters/pl-parameters.types";

import {
  plPropertiesReducer,
  plPropertiesInitialState,
} from "./properties/pl-properties.reducer";
import {
  plSubPropertiesReducer,
  plSubPropertiesInitialState,
} from "./subproperties/pl-subproperties.reducer";
import { PropertiesState } from "./properties/pl-properties.types";
import { SubPropertiesState } from "./subproperties/pl-subproperties.types";

import {
  plRulesTablesReducer,
  plRulesTablesInitialState,
} from "./rulestables/pl-rulestables.reducer";
import { RulesTablesState } from "./rulestables/pl-rulestables.types";
import {
  candidateReducer,
  candidateInitialState,
} from "./candidate/candidate.reducer";
import { CandidateInitialState } from "./candidate/candidate.types";
import { metadataReducer } from "./metadata/metadata.reducer";
import { ValidationState } from "./validation/validation.types";
import {
  plValidationInitialState,
  plValidationReducer,
} from "./validation/pl-validation.reducer";
import {
  plPropertyPrioritiesInitialState,
  plPropertyPrioritiesReducer,
} from "./propertyPriorities/pl-propertyPriorities.reducer";
import { PropertyPrioritiesState } from "./propertyPriorities/pl-propertyPriorities.types";
import {
  plLogicGroupsInitialState,
  plLogicGroupsReducer,
} from "./logicGroups/logicGroups.reducers";
import { LogicGroupsState } from "./logicGroups/logicGroups.types";

export interface ProductReducerState {
  products: Product[];
  newProduct: Partial<NewProductDto>;
  selectedProductLine?: Product;

  // product line parameters
  propertyValueAttributes?: null;
  newPropertyValueAttribute?: any;

  // flattened selected product line properties
  users: UsersState;
  parameters: ParametersState;

  properties: PropertiesState;
  subproperties: SubPropertiesState;
  rulestables: RulesTablesState;
  candidate: CandidateInitialState;

  // logic groups
  logicGroups: LogicGroupsState;

  // validation
  validation: ValidationState;

  propertyPriorities: PropertyPrioritiesState;

  // metadata
  metadata: any;

  // rest verbs
  getting: boolean;
  posting: boolean;
  deleting: boolean;

  // others
  showAddProductLineSidePanel: boolean;
  showImportProductLineSidePanel: boolean;
}

const newProductInitialState: Partial<NewProductDto> = {
  name: "",
};

const initialState: ProductReducerState = {
  products: mockedProducts,
  newProduct: { ...newProductInitialState },
  selectedProductLine: undefined,

  // flattened selected product line properties
  users: plUsersInitialState,
  parameters: plParametersInitialState,
  properties: plPropertiesInitialState,
  subproperties: plSubPropertiesInitialState,
  rulestables: plRulesTablesInitialState,
  candidate: candidateInitialState,
  logicGroups: plLogicGroupsInitialState,
  validation: plValidationInitialState,
  propertyPriorities: plPropertyPrioritiesInitialState,

  // rest verbs
  getting: false,
  posting: false,
  deleting: false,

  // metadata
  metadata: {},

  // others
  showAddProductLineSidePanel: false,
  showImportProductLineSidePanel: false,
};

export default function productsReducer(
  state: ProductReducerState = initialState,
  action: any
): ProductReducerState {
  switch (action.type) {
    case SET_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }

    case SET_SELECTED_PRODUCT_LINE: {
      return {
        ...state,
        selectedProductLine: action.payload,
      };
    }

    case SET_NEW_PRODUCT: {
      return {
        ...state,
        newProduct: {
          ...state.newProduct,
          ...action.payload,
        },
      };
    }

    case ADD_NEW_PRODUCT: {
      return {
        ...state,
        products: action.payload,
        newProduct: { ...newProductInitialState },
      };
    }

    case IMPORT_NEW_PRODUCT: {
      return {
        ...state,
        products: [...state.products, action.payload],
        newProduct: action.payload,
      };
    }

    case SET_DEFAULT_RULESET: {
      if (!state.selectedProductLine) return state;
      return {
        ...state,
        selectedProductLine: {
          ...state.selectedProductLine,
        },
      };
    }

    case SET_VERB: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CREATE_PRODUCT_PANEL_OPEN: {
      return {
        ...state,
        showAddProductLineSidePanel: action.payload,
      };
    }

    case IMPORT_PRODUCT_PANEL_OPEN: {
      return {
        ...state,
        showImportProductLineSidePanel: action.payload,
      };
    }

    case CLEAR_SELECTED_PRODUCT_LINE: {
      return {
        ...state,
        selectedProductLine: undefined,
      };
    }

    case SET_PRODUCT_LINES_METADATA: {
      return {
        ...state,
        metadata: {
          ...action.payload,
        },
      };
    }

    default:
      return {
        ...state,
        users: plUsersReducer(state.users, action),
        parameters: plParametersReducer(state.parameters, action),
        properties: plPropertiesReducer(state.properties, action),
        subproperties: plSubPropertiesReducer(state.subproperties, action),
        rulestables: plRulesTablesReducer(state.rulestables, action),
        candidate: candidateReducer(state.candidate, action),
        logicGroups: plLogicGroupsReducer(state.logicGroups, action),
        metadata: metadataReducer(state.metadata, action),
        validation: plValidationReducer(state.validation, action),
        propertyPriorities: plPropertyPrioritiesReducer(
          state.propertyPriorities,
          action
        ),
      };
  }
}
