import { H5, H6 } from "components/Typography/Headlines.component";
import React from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { File } from "assets/logos/icons/MicroIcons";
import { convertTimestampToDate } from "helpers/datesConverter";

export default function DocumentBox({
  published,
  content,
}: {
  published?: any;
  content?: string;
}) {
  return (
    <Box>
      <PublishedTitle color={colors.muttedHeadlines}>
        Published: {convertTimestampToDate(published)}
      </PublishedTitle>
      <Content>{content}</Content>
      <Row>
        <DownloadButton>
          <File />
          <DownloadTitle bold>Download</DownloadTitle>
        </DownloadButton>
      </Row>
    </Box>
  );
}

const Box = styled.div`
  width: 348px;
  height: 244px;
  padding: 24px;
  display: flex;
  margin-right: 24px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid ${colors.tables.borders};
  background-color: ${colors.white};
  margin-top: 24px;
  border-radius: 4px;
  @media ${(props) => props.theme.device.mobile} {
    height: auto;
  }
`;

const PublishedTitle = styled(H6)`
  margin-block-start: 0px;
  margin-block-end: 8px;
  width: 100%;
`;

const Content = styled(H5)`
  margin-block-start: 0px;
  margin-block-end: 8px;
  color: ${colors.secondary};
  line-height: 28px;
  width: 100%;
`;

const Row = styled.div`
  width: 100%;
`;

const DownloadButton = styled.div`
  width: 86px;
  height: 24px;
  background-color: ${colors.background.primaryLabels};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px 0px 0px;
  cursor: pointer;
`;

const DownloadTitle = styled(H6)`
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-left: 7px;
  color: ${colors.secondary};
`;
