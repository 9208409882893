import { LabelType } from "components";
import { User } from "../user/user.types";
import { Length, IsInt } from "class-validator";

type ProgramType =
  | "addOption"
  | "expandOption"
  | "addAccessory"
  | "newProduct"
  | "regulatoryChange"
  | "obsolescence";

type ProgramRegion = "NA" | "EMEA" | "ASIA" | "SA";

export class Program {
  @Length(1, 30)
  name: string;

  @IsInt()
  tasksRemaining: number;

  status: LabelType;

  // integer representing a percentage (ex: '80' means 80%)
  @IsInt()
  completion: number;

  teamMembers: User[];
  requirements?: any;

  // negative numbers if the project is late
  @IsInt()
  daysLeft: number;
  type: ProgramType;
  region: ProgramRegion;
  updatedAt?: string;
  createdAt?: string;
  id?: string;
  productsImpacted?: any;
  partsImpacted?: any;
  supportingDocs?: any;
  tasks?: any;
}

export const programTypeOption: { label: string; value: ProgramType }[] = [
  {
    label: "Add Option",
    value: "addOption",
  },
  {
    label: "Expand Option",
    value: "expandOption",
  },

  {
    label: "Add Accessory",
    value: "addAccessory",
  },
  {
    label: "New Product",
    value: "newProduct",
  },
  {
    label: "Regulatory Change",
    value: "regulatoryChange",
  },
  {
    label: "Obsolescence",
    value: "obsolescence",
  },
];

export const programRegionOptions: { label: string; value: ProgramRegion }[] = [
  {
    label: "North America",
    value: "NA",
  },
  {
    label: "EMEA",
    value: "EMEA",
  },

  {
    label: "Asia",
    value: "ASIA",
  },
  {
    label: "South America",
    value: "SA",
  },
];

export interface Task {
  name?: string;
  milestone?: string;
  details?: string;
  taskOwners?: [];
  taskManagers?: [];
  dueDate?: any;
  id: any;
  status?: string;
}

export interface TasksState {
  byId: any;
  allIds: any;
}
