import { Program, TasksState, Task } from "./programs.types";

// Actions constants
export const SET_PROGRAMS = "programs/SET_PROGRAMS";
export const SET_NEW_PROGRAM = "programs/SET_NEW_PROGRAM";
export const SET_SELECTED_PROGRAM = "programs/SET_SELECTED_PROGRAM";
export const ADD_NEW_TASK = "programs/ADD_NEW_TASK";
export const EDIT_TASK = "programs/EDIT_TASK";

// Action creators
export const setProgramsAction = (programs: Program[]) => {
  return {
    type: SET_PROGRAMS,
    payload: programs,
  };
};

export const setNewProgramAction = (program: Partial<Program>) => {
  return {
    type: SET_NEW_PROGRAM,
    payload: program,
  };
};

export const setSelectedProgramAction = (selectedProgram: Partial<Program>) => {
  return {
    type: SET_SELECTED_PROGRAM,
    payload: selectedProgram,
  };
};

export const addNewTaskAction = (task: TasksState) => {
  return {
    type: ADD_NEW_TASK,
    payload: task,
  };
};

export const editTaskAction = (task: Task) => {
  return {
    type: EDIT_TASK,
    payload: task,
  };
};
