import React, { useCallback, useState } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEditInputCell,
  GridRowId,
  GridRowOrderChangeParams,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RATGridToolbar from "components/DataGrid/RATGridToolbar";
import colors from "theme/colors";
import {
  DisplayUnitsDataGridProps,
  DisplayUnitsDataGridRow,
  ROUND_DIGIT_MAX,
  ROUND_DIGIT_MIN,
  RoundMethod,
} from "./UnitPickerInterfaces";
import {
  CreateDisplayUnitsDataGridRow,
  ValidateRoundDigits,
} from "./UnitPickerUtility";

const DisplayUnitsDataGrid: React.FC<DisplayUnitsDataGridProps> = ({
  rows,
  setRows,
  displayUnitOptions,
}) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const handleAddRow = () => {
    const newRow: DisplayUnitsDataGridRow = CreateDisplayUnitsDataGridRow("");

    // Adjust according to your column definitions
    setRows((prevRows) => [...prevRows, newRow]);
  };

  const handleDeleteRow = useCallback(
    (id: GridRowId) => () => {
      console.log("Delete Property Row = ", id);
      setTimeout(() => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    []
  );

  const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    // Create a new array of rows from state
    const updatedRows = [...rows];

    // Remove the moved row from its old position
    const [movedRow] = updatedRows.splice(params.oldIndex, 1);

    // Insert the moved row at its new position
    updatedRows.splice(params.targetIndex, 0, movedRow);

    // Update the state with the reordered rows
    setRows(updatedRows);
  };

  const handleApplySort = () => {
    const sortedRows = [...rows].sort((a, b) => {
      for (let i = 0; i < sortModel.length; i++) {
        const { field, sort } = sortModel[i];
        if (a[field] < b[field]) return sort === "asc" ? -1 : 1;
        if (a[field] > b[field]) return sort === "asc" ? 1 : -1;
      }
      return 0;
    });
    setRows(sortedRows);
    setSortModel([]);
  };

  const handleClearSort = () => {
    // Clear the sort
    setSortModel([]);
  };

  const processRowUpdate = useCallback(
    (newRow: DisplayUnitsDataGridRow) => {
      //Validate min and max round digit length
      newRow.roundDigit = ValidateRoundDigits(newRow.roundDigit);

      //Copy the existing rows
      let updatedRows = [...rows];

      //If we have set the default cell to true, make all other rows' default cell false
      if (newRow.isDefault) {
        updatedRows = updatedRows.map((row) =>
          row.id === newRow.id ? newRow : { ...row, isDefault: false }
        );
      } else {
        updatedRows = updatedRows.map((row) =>
          row.id === newRow.id ? newRow : row
        );
      }

      setRows(updatedRows);
      return newRow;
    },
    [rows, setRows]
  );

  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "Actions",
      type: "actions",
      width: 75,
      headerAlign: "center",

      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={`delete-${params.id}`}
          icon={<DeleteOutlineIcon color="primary" />}
          label="Delete"
          onClick={handleDeleteRow(params.id)}
        />,
      ],
    },
    // {
    //   field: "id",
    //   headerName: "Id",
    //   type: "number",
    //   width: 50,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "displayUnit",
      headerName: "Display Unit",
      type: "singleSelect",
      valueOptions: displayUnitOptions,
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "isDefault",
      headerName: "Default",
      type: "boolean",
      width: 70,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "roundMethod",
      headerName: "Round Method",
      type: "singleSelect",
      valueOptions: [RoundMethod.DECIMAL, RoundMethod.SIGNIFICANT],
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "roundDigit",
      headerName: "Round Digits",
      type: "number",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderEditCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <GridEditInputCell
            {...params}
            inputProps={{
              max: ROUND_DIGIT_MAX,
              min: ROUND_DIGIT_MIN,
            }}
          />
          <IconButton
            aria-label="clear"
            size="small"
            onClick={() =>
              params.api.setEditCellValue({
                id: params.id,
                field: "roundDigit",
                value: "",
              })
            }
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Tooltip
      title="Display Unit values will be used to populate the .DISPLAYUNIT subproperty.  These represent all of the units the property value can be converted to and displayed on the UI or Reports."
      placement="top-end"
      arrow
    >
      <div style={{ width: "100%" }}>
        <Typography variant="caption" gutterBottom>
          Display Units
        </Typography>

        <DataGridPro
          rows={rows}
          columns={columns}
          autoHeight
          showColumnVerticalBorder={true}
          showCellVerticalBorder={true}
          editMode="row"
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => console.error(error)}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          disableColumnMenu
          rowReordering
          onRowOrderChange={handleRowOrderChange}
          slots={{
            toolbar: () => (
              <RATGridToolbar
                handleAddRow={handleAddRow}
                handleApplySort={handleApplySort}
                handleClearSort={handleClearSort}
                showColumnsButton={false}
                showFilterButton={false}
                showExportButton={false}
              />
            ),
          }}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.tables.columnsAndRowsTitles,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
        />
      </div>
    </Tooltip>
  );
};

export default DisplayUnitsDataGrid;
