import React from "react";
import styled from "styled-components";

export const Input = ({
  placeholder,
  type = "text",
  size = "tall",
  label = "",
  required = false,
  disabled = false,
  onChange,
  className,
  autoFocus,
  name,
  value,
  icon,
  onKeyDown,
  inputRef,
}: {
  placeholder?: string;
  label?: string;
  type?: string;
  size?: "tall" | "short";
  required?: boolean;
  disabled?: boolean;
  onChange?: any;
  onKeyDown?: any;
  className?: any;
  autoFocus?: any;
  name?: any;
  value?: any;
  icon?: any;
  inputRef?: any;
}) => {
  return (
    <StyledInputWrapper
      className={className}
      type={type}
      size={size}
      required={required}
      disabled={disabled}
    >
      {label?.length > 0 && size === "tall" && (
        <StyledLabel>{label}</StyledLabel>
      )}
      {label?.length > 0 && size === "short" && (
        <StyledLabelShort>{label}</StyledLabelShort>
      )}
      <StyledInput
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={onChange}
        name={name}
        value={value}
        size={size}
        onKeyDown={onKeyDown}
        ref={inputRef}
      />
      {icon && <StyledIcon>{icon}</StyledIcon>}
    </StyledInputWrapper>
  );
};

export const StyledInputWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: ${(props) => (props.size === "tall" ? "72px" : "48px")};
  width: 464px;
  max-width: 100%;
  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  border-radius: 4px;
  padding: 15px 24px 11px 24px;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.tables.borders : "white"};
`;

export const StyledInput = styled.input<any>`
  text-decoration: none;
  width: 100%;
  font-size: ${(props) => (props.size === "short" ? "14px" : "16px")};
  color: ${(props) => props.theme.colors.primary};
  outline: none;
  border: none;
  background: none;
  padding: 0;

  :disabled {
    cursor: not-allowed;
  }

  :focus {
    outline: none; /* Remove default outline and use border or box-shadow */
  }
`;

const StyledLabel = styled.span<any>`
  color: ${(props) => props.theme.colors.secondary};
  font-size: 12px;
  margin-bottom: 4px;
`;

const StyledLabelShort = styled(StyledLabel)<any>`
  display: inline-block;
  padding: 4px;
  position: absolute;
  margin: -29px 0 0 -10px;
  background-color: #fff;
  font-size: 10px;
`;

const StyledIcon = styled.div`
  line-height: 0px;
  cursor: pointer;
`;
