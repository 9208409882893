export interface Fonts {
  weights: {
    bold: number;
    normal: number;
  };
}

const fonts: Fonts = {
  weights: {
    // normal|bold|bolder|lighter|number|initial|inherit are alias in CSS
    // They are listed here just FYI, use css alias in the code.
    bold: 700,
    normal: 400,
  },
};

export default fonts;
