import React, { useState } from "react";
import { Button } from "../../../../../components";
import PropertySearchDropdown from "../../../components/RulesTable/PropertySearchDropDown.component";
import styled from "styled-components";
import { Close } from "../../../../../assets/logos/icons/MicroIcons";
import colors from "../../../../../theme/colors";
import { useDispatch } from "react-redux";
import { addPropertiesToPropertyPriorityList } from "../../../../../store/reducers/products/propertyPriorities/pl-propertyPriorities.action";

export function AddPropertyToPriorityList({
  propertyPrioritiesId,
  rulesetName,
}: {
  propertyPrioritiesId: string;
  rulesetName: string;
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedPropertyIds, setSelectedPropertyIds] = useState<string[]>([]);

  const dispatch = useDispatch();

  const handlePressAddProperty = () => {
    setShowDropdown(true);
  };

  const handlePressClose = () => {
    setSelectedPropertyIds([]);
    setShowDropdown(false);
  };

  const handleSelectProperty = (e) => {
    setSelectedPropertyIds(e);
  };

  const handleAddProperties = () => {
    dispatch(
      addPropertiesToPropertyPriorityList({
        propertyPrioritiesId,
        propertyIds: selectedPropertyIds,
      })
    );
    handlePressClose();
  };

  return (
    <Wrapper>
      {!showDropdown && (
        <Button size={"small"} width={"150px"} onClick={handlePressAddProperty}>
          add property
        </Button>
      )}
      {showDropdown && (
        <>
          <StyledPropertySearchComponent
            placeholder={"properties"}
            name={"propertiesDropdown"}
            multiselect
            onChange={handleSelectProperty}
            rulesetFilter={[rulesetName]}
          />
          <Button
            size={"small"}
            width={"70px"}
            onClick={handleAddProperties}
            disabled={!selectedPropertyIds.length}
          >
            add
          </Button>
          <StyledClose onClick={handlePressClose} />
        </>
      )}
    </Wrapper>
  );
}

const StyledClose = styled(Close)<any>`
  margin-left: 16px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
`;

const StyledPropertySearchComponent = styled(PropertySearchDropdown)`
  margin-right: 16px;
  > div > div > input {
    background-color: ${colors.tables.columnsAndRowsTitles} !important;
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    height: 32px !important;
    border: 1px solid ${colors.tables.borders} !important;
  }
`;
