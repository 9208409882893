import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactAuthWrapper } from "@carrier-sce-sharedpackages/reactauthwrapper";
import { useDispatch } from "react-redux";

import Router from "modules/router/Router";
import appConfig from "api/carrierAuthApi/appConfig";

import { setUserAction } from "store/reducers/user/user.actions";
import ToastWrapper from "modules/errors/ToastWrapper";
import { ConfirmActionProvider } from "./components/ConfirmActionModal/ConfirmActionProvider";

import { appName, baseUrl } from "./api/baseUrl";
import { api } from "./api/api";

import MuiXLicense from "MuiXLicence";

function App() {
  const dispatch = useDispatch();
  const callback = (permissions) => {
    const { lastName, firstName, email, userId } = permissions;
    dispatch(setUserAction({ lastName, firstName, email, id: userId }));
    console.log("User Permissions = ", permissions);
  };

  useEffect(() => {
    console.group("UI Application Information");
    console.log("Application Name in User Management = ", appName);
    console.log("UI URL = ", window.location.hostname);
    console.log("UI version = ", process.env.REACT_APP_VERSION);
    console.log(
      "API Base URL = ",
      baseUrl == "/" ? process.env.REACT_APP_PROXY : baseUrl
    );
    console.groupEnd();

    //Get info about the api so we can console log it
    api
      .getApplicationInfo()
      .then((appInfo) => {
        console.group("API Application Information");
        console.log("API version = ", appInfo.data.version);
        console.log("Database Server = ", appInfo.data.dbServer);
        console.log("Database Name = ", appInfo.data.dbName);
        console.groupEnd();
      })
      .catch((something) => {
        console.error(
          "Unable to retreive API application information.",
          something
        );
      });
  }, []);

  return (
    <ReactAuthWrapper appConfig={appConfig} getPermissions={callback}>
      <ToastWrapper>
        <AppStyledWrapper>
          <ConfirmActionProvider>
            <Router />
            <MuiXLicense />
          </ConfirmActionProvider>
        </AppStyledWrapper>
      </ToastWrapper>
    </ReactAuthWrapper>
  );
}

const AppStyledWrapper = styled.div`
  font-size: 14px;

  margin: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.colors.primary};
  a {
    color: ${(props) => props.theme.colors.primary};
  }

  @media ${(props) => props.theme.device.mobile} {
    letter-spacing: 0.7px;
    line-height: 1.5;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
`;

export default App;
