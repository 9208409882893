import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CalendarIcon } from "assets/logos/icons/MediumIcons";
import { DatePicker, NormalP } from "components";
import { convertTimestampToDate } from "helpers/datesConverter";

export function DatePickerInput({
  className,
  onChange,
  value,
  id,
}: {
  className?: any;
  onChange: (value: any) => void;
  value?: string;
  id?: any;
}) {
  const [isDateSet, setTheDate] = useState<any>();
  useEffect(() => {
    setTheDate(value);
  }, [id]);
  const handleChange = (newDate) => {
    setTheDate(newDate);
    onChange(newDate);
  };
  return (
    <DatePicker setInputDate={(el) => handleChange(el)}>
      <StyledInputWrapper className={className}>
        <CustomIcon>
          <CalendarIcon />
        </CustomIcon>
        <StyledParagraph>
          {isDateSet ? convertTimestampToDate(isDateSet) : "Set Due Date"}
        </StyledParagraph>
      </StyledInputWrapper>
    </DatePicker>
  );
}

const StyledInputWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  height: 72px;
  width: 600px;
  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.tables.borders : "white"};
`;

const CustomIcon = styled.div`
  cursor: pointer;
  margin-right: 8px;
`;

const StyledParagraph = styled(NormalP)`
  margin-block-start: 0px;
  margin-block-end: 0px;
  color: ${(props) => props.theme.colors.muttedHeadlines};
  line-height: 28px;
`;
