import React from "react";
import styled from "styled-components";

export function CreateIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 8V49H5V8H52ZM49 11H8V46H49V11ZM30 24V30H36V33H30V39H27V33H21V30H27V24H30ZM12 13.5C12.8284 13.5 13.5 14.1716 13.5 15C13.5 15.8284 12.8284 16.5 12 16.5C11.1716 16.5 10.5 15.8284 10.5 15C10.5 14.1716 11.1716 13.5 12 13.5ZM18 13.5C18.8284 13.5 19.5 14.1716 19.5 15C19.5 15.8284 18.8284 16.5 18 16.5C17.1716 16.5 16.5 15.8284 16.5 15C16.5 14.1716 17.1716 13.5 18 13.5Z"
        fill="#027BFD"
      />
    </svg>
  );
}

export function ConfigureIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 36V42H51V45H25V51H16V36H25ZM22 39H19V48H22V39ZM13 42V45H6V42H13ZM41 21V36H32V30H6V27H32V21H41ZM38 24H35V33H38V24ZM51 27V30H44V27H51ZM25 6V12H51V15H25V21H16V6H25ZM22 9H19V18H22V9ZM13 12V15H6V12H13Z"
        fill="#027BFD"
      />
    </svg>
  );
}

export function ModifyIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4065 5.00292L37.3667 5.00594C30.9853 5.57571 25.8863 11.2374 26.0004 17.6268L26.0096 18.0661C26.0343 18.6557 26.1079 19.2593 26.2257 19.8659L26.3151 20.287L7.80575 36.677C4.35744 39.6495 4.11359 44.9818 7.22426 48.3372L7.41961 48.5404L7.61847 48.733C10.9712 51.8745 16.2165 51.6596 19.317 48.2013L35.7151 29.68L36.0314 29.7495C37.709 30.0861 39.404 30.1089 41.0943 29.7709C45.6561 28.8585 49.3933 25.4407 50.6438 21.0072C51.209 18.4203 51.1241 15.96 50.4369 13.669L49.7152 11.2636L43.1001 17.879L38.1211 12.9L44.7634 6.25805L42.3122 5.55771C40.6594 5.0855 39.0989 4.89715 37.4065 5.00292ZM38.7921 7.986L33.8788 12.9L43.1001 22.1213L48.0091 17.211L48.0192 17.5217C48.0318 18.4226 47.9378 19.3453 47.7343 20.2814C46.8137 23.5351 43.9693 26.1365 40.5059 26.8291C38.8938 27.1516 37.2614 27.0427 35.6234 26.561L34.7089 26.292L17.0771 46.2056C15.0686 48.4458 11.6638 48.5424 9.56078 46.4393L9.40477 46.2769C7.4596 44.1784 7.61396 40.803 9.77953 38.9361L29.7124 21.2874L29.4369 20.369C29.1539 19.4259 29.0001 18.4811 29.0001 17.6L28.9994 17.3365C29.0551 12.6905 32.7646 8.57142 37.3718 8.02136L37.5937 7.99708C37.8911 7.97849 38.1827 7.97131 38.471 7.97557L38.7921 7.986Z"
        fill="#027BFD"
      />
    </svg>
  );
}

export function NewTableIcon() {
  return (
    <svg
      width="46"
      height="42"
      viewBox="0 0 46 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="44"
        height="40"
        fill="#E1EFFF"
        stroke="#027BFD"
        strokeWidth="2"
      />
      <path d="M1 13H45" stroke="#027BFD" strokeWidth="2" />
      <path d="M15 13V41" stroke="#027BFD" strokeWidth="2" />
    </svg>
  );
}

export function TemplateIcon() {
  return (
    <svg
      width="42"
      height="46"
      viewBox="0 0 42 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="13"
        width="28"
        height="32"
        fill="#E1EFFF"
        stroke="#027BFD"
        strokeWidth="2"
      />
      <path d="M8 7H35V40" stroke="#027BFD" strokeWidth="2" />
      <path d="M14 1H41V34" stroke="#027BFD" strokeWidth="2" />
    </svg>
  );
}

function PlusAction({ className }: { className: string }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="24" cy="24" r="24" fill="#E1EFFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3334 14.6667V22.6667H33.3334V25.3333H25.3321L25.3334 33.3333H22.6667L22.6654 25.332L14.6667 25.3333V22.6667L22.6667 22.6653V14.6667H25.3334Z"
        fill="#027BFD"
      />
    </svg>
  );
}

export const PlusActionIcon = styled(PlusAction)<any>`
  cursor: pointer;
  fill: black;

  &:hover circle {
    fill: ${(props) => props.theme.colors.secondary};
  }

  &:hover path {
    fill: white;
  }
`;
