import { DataTypeEnum } from "../properties/pl-properties.types";

export interface SubPropertiesState {
  byParentPropertyId: any;
  allParentPropertyIds: any;
  newSubProperty: any;
  getting: boolean;
  deleting: boolean;
  posting: boolean;
}

export enum DomainEnum {
  OPEN = "OPEN",
  DISCRETE = "DISCRETE",
  RANGE = "RANGE",
  EXPRESSION = "EXPRESSION",
}

export const domainOptions: DomainEnum[] = [
  DomainEnum.OPEN,
  DomainEnum.DISCRETE,
  DomainEnum.RANGE,
  DomainEnum.EXPRESSION,
];

export interface SubProperty {
  name: string;
  dataType: DataTypeEnum;
  domain: DomainEnum;
  ruleSets: any;
  tags: any;
  propertyValueAttributes?;
  isStandardSubProp: boolean;
}

export enum StandardSubProperties {
  ALERT = ".ALERT",
  BOLD = ".BOLD",
  CHILDREN = ".CHILDREN",
  CONTROLTYPE = ".CONTROLTYPE",
  DEFAULT = ".DEFAULT",
  DETAILS = ".DETAILS",
  DISPLAYUNIT = ".DISPLAYUNIT",
  DISPLAYUNIT_DEFAULT = ".DISPLAYUNIT.DEFAULT",
  ENABLED = ".ENABLED",
  FIX = ".FIX",
  FIX_DEFAULT = ".FIX.DEFAULT",
  HIDEINFEASIBLE = "HIDEINFEASIBLE",
  MIN = ".MIN",
  MAX = ".MAX",
  MSG_INFO = ".MSG_INFO",
  MSG_WARNING = ".MSG_WARNING",
  MSG_ERROR = ".MSG_ERROR",
  ORDER = ".ORDER",
  PERCENT = ".PERCENT",
  UNIT = ".UNIT",
  UNIT_DEFAULT = ".UNIT.DEFAULT",
  VALID = ".VALID",
  VISIBLE = ".VISIBLE",
}

export const StandardSubPropertiesOptions = Object.keys(StandardSubProperties);

export const standardSubPropertiesObject = {
  ".ALERT": {
    name: StandardSubProperties.ALERT,
    dataType: DataTypeEnum.BOOL,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".BOLD": {
    name: StandardSubProperties.BOLD,
    dataType: DataTypeEnum.BOOL,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".CHILDREN": {
    name: StandardSubProperties.CHILDREN,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".CONTROLTYPE": {
    name: StandardSubProperties.CONTROLTYPE,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".DEFAULT": {
    name: StandardSubProperties.DEFAULT,
    dataType: DataTypeEnum.BOOL, // determined by the main property
    domain: DomainEnum.OPEN,
    ruleSets: [],
    tags: [],
  },
  ".DETAILS": {
    name: StandardSubProperties.DETAILS,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.OPEN,
    ruleSets: [],
    tags: [],
  },
  ".DISPLAYUNIT": {
    name: StandardSubProperties.DISPLAYUNIT,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".DISPLAYUNIT.DEFAULT": {
    name: StandardSubProperties.DISPLAYUNIT_DEFAULT,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".ENABLED": {
    name: StandardSubProperties.ENABLED,
    dataType: DataTypeEnum.BOOL,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".FIX": {
    name: StandardSubProperties.FIX,
    dataType: DataTypeEnum.BOOL,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".FIX.DEFAULT": {
    name: StandardSubProperties.FIX_DEFAULT,
    dataType: DataTypeEnum.BOOL,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
  },
  ".MIN": {
    name: StandardSubProperties.MIN,
    dataType: DataTypeEnum.INT, // determined by the main property
    domain: DomainEnum.OPEN,
    ruleSets: [],
    tags: [],
  },
  ".MAX": {
    name: StandardSubProperties.MAX,
    dataType: DataTypeEnum.INT, // determined by the main property
    domain: DomainEnum.OPEN,
    ruleSets: [],
    tags: [],
  },
  ".MSG_INFO": {
    name: StandardSubProperties.MSG_INFO,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.OPEN,
    ruleSets: [],
    tags: [],
  },
  ".MSG_WARNING": {
    name: StandardSubProperties.MSG_WARNING,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.OPEN,
    ruleSets: [],
    tags: [],
  },
  ".MSG_ERROR": {
    name: StandardSubProperties.MSG_ERROR,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.OPEN,
    ruleSets: [],
    tags: [],
  },
  ".ORDER": {
    name: StandardSubProperties.ORDER,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".PERCENT": {
    name: StandardSubProperties.PERCENT,
    dataType: DataTypeEnum.FLOAT,
    domain: DomainEnum.OPEN,
    ruleSets: [],
    tags: [],
  },
  ".UNIT": {
    name: StandardSubProperties.UNIT,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".UNIT.DEFAULT": {
    name: StandardSubProperties.UNIT_DEFAULT,
    dataType: DataTypeEnum.STRING,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".VALID": {
    name: StandardSubProperties.VALID,
    dataType: DataTypeEnum.BOOL,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
  ".VISIBLE": {
    name: StandardSubProperties.VISIBLE,
    dataType: DataTypeEnum.BOOL,
    domain: DomainEnum.DISCRETE,
    ruleSets: [],
    tags: [],
  },
};
