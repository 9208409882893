import React, { useEffect, useRef, useState } from "react";
import { useTypedSelector } from "store";
import styled from "styled-components";
import { api } from "api/api";
import {
  productRegionOptions,
  ProductRegion,
} from "store/reducers/products/products.types";
import { CircularProgressWithLabel } from "components";
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { ConflictAction, ProcessState } from "modules/constants/enums";
import { useInterval } from "hooks/useInterval";

//Interval between validation status polling requests in milliseconds
const POLLING_INTERVAL = 1000;

export const ImportProductForm = ({
  handleRefreshProductlines,
  visible,
}: {
  handleRefreshProductlines: () => void;
  visible: boolean;
}) => {
  //Redux Store
  const currentUserId = useTypedSelector((state) => state.user.id);

  //State
  const [importFile, setImportFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [fileJson, setFileJson] = useState<Record<string, unknown> | null>(
    null
  );
  const [fileName, setFileName] = useState<string | null>("");
  const [name, setName] = useState<string>("");
  const [region, setRegion] = useState<string>(ProductRegion.GLOBAL);
  const [productLineId, setProductLineId] = useState<string>("");
  const [existingProductLine, setExistingProductLine] = useState<any>(null);
  const [conflictAction, setConflictAction] = useState<ConflictAction>(
    ConflictAction.ABORT
  );
  const [haveReplaceRights, setHaveReplaceRights] = useState<boolean>(false);
  const [processId, setProcessId] = useState<string>("");
  const [processMsg, setProcessMessage] = useState<string>("");
  const [processPercentComplete, setProcessPercentComplete] = useState<number>(
    0
  );
  const [processState, setProcessState] = useState<ProcessState>(
    ProcessState.IDLE
  );
  const [pollingInterval] = useState<number>(POLLING_INTERVAL);
  const waitingForPollResponse = useRef(false);

  const clearProductLineInfo = () => {
    setFileJson(null);
    setProductLineId("");
    setName("");
    setRegion(ProductRegion.GLOBAL);
    setConflictAction(ConflictAction.ABORT);
    setExistingProductLine(null);
    setHaveReplaceRights(false);
    setProcessId("");
    setProcessState(ProcessState.IDLE);
    setProcessPercentComplete(0);
    setProcessMessage("");
  };

  const resetAllState = () => {
    setConflictAction(ConflictAction.ABORT);
    setExistingProductLine(null);
    setFileError(null);
    setFileJson(null);
    setFileName("");
    setHaveReplaceRights(false);
    setImportFile(null);
    setName("");
    setProcessId("");
    setProcessMessage("");
    setProcessPercentComplete(0);
    setProcessState(ProcessState.IDLE);
    setProductLineId("");
    setRegion(ProductRegion.GLOBAL);
    clearProductLineInfo;
    setFileJson(null);

    waitingForPollResponse.current = false;
  };

  //Determine if we have gathered all the items necessary to do the import
  const isImportReady =
    processState === ProcessState.IDLE &&
    fileJson &&
    name &&
    region &&
    (!existingProductLine ||
      (existingProductLine &&
        (conflictAction === ConflictAction.NEW ||
          conflictAction === ConflictAction.REPLACE)));

  //This useEffect on the change of the productLineId, checks to see
  //if the productline already exists and sets appropriate state variable
  useEffect(() => {
    if (productLineId) {
      //Call the api and see if the productline exists
      const fetchData = async () => {
        try {
          const response: any = await api.getProductLine(productLineId, false);
          //Check if we have response data which indicates the productline exists
          setExistingProductLine(
            response && response.data ? response.data : null
          );
        } catch (error) {
          console.error("API error fetching productline: ", error);
          setExistingProductLine(null);
        }
      };

      fetchData();
    } else {
      //No productLineId means we don't have a conflict.
      setExistingProductLine(null);
    }
  }, [productLineId]);

  useEffect(() => {
    const isAdmin = haveAdminRightsToExistingProductLine();
    setHaveReplaceRights(isAdmin);
  }, [existingProductLine]);

  useEffect(() => {
    if (!visible) {
      resetAllState();
    }
  }, [visible]);

  const haveAdminRightsToExistingProductLine = () => {
    if (!existingProductLine || !existingProductLine.users) return false;
    if (!currentUserId) return false;

    const isAdmin = existingProductLine.users.some(
      (user) =>
        user.id === currentUserId.toString().trim() && user.role === "ADMIN"
    );
    return isAdmin;
  };

  // handlers
  const handleFileChange = (event) => {
    //Clear out any previous productline data
    clearProductLineInfo();

    //Get a reference to the file object from the input component
    const file = event.target.files && event.target.files[0];
    if (!file) {
      setImportFile(null);
      setFileName("");
      setFileError("No import file chosen");
      return;
    }

    //reset file input
    event.target.value = null;

    //Save the file to state
    setImportFile(file);
    setFileName(file.name);

    handleFileChosen(file);
  };

  const handleFileChosen = (file: File | null) => {
    if (!file) {
      setFileError("No import file chosen");
      clearProductLineInfo();
      return;
    }
    if (!file.type.match("json")) {
      setFileError("Import file is not valid JSON.");
      clearProductLineInfo();
      return;
    }
    //Case insensitive match for rules database file name suffix
    if (!file.name || !/@@Database.*\.json/i.test(file.name)) {
      setFileError("Not a rules database file");
      clearProductLineInfo();
      return;
    }

    const fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  const handleFileRead = (e: ProgressEvent<FileReader>) => {
    const content = e.target?.result as string;
    let parsedData: any = null;
    try {
      parsedData = JSON.parse(content);
      setFileJson(parsedData);
      setFileError(null);
    } catch (err) {
      setFileError("Import file is not valid JSON.");
      clearProductLineInfo();
      return;
    }

    if (!parsedData?.ProductLine?.Name) {
      setFileError(
        "Unable to determine the product line name.  This is not a valid file for import."
      );
      clearProductLineInfo();
      return;
    }
    if (!parsedData?.ProductLine?.Id) {
      setFileError(
        "Unable to determine the product line Id.  This is not a valid file for import."
      );
      clearProductLineInfo();
      return;
    }
    if (!parsedData?.ProductLine?.Region) {
      setFileError(
        "Unable to determine the product line Region.  This is not a valid file for import."
      );
      clearProductLineInfo();
      return;
    }

    //Read the object to extract product line info from it and save to state.
    setProductLineId(parsedData?.ProductLine?.Id);
    setName(parsedData?.ProductLine?.Name);
    setRegion(parsedData?.ProductLine?.Region);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };

  const handleConflictActionChange = (event) => {
    setConflictAction(event.target.value);
  };

  //Request API to begin import of the productline
  const beginImport = async () => {
    setProcessState(ProcessState.INITIALIZING);
    setProcessMessage("Uploading product line file to server");

    try {
      //Request to import the productline.
      //This will queue the request to run asynchronously and immediately return
      const response: any = await api.importProductLineDatabaseJson(
        importFile,
        name,
        region,
        conflictAction
      );

      //If the validation request was accepted successfully by the api,
      //update our state with the process response.
      if (response && response.status == 202) {
        if (response.data) {
          setProcessId(response.data.id);
          setProcessState(response.data.processState);
          setProcessPercentComplete(response.data.percentComplete);
          setProcessMessage(response.data.message);
        }
      } else {
        //some error happened, update state with the error message
        setProcessState(ProcessState.ERROR);
        setProcessPercentComplete(100);
        setProcessMessage(
          response?.response?.data
            ? response.response.data
            : "Unable to initiate the Import Product Line process."
        );
      }
    } catch (error) {
      //A totally unexpected error happened.  Update state.
      console.error(
        error,
        "Error occurred while starting importing product line"
      );
      setProcessState(ProcessState.ERROR);
      setProcessPercentComplete(100);
      setProcessMessage("Error occurred while starting importing product line");
    }
  };

  const pollImportStatus = async () => {
    //Begin polling request if we have a process we are tracking and we are not already
    //waiting on a response.
    //console.log("pollImportStatus BEGIN");
    if (processId && !waitingForPollResponse.current) {
      //flag that we are waiting to prevent re-entrance
      waitingForPollResponse.current = true;

      try {
        const response = await api.getProcess(processId);

        if (response.status != 200) {
          setProcessState(ProcessState.ERROR);
        }

        if (response && response.data && response.data.processState) {
          setProcessState(response.data.processState);
          setProcessPercentComplete(response.data.percentComplete);
          setProcessMessage(response.data.message);

          //If the import process is complete, stop polling, and
          //trigger the callback to refresh the product lines
          if (response.data.processState == ProcessState.COMPLETE) {
            handleRefreshProductlines();
          }
        } else {
          //some error happened, update state with the error message
          setProcessState(ProcessState.ERROR);
          setProcessPercentComplete(100);
          setProcessMessage(
            response?.data?.message
              ? response.data.message
              : "Unable to retrieve status of the Import Product Line process."
          );
        }
      } catch (error) {
        console.error(error, "Error occurred while importing product line");
        setProcessState(ProcessState.ERROR);
        setProcessPercentComplete(100);
        setProcessMessage("Error occurred while importing product line");
      } finally {
        waitingForPollResponse.current = false;
        //console.log("pollImportStatus END");
      }
    }
  };

  //While the import process is queued or actively processing,
  //we will periodically poll the process status.
  //If the import is not processing, we will stop polling by setting
  //the delay/interval to 0.
  useInterval(
    pollImportStatus,
    processState === ProcessState.QUEUED ||
      processState === ProcessState.PROCESSING
      ? pollingInterval
      : 0
  );

  //If the form is not visible, don't bother rendering anything
  if (!visible) {
    return null;
  }

  return (
    <Grid
      container
      columnSpacing={1}
      rowSpacing={3}
      alignItems="center"
      style={{ marginLeft: "20px", marginRight: "20px" }}
    >
      <Grid xs={10}>
        <TextField
          label="File Name"
          value={fileName}
          id="file-text-input"
          variant="outlined"
          size="small"
          fullWidth
          multiline
          margin="none"
          disabled
        />
      </Grid>
      <Grid xs>
        <Button component="label" variant="contained">
          <FileOpenOutlinedIcon />
          <VisuallyHiddenInput
            type="file"
            accept=".json"
            onChange={handleFileChange}
          />
        </Button>
      </Grid>
      {fileError && (
        <Grid xs={12}>
          <Alert severity="error">{fileError}</Alert>
        </Grid>
      )}
      <Grid xs={12}>
        <TextField
          label="Product Line Name"
          value={name}
          id="productline-name"
          variant="outlined"
          size="small"
          fullWidth
          margin="none"
          onChange={handleNameChange}
          disabled={!fileJson}
        />
      </Grid>
      <Grid xs={12}>
        <TextField
          label="Region"
          //defaultValue={ProductRegion.GLOBAL}
          value={region}
          id="productline-region"
          variant="outlined"
          size="small"
          fullWidth
          margin="none"
          select
          SelectProps={{
            native: true,
          }}
          onChange={handleRegionChange}
          disabled={!fileJson}
        >
          {productRegionOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid xs={12}>
        <TextField
          label="Product Line Id"
          id="productline-id"
          value={productLineId}
          variant="outlined"
          size="small"
          fullWidth
          margin="none"
          disabled
        />
      </Grid>
      {existingProductLine && (
        <Grid
          xs={12}
          sx={{ border: "2px solid orange" }}
          justifyContent="center"
        >
          <Alert severity="warning">
            A productline with the same id already exists named &quot;
            {existingProductLine.name}&quot;.
          </Alert>
          <FormControl sx={{ marginLeft: 2 }}>
            <FormLabel id="conflict-actions-group-label">
              <p>How should the conflict be resolved?</p>
            </FormLabel>
            <RadioGroup
              aria-labelledby="conflict-actions-group-label"
              value={conflictAction}
              name="conflict-actions-radio-group"
              onChange={handleConflictActionChange}
            >
              <FormControlLabel
                key={"ABORT"}
                value={"ABORT"}
                label={"Do not import"}
                control={<Radio />}
              />
              <FormControlLabel
                key={"NEW"}
                value={"NEW"}
                label={"Import as new Product Line"}
                control={<Radio />}
              />
              <FormControlLabel
                key={"REPLACE"}
                value={"REPLACE"}
                label={"Replace existing Product Line"}
                control={<Radio />}
                disabled={!haveReplaceRights}
              />
            </RadioGroup>
            {!haveReplaceRights && (
              <FormHelperText>
                ADMIN rights are required to replace a Product Line
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      )}
      <Grid xs={12}>
        <Button
          id="productline-import"
          variant="contained"
          size="large"
          fullWidth
          disabled={!isImportReady}
          onClick={beginImport}
        >
          Import Product Line
        </Button>
      </Grid>
      <Grid xs={12} display="flex" justifyContent="center">
        {processState === ProcessState.INITIALIZING && <CircularProgress />}
        {(processState === ProcessState.QUEUED ||
          processState === ProcessState.PROCESSING ||
          processState === ProcessState.COMPLETE) && (
          <CircularProgressWithLabel value={processPercentComplete} />
        )}
      </Grid>
      <Grid xs={12} display="flex" justifyContent="center">
        {(processState === ProcessState.INITIALIZING ||
          processState === ProcessState.QUEUED ||
          processState === ProcessState.PROCESSING) && (
          <Alert severity="info" variant="outlined">
            <strong>Import Progress</strong>
            <p>{processMsg}</p>
          </Alert>
        )}
        {processState === ProcessState.COMPLETE && (
          <Alert severity="success" variant="outlined">
            <strong>Success</strong>
            <p>{processMsg}</p>
          </Alert>
        )}
        {processState === ProcessState.ERROR && (
          <Alert severity="error" variant="outlined">
            <strong>Error: Import Failed</strong>
            <p>{processMsg}</p>
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default ImportProductForm;
