import axios, { AxiosResponse } from "axios";
import { RulesEngineApiUrl, RulesEngineEndpoint } from "./constants";
import { toast } from "react-toastify";
import JSZip from "jszip";

const getEndPointURL = (
  baseURL: RulesEngineApiUrl,
  endPoint: RulesEngineEndpoint
): string => {
  return baseURL + endPoint;
};

const getHeaders = () => {
  const headers: Record<string, string> = {};

  const accessToken = localStorage.getItem("msal.idtoken");
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`; // Corrected to use template literals properly
  }

  return headers;
};

export const CleanCaches = async (
  baseUrl: RulesEngineApiUrl
): Promise<AxiosResponse<void>> => {
  try {
    // Build the URL for the API call
    const url: string = getEndPointURL(
      baseUrl,
      RulesEngineEndpoint.CLEAN_CACHES
    );

    // Make the API call
    const response = await axios.get<void>(url, {
      headers: getHeaders(),
    });
    return response; // Returns the full response
  } catch (error) {
    let errMsg = `${RulesEngineEndpoint.CLEAN_CACHES} error: `;
    if (axios.isAxiosError(error) && error.response) {
      // Ensure that error.response exists before accessing it
      errMsg += JSON.stringify(error.response.data);
    } else if (error instanceof Error) {
      errMsg += error.message;
    } else {
      errMsg += "An unexpected error occurred";
    }
    toast.error(errMsg); // Display the error message as a toast notification
    throw new Error(errMsg); // Throw the error to be caught by the caller
  }
};

export const UploadProductLineRulesZip = async (
  baseUrl: RulesEngineApiUrl,
  productLineJson: string,
  fileName = "productLine.json"
): Promise<AxiosResponse<void>> => {
  // console.log("UploadProductLineRulesZip baseURL = ", baseUrl);
  // console.log("UploadProductLineRulesZip fileName = ", fileName);

  const zip = new JSZip();
  const jsonStr = JSON.stringify(productLineJson);
  zip.file(fileName, jsonStr); // Add JSON file to the zip

  try {
    // Generate a blob from the ZIP
    const blob = await zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 9,
      },
      mimeType: "application/zip",
    });

    const formData = new FormData();
    const zipFileName = fileName.replace(/\.[^/.]+$/, ".zip"); // Replace the file extension with .zip
    formData.append("file", blob, zipFileName); // Append zip blob to FormData with the derived zip file name

    // Build the URL for the API call
    const url: string = getEndPointURL(
      baseUrl,
      RulesEngineEndpoint.UPLOAD_PRODUCTLINE_RULES_ZIP
    );

    // Make the API call
    const response = await axios.post<void>(url, formData, {
      headers: getHeaders(),
    });

    return response; // Returns the full response
  } catch (error) {
    let errMsg = `${RulesEngineEndpoint.UPLOAD_PRODUCTLINE_RULES_ZIP} error: `;
    if (axios.isAxiosError(error) && error.response) {
      // Ensure that error.response exists before accessing it
      errMsg += JSON.stringify(error.response.data);
    } else if (error instanceof Error) {
      errMsg += error.message;
    } else {
      errMsg += "An unexpected error occurred";
    }
    toast.error(errMsg); // Display the error message as a toast notification
    throw new Error(errMsg); // Throw the error to be caught by the caller
  }
};
