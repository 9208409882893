import React from "react";
import { useTypedSelector } from "store";
import styled from "styled-components";
import colors from "theme/colors";
import AddNewTableButton from "./AddNewTableButton.component";
import PLFiltersRulesTables from "../../../components/PLFiltersRulesTables.component";
import { H3, LoaderSpinner2 } from "components";
import {
  getNextRulesTablesPage,
  RULES_TABLES_ITEMS_PER_PAGE,
} from "store/reducers/products/rulestables/pl-rulestables.actions";
import { useDispatch } from "react-redux";
import RulesTable from "pages/Products/components/RulesTable/RulesTable.component";
import { selectRulesTablesPagination } from "../../../../../store/reducers/products/rulestables/pl-ruletables.selectors";
import Pagination from "../../../../../components/Pagination/Pagination.component";
import ImportTableFromTSVButton from "./ImportTableFromTSVButton.component";

export default function ProductLineRulesTables() {
  const { byId: rulesTablesById } = useTypedSelector(
    (state) => state.products.rulestables
  );

  const { getting: gettingRulesTables } = useTypedSelector(
    (state) => state.products.rulestables
  );

  const { page, total } = useTypedSelector((state) =>
    selectRulesTablesPagination(state)
  );

  const dispatch = useDispatch();

  const handleNextPage = (page) => {
    dispatch(getNextRulesTablesPage(page));
  };

  const scrollToTop = () => {
    const page = document.getElementById("product-lines-wrapper");
    page && page.scrollTo(0, 0);
  };

  return (
    <Wrapper>
      <FilterAddWrapper>
        <PLFiltersRulesTables />
        <AddNewTableButton />
        <ImportTableFromTSVButton />
      </FilterAddWrapper>
      <StyledHeader bold color={colors.muttedHeadlines}>
        Rules Tables
      </StyledHeader>
      <PropertiesListWrapper>
        {!gettingRulesTables &&
          Object.keys(rulesTablesById).map((id) => (
            <RuletableStyledWrapper key={id}>
              <RulesTable id={id} ruleTableData={rulesTablesById[id]} />
            </RuletableStyledWrapper>
          ))}
        <LoaderWrapper>
          {gettingRulesTables ? (
            <LoaderSpinner2 />
          ) : (
            <Pagination
              page={page}
              itemsPerPage={RULES_TABLES_ITEMS_PER_PAGE}
              total={total}
              changePageAction={handleNextPage}
              handleScroll={scrollToTop}
            />
          )}
        </LoaderWrapper>
      </PropertiesListWrapper>
    </Wrapper>
  );
}

const PropertiesListWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 90vh;
  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;
  white-space: nowrap;
`;

const RuletableStyledWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.tables.borders};
  padding-top: 1px;
  padding-bottom: 32px;
  margin-bottom: 32px;
`;

const FilterAddWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.tables.borders};
  align-items: center;
  width: 100%;
  min-height: 102px;
  padding: 8px 32px 8px 32px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: ${(props) => props.theme.colors.white};
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background.pageTable};
`;

const StyledHeader = styled(H3)`
  padding-left: 30px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
`;
