import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { H3, Loader1, Button, H4 } from "components";
import colors from "theme/colors";
import { useTypedSelector } from "store";
import { selectProductLineId } from "store/reducers/products/products.selectors";
import {
  selectCandidateJsonData,
  selectCandidateGetting,
} from "store/reducers/products/candidate/candidate.selectors";
import { useDispatch } from "react-redux";
import {
  getCandidateSelection,
  saveCandidateSelection,
  SET_VERB,
  addSectionCandidate,
  // addSectionCandidate,
} from "store/reducers/products/candidate/candidate.actions";
import { candidateJsonExample } from "store/reducers/products/candidate/candidate.fixtures";

export default function ProductCandidateSelection() {
  const [mode, setMode] = useState("tree");
  const dispatch = useDispatch();
  const selectedProductLineId = useTypedSelector(selectProductLineId);
  const candidateJson = useTypedSelector(selectCandidateJsonData);
  const getting = useTypedSelector(selectCandidateGetting);

  useEffect(() => {
    if (!selectedProductLineId) return;
    dispatch(getCandidateSelection());
  }, [selectedProductLineId]);

  const saveJson = (data) => {
    dispatch(
      saveCandidateSelection(
        Object.keys(data).map((key: string) => {
          return data[key];
        })
      )
    );
  };

  const createElement = () => {
    dispatch(addSectionCandidate());
  };

  const changeMode = () => {
    setMode(mode === "code" ? "tree" : "code");
    dispatch({ type: SET_VERB, payload: { getting: true } });
    setTimeout(() => {
      dispatch({ type: SET_VERB, payload: { getting: false } });
    }, 1000);
  };
  return (
    <Wrapper>
      <StyledHeader bold color={colors.muttedHeadlines}>
        Candidate Selection
      </StyledHeader>
      <ButtonsContainer>
        <StyledButton
          size="small"
          onClick={changeMode}
          style={{ marginRight: 16 }}
        >
          Switch to {mode === "code" ? "tree" : "code"} mode
        </StyledButton>
        <StyledButton size="small" onClick={createElement}>
          + Add candidate template
        </StyledButton>
      </ButtonsContainer>
      {candidateJson && !getting ? (
        <EditorWrapper>
          <Editor
            value={candidateJson}
            onChange={(json) => saveJson(json)}
            mode={mode}
          />
          <Divider />
          <div style={{ marginTop: 32 }}>
            <H4>Example:</H4>

            <Example>
              <pre>{JSON.stringify(candidateJsonExample, null, 2)}</pre>
            </Example>
          </div>
        </EditorWrapper>
      ) : (
        <LoaderContainer>
          <Loader1 />
        </LoaderContainer>
      )}
    </Wrapper>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
`;

const Divider = styled.div`
  height: 2px;
  margin-top: 64px;

  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.disabled};
`;

const EditorWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Example = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.disabled};
`;
const StyledButton = styled(Button)`
  max-width: 200px;
  margin-bottom: 32px;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 500px;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(H3)`
  padding-left: 30px;
`;
