import React from "react";
import { User } from "components/Avatar/User.component";
import { useTypedSelector } from "store";
import styled from "styled-components";
import { DesktopOnly, MobileOnly, Avatar } from "components";

export function ResponsiveUser() {
  const { firstName, lastName, role, preferences, id } = useTypedSelector(
    (state) => state.user
  );
  const { navigationOpen } = preferences;

  return (
    <>
      <MobileOnly>
        <Avatar firstName={firstName} lastName={lastName} size="small" />
      </MobileOnly>
      <DesktopOnly>
        <StyledUser
          size="extraSmall"
          firstName={firstName}
          lastName={lastName}
          role={role}
          color="white"
          hideRole={!navigationOpen}
          id={id}
        />
      </DesktopOnly>
    </>
  );
}

const StyledUser = styled(User)`
  padding: ${(props) => (props.hideRole ? "0px" : "24px")};
`;
