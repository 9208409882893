import { SET_METADATA, SET_VERB } from "./metadata.actions";
import { MetadataInitialState } from "./metadata.types";

export const metadataInitialState: MetadataInitialState = {
  data: undefined,
  getting: false,
  posting: false,
  deleting: false,
};

export function metadataReducer(
  metadataState: MetadataInitialState = metadataInitialState,
  action: any
) {
  switch (action.type) {
    case SET_METADATA: {
      return {
        ...metadataState,
        data: action.payload,
      };
    }
    case SET_VERB: {
      return {
        ...metadataState,
        ...action.payload,
      };
    }

    default:
      return metadataState;
  }
}
