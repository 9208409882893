import React from "react";
import { CardCustom } from "components";
import { PlusActionIcon } from "assets/logos/icons/QuickActions";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import routes from "modules/router/routes";

export function AddNewProgram() {
  const history = useHistory();
  return (
    <StyledCustomCard
      onClick={() => history.push(routes.PROGRAMS_ADD_NEW)}
      itemAlignment="center"
    >
      <PlusActionIcon />
      <Text>ADD NEW PROGRAM</Text>
    </StyledCustomCard>
  );
}

const StyledCustomCard = styled(CardCustom)<any>`
  :hover {
    border: 1px solid transparent;
    box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.secondary};
  }
  &:hover circle {
    fill: ${(props) => props.theme.colors.secondary};
  }

  &:hover path {
    fill: white;
  }
`;

const Text = styled.span`
  color: ${(props) => props.theme.colors.secondary};
  margin-top: 16px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`;
