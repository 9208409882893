import React from "react";
import styled from "styled-components";

import { Notification } from "store/reducers/notifications/notifications.types";
import { CardNotification } from "components";
import { useTypedSelector } from "store";

export default function DashboardNotifications() {
  const { notifications } = useTypedSelector((state) => state.notifications);
  return (
    <Wrapper>
      {notifications.map(
        ({ title, description, status, createdAt }: Notification) => {
          return (
            <StyledCardNotification
              title={title}
              description={description}
              status={status}
              createdAt={createdAt}
              key={title}
            />
          );
        }
      )}
    </Wrapper>
  );
}

const StyledCardNotification = styled(CardNotification)`
  margin-top: 24px;
  @media ${(props) => props.theme.device.mobile} {
    margin-right: 24px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;

  @media ${(props) => props.theme.device.mobile} {
    flex-direction: row;
  }
`;
