import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import IsoIcon from "@mui/icons-material/Iso";
import OverlayRightPanel from "containers/OverlayRightPanel/OverlayRightPanel.container";
import styled from "styled-components";
import { Table } from "components";
import Checkbox from "components/Forms/Checkbox.component";
import { standardSubPropertiesObject } from "store/reducers/products/subproperties/pl-subproperties.types";
import colors from "../../../../../theme/colors";
import { useDispatch } from "react-redux";
import {
  addStandardSubPropertyPL,
  removeStandardSubPropertyPL,
} from "store/reducers/products/subproperties/pl-subproperties.actions";
import { useParentPropertyContext } from "./ParentPropertyContext";
import { DomainEnum } from "../../../../../store/reducers/products/properties/pl-properties.types";

export default function AddStandardSubPropertyButton({
  standardProperties,
}: {
  standardProperties: any;
}) {
  const [open, setOpen] = useState(false);
  const {
    parentId,
    parentPropertyName,
    parentPropertyRulesets,
    parentPropertyTags,
    parentDataType,
    parentDomain,
  } = useParentPropertyContext();
  const dispatch = useDispatch();

  const handlePress = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ruleSetIds = parentPropertyRulesets?.map((ruleSet) => {
    return ruleSet.id;
  });

  //Return a list of the any UI tag IDs from the parent property
  const uiTagIds = parentPropertyTags
    ?.filter((tag) => {
      return tag.category === "UI_LOCATION";
    })
    .map((tag) => {
      return tag.id;
    });

  const checkIfPropertyIsAdded = (standardSubPropertyName: string) => {
    return (
      Object.keys(standardProperties).some((property) => {
        const partialStandardSubPropertyName = standardProperties[
          property
        ].name.replace(parentPropertyName, "");

        if (partialStandardSubPropertyName === standardSubPropertyName)
          return true;
      }) || false
    );
  };

  const tableHeaderStyle = { color: colors.muttedHeadlines, height: "40px" };
  const tableRowStyle = { height: "40px" };

  const tableRows = () => {
    return Object.entries(standardSubPropertiesObject).map((key) => {
      const checked = checkIfPropertyIsAdded(key[1].name);
      const name = key[1].name;
      const disabled =
        [".MIN", ".MAX"].includes(name) && parentDomain !== DomainEnum.OPEN;

      const handleStandardPropertySelect = () => {
        if (checked) {
          dispatch(
            removeStandardSubPropertyPL({
              standardSubPropertyName: parentPropertyName + name,
              parentId,
            })
          );
        } else {
          dispatch(
            addStandardSubPropertyPL({
              standardSubPropertyName: name,
              parentPropertyName,
              parentId,
              ruleSetIds,
              ruleSetsObj: parentPropertyRulesets,
              tagIds: uiTagIds,
              parentDataType,
              parentDomain,
            })
          );
        }
      };

      return [
        {
          content: name,
          tableRowStyle,
          key: "contentName" + name,
        },
        {
          content: (
            <Checkbox
              checked={checked}
              key={"checkBox" + name}
              disabled={disabled}
              onClick={handleStandardPropertySelect}
            />
          ),
          tableRowStyle,

          key: "checkBox" + name,
        },
      ];
    });
  };
  const tableData = () => {
    return {
      header: [
        { content: "Name", style: tableHeaderStyle, id: "headerCol1" },
        { content: "Used", style: tableHeaderStyle, id: "headerCol2" },
      ],
      rows: tableRows(),
    };
  };

  return (
    <>
      <OverlayRightPanel
        open={open}
        closeNav={handleClose}
        title="Add standard sub property"
      >
        <Wrapper>
          <Table data={tableData()} size="small" />
        </Wrapper>
      </OverlayRightPanel>
      <Tooltip title="Add / Delete Standard Subproperties" arrow>
        <IconButton
          size="medium"
          color="primary"
          aria-label="clone property"
          onClick={handlePress}
        >
          <IsoIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  );
}

const Wrapper = styled.div`
  padding: 0 54px;
`;
