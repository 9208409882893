import { api } from "../../../../api/api";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AsyncSelectDropdown from "../../../../components/Forms/SelectDropdown/AsyncSelectDropown.component";
import { useRulesTableContext } from "../../pages/ProductDetail/ProductLineRulesTables/RulesTableContext";

export default function PropertySearchDropdown({
  placeholder,
  multiselect,
  width = 250,
  onChange,
  label,
  className,
  disabled = false,
  rulesetFilter = [],
  size = "large",
  includePVAProperties = false,
  includeSubProperties = true,
}: {
  placeholder: string;
  name: string;
  multiselect: boolean;
  width?: number;
  onChange: (e: any) => void;
  label?: string;
  className?: string;
  disabled?: boolean;
  rulesetFilter?: string[];
  size?: "small" | "large";
  includePVAProperties?: boolean;
  includeSubProperties?: boolean;
}) {
  const { productLineId } = useParams<{ productLineId: string }>();
  const [timer, setTimer] = useState<any>();

  const { id: rulesTableId } = useRulesTableContext();

  const getPropertyOptions = async (nameQuery) => {
    const res = await api.getProperties(
      productLineId,
      nameQuery,
      "",
      "",
      rulesetFilter,
      "",
      includePVAProperties,
      includeSubProperties,
      50,
      0,
      true,
      true
    );

    return res.data.properties.map((property) => ({
      value: property.id,
      label: property.name,
    }));
  };

  const promiseOptions = (query: string) =>
    new Promise<any>((resolve) => {
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          resolve(getPropertyOptions(query));
        }, 500)
      );
    });

  return (
    <AsyncSelectDropdown
      key={`${rulesTableId}-${JSON.stringify(rulesetFilter)}`}
      placeholder={placeholder}
      multiselect={multiselect}
      onChange={onChange}
      loadOptions={promiseOptions}
      width={width}
      label={label}
      className={className}
      disabled={disabled}
      size={size}
    />
  );
}
