export interface Document {
  published?: any;
  content?: string;
  category: string;
}

export const supportingDocs: Document[] = [
  {
    published: 1614681514000,
    content:
      "CONTROLS 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large Rooftop Units with ComfortLink",
    category: "controls",
  },
  {
    published: 1614681514000,
    content:
      "CONTROLS 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large Rooftop Units with ComfortLink",
    category: "controls",
  },
  {
    published: 1614681514000,
    content:
      "CONTROLS 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large Rooftop Units with ComfortLink",
    category: "controls",
  },
  {
    published: 1614681514000,
    content:
      "CAD TEMPLATES 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large Rooftop Units with ComfortLink Version 10.X Controls",
    category: "cad-templates",
  },
  {
    published: 1614681514000,
    content:
      "CAD TEMPLATES 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large Rooftop Units with ComfortLink Version 10.X Controls",
    category: "cad-templates",
  },
  {
    published: 1614681514000,
    content:
      "CAD TEMPLATES 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large Rooftop Units with",
    category: "cad-templates",
  },
  {
    published: 1614681514000,
    content:
      "CAD TEMPLATES 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large Rooftop Units",
    category: "cad-templates",
  },
  {
    published: 1614681514000,
    content:
      "CAD TEMPLATES 48/50A 020-060 Start-Up, Operation, Service and Controls ",
    category: "cad-templates",
  },
  {
    published: 1614681514000,
    content:
      "CAD TEMPLATES 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large Rooftop",
    category: "cad-templates",
  },
  {
    published: 1614681514000,
    content:
      "CAD TEMPLATES 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker®",
    category: "cad-templates",
  },
  {
    published: 1614681514000,
    content:
      "BROCHURE 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large",
    category: "brochure",
  },
  {
    published: 1614681514000,
    content:
      "ACCESORY AND KIT DATA 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large Rooftop Units with",
    category: "accessory-and-kit-data",
  },
  {
    published: 1614681514000,
    content:
      "ACCESORY AND KIT DATA 48/50A 020-060 Start-Up, Operation, Service and Controls Troubleshooting for Weathermaker® Single Package Large Rooftop Units with",
    category: "accessory-and-kit-data",
  },
];
