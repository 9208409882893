import { useEffect, useState } from "react";
import { findRulesetIntersectionInProperties } from "../../pages/Products/components/RulesTable/helpers";
import {
  ErrorObject,
  ValidatorNames,
} from "../../store/reducers/products/validation/validation.types";

export default function useValidateRuletableRulesets({
  properties,
}: {
  properties: any;
}) {
  const [errorMessage, setErrorMessage] = useState<
    Pick<ErrorObject, "message" | "validatorName">
  >({
    message: null,
    validatorName: ValidatorNames.WrongRulesetsInRuleTableProperties,
  });

  useEffect(() => {
    if (properties === null) {
      setErrorMessage({
        message: null,
        validatorName: ValidatorNames.WrongRulesetsInRuleTableProperties,
      });
      return;
    }
    if (
      !findRulesetIntersectionInProperties(properties).length &&
      properties.length
    ) {
      setErrorMessage({
        message: "no intersection found for properties rulesets",
        validatorName: ValidatorNames.WrongRulesetsInRuleTableProperties,
      });
      return;
    }
    setErrorMessage({
      message: null,
      validatorName: ValidatorNames.WrongRulesetsInRuleTableProperties,
    });
  }, [properties]);

  return errorMessage;
}
