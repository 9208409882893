import React from "react";
import styled from "styled-components";
import { H4 } from "components/Typography/Headlines.component";
import colors from "theme/colors";

export default function SectionTitle({ children }: { children?: any }) {
  return <CustomH4 bold>{children}</CustomH4>;
}

const CustomH4 = styled(H4)`
  margin-top: 0px;
  margin-bottom: 16px;
  line-height: 28px;
  color: ${colors.muttedHeadlines}}
`;
