import React from "react";
import styled from "styled-components";
import { H4 } from "components";
import { Close } from "assets/logos/icons/MicroIcons";
import colors from "theme/colors";

export default function OverlayRightPanel({
  open,
  closeNav,
  title,
  children,
}: {
  open: boolean;
  closeNav: () => void;
  title?: string;
  children?: any;
}) {
  return (
    <Background open={open} onClick={closeNav}>
      <Wrapper open={open} onClick={(e) => e.stopPropagation()}>
        <TopRow>
          <StyledH4>{title}</StyledH4>
          <CloseButton onClick={closeNav}>
            <Close color={colors.muttedHeadlines} />
          </CloseButton>
        </TopRow>
        <ChildrenWrapper open={open}>{children}</ChildrenWrapper>
      </Wrapper>
    </Background>
  );
}

const ChildrenWrapper = styled.div<any>`
  position: relative;
  left: ${(props) => (props.open ? "0px" : "400px")};
  transition: left 0.3s;

  /* Hack for smooth transition (equivalent to width: 100%) */
  max-width: 600px;
`;

const StyledH4 = styled(H4)`
  color: ${(props) => props.theme.colors.primary};
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 0px;
  overflow: hidden;
  white-space: nowrap;
`;

const Background = styled.div<any>`
  height: 100vh;
  width: ${(props) => (props.open ? "100vw" : "0vw")};
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
`;

const Wrapper = styled.div<any>`
  box-sizing: border-box;
  height: 100%; /* 100% Full-height */
  top: 0; /* Stay at the top */
  width: ${(props) =>
    props.open ? "350px" : "0"}; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  right: 0;
  background-color: white; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  z-index: 99999 + 1;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 36px;
  margin-left: 50px;
  cursor: pointer;
  margin-right: 30px;

  :hover {
    opacity: 0.7;
  }
`;
