import React from "react";
import styled from "styled-components";
import { ErrorObject } from "../../store/reducers/products/validation/validation.types";

export default function ErrorMessage({
  errors,
}: {
  errors: Partial<ErrorObject>[];
}) {
  return (
    <ErrorWrapper>
      {errors.map((error) => (
        <Error key={error.id}>{error.message}</Error>
      ))}
    </ErrorWrapper>
  );
}

const ErrorWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
`;

const Error = styled.div<any>`
  color: ${(props) => props.theme.colors.error};
`;
