export const getEnumPropertiesOptions = (typescriptEnum) => {
  return Object.entries(typescriptEnum).map((elem) => {
    return { value: elem[1], name: elem[1] };
  });
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
