import {
  SET_PROGRAMS,
  SET_NEW_PROGRAM,
  SET_SELECTED_PROGRAM,
  ADD_NEW_TASK,
  EDIT_TASK,
} from "./programs.actions";
import { Program, TasksState } from "./programs.types";
import { mockedPrograms } from "./programs.mocks";
import { mockedTasks } from "../mocks/tasks";
import { editTaskStateById } from "../../helpers";

export interface ProgramReducerState {
  programs: Program[];
  newProgram: Partial<Program>;
  // SelectedProgram is used by the header to display the name,
  // shall not be used to contain details about a selected program
  // use .selectors for that
  selectedProgram?: Program;
  selectedProgramTasks: TasksState;
}

const initialState: ProgramReducerState = {
  programs: mockedPrograms,
  newProgram: {
    name: "",
  },
  selectedProgram: undefined,
  selectedProgramTasks: mockedTasks,
};

export default function programsReducer(
  state: ProgramReducerState = initialState,
  action: any
): ProgramReducerState {
  switch (action.type) {
    case SET_PROGRAMS: {
      return {
        ...state,
        programs: action.payload,
      };
    }

    case SET_NEW_PROGRAM: {
      return {
        ...state,
        newProgram: {
          ...state.newProgram,
          ...action.payload,
        },
      };
    }

    case SET_SELECTED_PROGRAM: {
      return {
        ...state,
        selectedProgram: action.payload,
      };
    }
    case EDIT_TASK: {
      const newObj = editTaskStateById(
        action.payload,
        state.selectedProgramTasks
      );
      return {
        ...state,
        selectedProgramTasks: newObj,
      };
    }
    case ADD_NEW_TASK: {
      return {
        ...state,
        selectedProgramTasks: {
          ...state.selectedProgramTasks,
          byId: {
            ...state.selectedProgramTasks.byId,
            [action.payload.byId.id]: action.payload.byId,
          },
          allIds: [
            ...state.selectedProgramTasks.allIds,
            action.payload.allIds.id,
          ],
        },
      };
    }

    default:
      return state;
  }
}
