import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { useHistory } from "react-router-dom";

import Header from "./Header";
import SideMenu from "./SideMenu/SideMenu";
import "./transitions.css";
import { useTypedSelector } from "store";

export default function Layout(props: any) {
  const nodeRef = useRef(null);
  const [inProp, setInProp] = useState(false);
  const { navigationOpen } = useTypedSelector(
    (state) => state.user.preferences
  );
  const history = useHistory();

  useEffect(() => {
    setInProp(true);

    history.listen(() => {
      // If the page has inside routing, it shall not trigger the transition effect
      const hasNestedRouting = history.location.pathname.includes("products/");
      if (hasNestedRouting) return;

      setInProp(false);

      // This is a failsafe if onExited doesn t trigger for any reason
      setTimeout(() => {
        setInProp(true);
      }, 2000);
    });

    return () => {
      setInProp(false);
    };
  }, []);

  const { children } = props;
  return (
    <Container>
      <RowContainer>
        <SideMenu />
        <Main navigationOpen={navigationOpen}>
          <Header />
          <CSSTransition
            nodeRef={nodeRef}
            in={inProp}
            timeout={200}
            classNames="my-node"
            onExited={() => setInProp(true)}
            style={{ height: "100%" }}
          >
            <div
              ref={nodeRef}
              style={{
                opacity: inProp ? "block" : "none",
                // TODO: I don't see this margin-bottom applied, check why
                marginBottom: 56,
                minHeight: "100%",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              {children}
            </div>
          </CSSTransition>
        </Main>
      </RowContainer>
    </Container>
  );
}

const RowContainer = styled.div`
  display: flex;
  flex-grow: 1;
  //padding-top: 10vh;
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;

  padding: 0;
  box-sizing: border-box;
  border: none;
  background-color: white;
`;

const Main = styled.main<any>`
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding-left: 56px;
  @media ${(props) => props.theme.device.mobile} {
    padding-left: ${(props) => (props.navigationOpen ? "210px" : "56px")};
  }
  transition: padding 0.5s;

  padding-top: 0;

  background-color: ${(props) => props.theme.colors.greyBackground};
  // margin-bottom: 56px;
`;
