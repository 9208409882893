import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Table } from "components";
import { Alert } from "@mui/material";
import { getPropertiesData, getTableData } from "./helpers";
import { useDispatch } from "react-redux";
import RulesTableAddProperty from "./RulesTableAddProperty.component";
import {
  addNewPropertyPvaToRuleTableAction,
  addNewPropertyToRuleTableAction,
  addPropertyRowToRuleTable,
  deleteRulesTable,
  deleteRuleTableRow,
  editRuleTableAction,
  reOrderRuleTableAction,
} from "store/reducers/products/rulestables/pl-rulestables.actions";
import { useTypedSelector } from "store";
import { CommentsAndSources } from "../CommentsAndSources.component";
import { RulesTableContext } from "../../pages/ProductDetail/ProductLineRulesTables/RulesTableContext";
import Validator from "../../../../components/Validator/Validator.component";
import { ObjectAssignedEnum } from "../../../../store/reducers/products/validation/validation.types";
import Grid from "@mui/material/Unstable_Grid2";

import ConfirmActionButton from "components/ConfirmActionModal/ConfirmActionButton.component";
import { BatchEditButton } from "./BatchEditButton.component";
import { AddMultipleRowsButton } from "./AddMultipleRowsButton.component";
import ExportRuleTableToTSVButton from "./ExportRuleTableToTSVButton";

export default function RulesTable({
  id,
  ruleTableData,
  hideCommentsAndSources = false,
}: {
  id: string;
  ruleTableData: any;
  hideCommentsAndSources?: boolean;
}) {
  const [newPropertyId, setNewPropertyId] = useState("");
  const [newPropertyPvaId, setNewPropertyPvaId] = useState("");

  useEffect(() => {
    setNewPropertyPvaId("");
  }, [ruleTableData.tableType]);

  const ruleSets = ruleTableData.rulesets;
  const ruleSetsList =
    useTypedSelector((state) => state.products?.parameters.ruleSets) || [];
  const data = getTableData(ruleSets, ruleTableData, ruleSetsList);
  const propertiesData = getPropertiesData(ruleTableData);
  const dispatch = useDispatch();

  const PropertiesOptions = {
    canAddRow: !!ruleTableData.properties.length,
    addRowAction: () => {
      dispatch(addPropertyRowToRuleTable(ruleTableData));
    },
    addRowContent: [],
    show: true,
    rowDeleteAction: (row: any) =>
      dispatch(deleteRuleTableRow(ruleTableData, row[0].rowId)),
  };

  const addNewProperty = () => {
    if (newPropertyPvaId) {
      dispatch(
        addNewPropertyPvaToRuleTableAction(id, newPropertyId, newPropertyPvaId)
      );
      return;
    }
    dispatch(addNewPropertyToRuleTableAction(id, [newPropertyId]));
  };

  const handleDeleteTableOnClick = (elId) => {
    dispatch(deleteRulesTable(elId));
  };

  const reOrderProperties = (items: any) => {
    const newItems = items
      .map((item: any) => item.id)
      .filter((item) => item !== "NewColProps");

    dispatch(
      editRuleTableAction({
        ruleTableId: id,
        ruleTable: { propertyIds: newItems },
      })
    );
  };

  const reOrderRows = (
    items: any,
    target,
    destinationPosition,
    sourcePosition
  ) => {
    dispatch(
      reOrderRuleTableAction({
        ruleTableId: id,
        targetPropertyId: target,
        destinationPosition,
        sourcePosition,
      })
    );
  };

  return (
    <Wrapper>
      <RulesTableContext.Provider key={id} value={ruleTableData}>
        <Validator
          objectType={ObjectAssignedEnum.RULETABLE}
          objectData={ruleTableData}
          validations={ruleTableData.validations}
        />

        {ruleTableData.ownerTool && (
          <Alert severity="warning">
            This table is owned by Tool &quot;{ruleTableData.ownerTool}
            &quot;. Any edits may be overwritten.
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid xs="auto">
            <Table data={data} size={"small"} />
          </Grid>
          <Grid xs container direction="column" spacing={1}>
            <Grid>
              <ExportRuleTableToTSVButton ruleTableId={id} />
            </Grid>
            <Grid>
              <BatchEditButton ruletableData={ruleTableData} />
            </Grid>
          </Grid>
          <Grid xsOffset="auto">
            <ConfirmActionButton
              message={"Are you sure you want to delete this table?"}
              action={() => handleDeleteTableOnClick(id)}
              buttonType={"button"}
            />
          </Grid>
        </Grid>
        <BottomBox>
          <StyledTable
            data={propertiesData}
            {...PropertiesOptions}
            size={"small"}
            tableWidth={`${propertiesData?.header?.length * 3}px`}
            fixHeaderWidth={true}
            onDragEndActionHeader={reOrderProperties}
            onDragEndActionRows={reOrderRows}
          />
          <RulesTableAddProperty
            newPropertyId={newPropertyId}
            setNewPropertyId={setNewPropertyId}
            newPropertyPvaId={newPropertyPvaId}
            setNewPropertyPvaId={setNewPropertyPvaId}
            addNewProperty={addNewProperty}
            ruleTableType={ruleTableData.tableType}
          />
        </BottomBox>
        <AddMultipleRowsButton ruletableData={ruleTableData} />
        {!hideCommentsAndSources && <CommentsAndSources data={ruleTableData} />}
      </RulesTableContext.Provider>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled(Table)`
  > table {
    border: none;
  }
  > table > tbody > tr > td {
    min-width: 180px !important;
    max-width: 180px;
  }
`;

const BottomBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 16px;
`;
