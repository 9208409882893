import React from "react";
import styled from "styled-components";
import { useTypedSelector } from "store";

export default function ComponentsLibraryPage() {
  const { navigationOpen } = useTypedSelector(
    (state) => state.user.preferences
  );
  return (
    <Wrapper navigationOpen={navigationOpen}>
      You are on Components Library page
    </Wrapper>
  );
}

const Wrapper = styled.div<any>`
  padding-left: 10px;
  padding-top: 20px;

  @media ${(props) => props.theme.device.mobile} {
    padding-left: ${(props) => (props.navigationOpen ? "50px" : "10px")};
  }
`;
