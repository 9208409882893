import React from "react";
import styled from "styled-components";
import { User } from "./User.component";
import { PaperPlane, Phone } from "../../assets/logos/icons/MediumIcons";
import { Size } from "./sizes";
import colors from "../../theme/colors";

export function UserCard({
  firstName = "",
  lastName = "",
  size = "normal",
  id = "",
  role = "-",
  color,
  className,
}: {
  firstName?: string;
  lastName?: string;
  profilePicture?: string;
  id?: string;
  size?: Size;
  role?: string;
  color?: string;
  className?: any;
}) {
  return (
    <Wrapper className={className}>
      <User
        firstName={firstName}
        lastName={lastName}
        size={size}
        role={role}
        color={color}
        hideRole={false}
        id={id}
      />
      <ButtonsWrapper>
        <PaperPlane color={colors.muttedHeadlines} />
        <Divider />
        <Phone color={colors.muttedHeadlines} />
      </ButtonsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  border: 1px solid ${colors.tables.borders};
  width: 300px;
  height: 64px;
  border-radius: 4px;
`;

const Divider = styled.span<any>`
  width: 0;
  height: 39px;
  padding: 0;
  margin: 0;
  border-left: 1px solid ${colors.tables.borders};
`;

const ButtonsWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
`;

export default UserCard;
