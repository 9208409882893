import { setRestVerb } from "store/reducers/generalActions";
import { api } from "api/api";
import { RulesTable } from "./pl-rulestables.types";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import { ObjectAssignedEnum } from "../validation/validation.types";
import { reorder } from "components/Table/helper";

export const SET_NEW_RULES_TABLE = "products/plRulesTables/SET_NEW_RULES_TABLE";
export const ADD_NEW_RULES_TABLE = "products/plRulesTables/ADD_NEW_RULES_TABLE";
export const DELETE_RULES_TABLE = "products/plRulesTables/DELETE_RULES_TABLE";
export const SET_PL_RULES_TABLES = "products/plRulesTables/SET_PL_RULES_TABLES";
export const SET_PL_RULES_TABLES_DETAILS =
  "products/plRulesTables/SET_PL_RULES_TABLES_DETAILS";
export const EDIT_PL_RULETABLE = "products/plRulesTables/EDIT_PL_RULETABLE";
export const SET_PL_RULE_TABLE = "products/plRulesTables/SET_PL_RULE_TABLE";
export const DELETE_RULE_TABLE_ROW =
  "products/plRulesTables/DELETE_RULE_TABLE_ROW";
export const ADD_NEW_PROPERTY = "products/plRulesTables/ADD_NEW_PROPERTY";
export const SET_VERB = "products/plRulesTables/REST_VERB";
export const SET_RULES_TABLES_INITIAL_STATE =
  "products/plRulesTables/SET_RULES_TABLES_INITIAL_STATE";
export const GET_RULETABLE_VALIDATION_BY_OBJECT_ID =
  "products/plRulesTables/GET_RULETABLE_VALIDATION_BY_OBJECT_ID";
export const SET_PL_RULES_TABLES_PAGINATION =
  "product/plRulesTables/SET_PL_RULES_TABLES_PAGINATION";

//sets the number of rules tables returned from getRulesTables api and how many are
//displayed on one page of the UI.
export const RULES_TABLES_ITEMS_PER_PAGE = 5;

export const setNewRulesTable = (newRulesTable: RulesTable) => {
  return {
    type: SET_NEW_RULES_TABLE,
    payload: newRulesTable,
  };
};

export const addRuleTableToProductLineAction = (rulestable: RulesTable) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plRulesTablesGetting", true, "posting"));

    try {
      const { rulestables, selectedProductLine } = getState().products;

      const { data } = await api.createProductLineRulesTable(
        rulestable,
        selectedProductLine.id
      );
      const newById = { [data.id]: data, ...rulestables.byId };
      const newAllIds = rulestables.allIds.concat(data.id);

      dispatch({
        type: ADD_NEW_RULES_TABLE,
        payload: {
          byId: newById,
          allIds: newAllIds,
          total: rulestables.total,
        },
      });
      setTimeout(
        () =>
          window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: "smooth",
          }),
        300
      );
    } catch (e) {
      console.error(e, "error while adding a rule table to product line");
    }
    dispatch(setRestVerb("products/plRulesTablesGetting", false, "posting"));
  };
};

export const deleteRulesTable = (rulestableId) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plRulesTablesDeleting", true, "deleting"));

    try {
      const { selectedProductLine } = getState().products;
      const response = await api.deleteRuleTable(
        selectedProductLine.id,
        rulestableId
      );
      if (response && response.status === 204) {
        dispatch({
          type: DELETE_RULES_TABLE,
          payload: { id: rulestableId },
        });
      }
    } catch (e) {
      console.error(e, "error while deleting rule table");
    }
    dispatch(setRestVerb("products/plRulesTablesDeleting", false, "deleting"));
  };
};

export const getRulesTablesAction = ({
  tableTypes,
  rulesetIds,
  propertyIds,
  productLineId,
  items = RULES_TABLES_ITEMS_PER_PAGE,
  page = 0,
}: {
  tableTypes?: any;
  rulesetIds?: any;
  propertyIds?: any;
  productLineId: string;
  items?: number;
  page?: number;
}) => {
  return async (dispatch) => {
    dispatch(setRestVerb("products/plRulesTables", true, "getting"));
    try {
      const { data } = await api.getRulesTables(
        productLineId,
        items,
        page,
        tableTypes,
        rulesetIds,
        propertyIds
      );
      const newAllIds: Array<any> = [];
      const newById = {};

      if (!data.ruleTables) return;
      data.ruleTables.map((rulesTable) => {
        newAllIds.push(rulesTable.id);
        newById[rulesTable.id] = rulesTable;
      });

      dispatch({
        type: SET_PL_RULES_TABLES,
        payload: {
          byId: newById,
          allIds: newAllIds,
          page,
          total: data.total,
        },
      });
    } catch (e) {
      console.error(e, "error while getting all rules tables to product line");
    }
    dispatch(setRestVerb("products/plRulesTables", false, "getting"));
  };
};

export const getRuleTableAction = ({ tableId }: { tableId: string }) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plRulesTables", true, "getting"));

    const { id: productLineId } = getState().products.selectedProductLine;
    const { rulestables } = getState().products;

    try {
      const { data } = await api.getRuleTable(productLineId, tableId);

      const newById = { ...rulestables.byId, [data.id]: data };
      const newAllIds = rulestables.allIds.concat(data.id);

      dispatch({
        type: ADD_NEW_RULES_TABLE,
        payload: {
          byId: newById,
          allIds: newAllIds,
          total: rulestables.total,
        },
      });
    } catch (e) {
      console.error(e, "error while getting ruletable");
    }
    dispatch(setRestVerb("products/plRulesTables", false, "getting"));
  };
};

export const reOrderRuleTableAction = ({
  ruleTableId,
  targetPropertyId,
  destinationPosition,
  sourcePosition,
}) => {
  return async (dispatch, getState) => {
    const { selectedProductLine, rulestables } = getState().products;
    const ruleTable = { ...rulestables.byId[ruleTableId] };
    const { rows } = ruleTable;
    ruleTable.rows = reorder(rows, sourcePosition, destinationPosition);

    try {
      const newByIdOptimistic = {
        ...rulestables.byId,
        [ruleTableId]: ruleTable,
      };
      dispatch({
        type: EDIT_PL_RULETABLE,
        payload: newByIdOptimistic,
      });
      await api.reOrderProductLineRulesTableRows(
        ruleTableId,
        selectedProductLine.id,
        targetPropertyId,
        destinationPosition
      );
    } catch (e) {
      // optimistic rollback
      dispatch({
        type: EDIT_PL_RULETABLE,
        payload: { ...rulestables.byId },
      });

      console.error(e);
    }
  };
};

export const editRuleTableAction = ({
  ruleTableId,
  ruleTable,
}: {
  ruleTableId: string;
  ruleTable: any;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plRuleTables", true, "posting"));
    const { selectedProductLine, rulestables } = getState().products;
    try {
      if (Object.keys(ruleTable)[0] === "lookUpFieldIndex") {
        const columns = rulestables.byId[ruleTableId].properties.length;
        const lookupIndex = ruleTable["lookUpFieldIndex"];

        if (lookupIndex > columns) {
          toast.error(
            "The number of Lookup Properties can't be greater then the number of properties in the rules table."
          );
          return;
        }

        if (lookupIndex < 1) {
          toast.error("The number of Lookup Properties can't be less then 1.");
          return;
        }
      }

      if ("rulesetIds" in ruleTable && !ruleTable.rulesetIds.length) throw null;

      if (
        ruleTable.tableType &&
        ["ATTR_LOOKUP", "LOOKUP"].includes(ruleTable.tableType)
      ) {
        ruleTable.lookUpFieldIndex = "1";
      }

      if (!ruleTableId) return;
      const res = await api.editProductLineRulesTable(
        ruleTableId,
        selectedProductLine.id,
        ruleTable
      );

      if (!res) throw null;
      const { data } = res;

      const newByIdRealistic = { ...rulestables.byId, [data.id]: data };

      dispatch({
        type: EDIT_PL_RULETABLE,
        payload: newByIdRealistic,
      });
    } catch (e) {
      console.error(e, "error while editing a rule table of a product line");

      // rollback if it fails
      dispatch({
        type: EDIT_PL_RULETABLE,
        payload: { ...rulestables.byId },
      });
    }
    dispatch(setRestVerb("products/plRulesTables", false, "posting"));
  };
};

export const editRuleTableCellAction = ({
  ruleTableId,
  rowId,
  cellId,
  value,
}: {
  ruleTableId: string;
  rowId: string;
  cellId: string;
  value: any;
}) => {
  return async (dispatch, getState) => {
    try {
      const { selectedProductLine, rulestables } = getState().products;

      const ruleTable = { ...rulestables.byId[ruleTableId] };

      const { data }: any = await api.editRuleTableCell(
        selectedProductLine.id,
        ruleTableId,
        rowId,
        cellId,
        value
      );
      const newRuleTable = {
        ...ruleTable,
        rows: ruleTable.rows.map((row) => {
          if (row.id !== rowId) return row;
          return data;
        }),
      };

      dispatch({
        type: SET_PL_RULE_TABLE,
        payload: { data: newRuleTable, ruleTableId: ruleTable.id },
      });
    } catch (e) {
      console.error(e, "error while editing a ruletable cell");
    }
  };
};

export const addPropertyRowToRuleTable = (ruleTable: any, amount = 1) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plRulesTables", true, "posting"));
    try {
      const { selectedProductLine } = getState().products;
      const body = Array(amount).fill({
        cells: [],
      });

      const { data } = await api.addPropertiesRowToRuleTable(
        ruleTable.id,
        body,
        selectedProductLine.id
      );

      const newRuleTable = { ...ruleTable };
      newRuleTable.rows = newRuleTable.rows.concat(data);

      dispatch({
        type: SET_PL_RULE_TABLE,
        payload: { data: newRuleTable, ruleTableId: ruleTable.id },
      });

      toast.dismiss();
    } catch (e) {
      toast.error(e);
      console.error(e, "error while adding a new row to rule table");
    }
    dispatch(setRestVerb("products/plRulesTables", false, "posting"));
  };
};

export const deleteRuleTableRow = (ruleTable, rowId) => {
  return async (dispatch, getState) => {
    dispatch(
      setRestVerb("products/plRulesTablesRowDeleting", true, "deleting")
    );
    try {
      const { selectedProductLine } = getState().products;
      const response = await api.deleteRuleTableRow(
        selectedProductLine.id,
        ruleTable.id,
        rowId
      );
      const newRuleTable = cloneDeep(ruleTable);

      newRuleTable.rows = newRuleTable.rows.filter((row) => row.id !== rowId);

      if (response && response.status === 204) {
        dispatch({
          type: DELETE_RULE_TABLE_ROW,
          payload: { ruleTable: newRuleTable },
        });
      }
    } catch (e) {
      console.error(e, "error while deleting rule table");
    }
    dispatch(
      setRestVerb("products/plRulesTablesRowDeleting", false, "deleting")
    );
  };
};

export const addNewPropertyToRuleTableAction = (ruleTableId, properties) => {
  return async (dispatch, getState) => {
    dispatch(
      setRestVerb("products/plRulesTablesAddingProperty", true, "posting")
    );
    try {
      const { selectedProductLine } = getState().products;
      const response = await api.addNewPropertyToRuleTable(
        ruleTableId,
        properties,
        selectedProductLine.id
      );
      const { data } = response;
      dispatch({
        type: ADD_NEW_PROPERTY,
        payload: { ruleTableId: data.id, ruleTable: data },
      });
    } catch (e) {
      toast.error(e);
      console.error(e, "error while adding property rule table");
    }
    dispatch(
      setRestVerb("products/plRulesTablesAddingProperty", false, "posting")
    );
  };
};

export const addNewPropertyPvaToRuleTableAction = (
  ruleTableId,
  propertyId,
  pvaId
) => {
  return async (dispatch, getState) => {
    dispatch(
      setRestVerb("products/plRulesTablesAddingProperty", true, "posting")
    );
    try {
      const { selectedProductLine } = getState().products;
      const { data } = await api.addNewPropertyPvaToRuleTable(
        selectedProductLine.id,
        ruleTableId,
        propertyId,
        pvaId
      );

      dispatch({
        type: ADD_NEW_PROPERTY,
        payload: { ruleTableId: data.id, ruleTable: data },
      });
    } catch (e) {
      toast.error(e);
      console.error(e, "error while adding property pva to rule table");
    }
    dispatch(
      setRestVerb("products/plRulesTablesAddingProperty", false, "posting")
    );
  };
};

export const getRuleTableValidation = ({
  objectId,
  objectAssigned,
}: {
  objectId: string;
  objectAssigned: ObjectAssignedEnum;
}) => {
  return async (dispatch, getState) => {
    const selectedProductLineId = getState().products?.selectedProductLine?.id;

    try {
      const { data } = await api.getValidation(
        selectedProductLineId,
        objectId,
        objectAssigned
      );

      dispatch({
        type: GET_RULETABLE_VALIDATION_BY_OBJECT_ID,
        payload: { validation: data, objectId },
      });
    } catch (e) {
      console.error(e, "error while getting validation for specific object");
    }
  };
};

export const removePropertyFromRuleTable = (ruleTableId, propertyId) => {
  return async (dispatch, getState) => {
    try {
      const { selectedProductLine, rulestables } = getState().products;
      await api.removePropertyFromRuleTable(
        selectedProductLine.id,
        ruleTableId,
        [propertyId]
      );

      const newRuleTable = { ...rulestables.byId[ruleTableId] };
      const newProperties = newRuleTable.properties.filter(
        (property) => property.id !== propertyId
      );
      const newRows = newRuleTable.rows.map((row) => {
        return {
          ...row,
          cells: row.cells.filter((cell) => cell.propertyId !== propertyId),
        };
      });

      newRuleTable.properties = newProperties;
      newRuleTable.rows = newRows;

      dispatch({
        type: SET_PL_RULE_TABLE,
        payload: { data: newRuleTable, ruleTableId },
      });
    } catch (e) {
      toast.error(e);
      console.error(e, "error while deleting a property from a rule table");
    }
  };
};

export const getNextRulesTablesPage = (page: number) => {
  return async (dispatch) => {
    dispatch({ type: SET_PL_RULES_TABLES_PAGINATION, payload: { page } });
  };
};

export const setRulesTablesInitialState = () => {
  return {
    type: SET_RULES_TABLES_INITIAL_STATE,
  };
};
