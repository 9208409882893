import {
  ErrorObject,
  ObjectAssignedEnum,
} from "../../store/reducers/products/validation/validation.types";
import {
  addValidation,
  deleteValidation,
} from "../../store/reducers/products/validation/validation.action";
import { Dispatch } from "@reduxjs/toolkit";

const matchErrorValidatorNames = (
  validatorName: string,
  currentObjectValidation: ErrorObject[]
) => {
  return currentObjectValidation.some(
    (el) => el.validatorName === validatorName
  );
};

export function validationDispatcher(
  dispatch: Dispatch<any>,
  objectAssigned: ObjectAssignedEnum,
  objectId: string,
  appErrorObjects: Pick<ErrorObject, "message" | "validatorName">[],
  currentObjectValidation: ErrorObject[]
) {
  appErrorObjects.forEach((errorObject) => {
    // error in frontend
    if (errorObject.message) {
      // error in frontend and validation endpoint
      if (
        errorObject.validatorName &&
        matchErrorValidatorNames(
          errorObject.validatorName,
          currentObjectValidation
        )
      ) {
        return;
      }
      // error in frontend and not in validation
      dispatch(
        addValidation({
          objectAssigned: objectAssigned,
          objectId,
          validatorName: errorObject.validatorName,
          message: errorObject.message,
        })
      );
      return;
    }

    // error not in fronted but present in validation endpoint
    if (
      errorObject.validatorName &&
      matchErrorValidatorNames(
        errorObject.validatorName,
        currentObjectValidation
      )
    ) {
      const validationToDelete = currentObjectValidation.find(
        (validation) => validation.validatorName === errorObject.validatorName
      );
      if (validationToDelete?.id)
        dispatch(deleteValidation({ validationId: validationToDelete.id }));
      return;
    }
    //no error in frontend and in validation endpoint
    return;
  });
}
