import React from "react";
import styled from "styled-components";
import { LoaderSpinner2 } from "components";

const getTextColor = (color = "") => {
  if (color === "secondary" || color === "primary") return "white";
  return "primary";
};

export function Button({
  children,
  size = "normal",
  color = "secondary",
  disabled = false,
  type = "primary",
  width = "",
  height = "",
  className,
  onClick,
  buttonType,
  style,
  loading = false,
}: {
  children?: any;
  size?: "normal" | "small";
  color?: "secondary" | "primary" | "white" | "error";
  disabled?: boolean;
  type?: "primary" | "secondary";
  width?: string;
  height?: string;
  className?: any;
  onClick?: any;
  buttonType?: any;
  style?: any;
  loading?: boolean;
}) {
  if (loading)
    return (
      <LoaderWrapper size={size}>
        <LoaderSpinner2 />
      </LoaderWrapper>
    );

  return (
    <StyledButton
      size={size}
      color={color}
      textColor={getTextColor(color)}
      disabled={disabled}
      styleType={type}
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      type={buttonType}
      style={style}
    >
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button<any>`
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  background-color: ${(props) =>
    props.styleType === "primary" ? props.theme.colors[props.color] : "white"};
  border-radius: 3px;
  padding: ${(props) => (props.size === "small" ? "8px 16px" : "16px 40px")};
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "auto")};

  color: ${(props) =>
    props.styleType === "primary"
      ? props.theme.colors[props.textColor]
      : props.theme.colors[props.color]};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: ${(props) =>
    props.styleType === "primary"
      ? "none"
      : `1px solid ${props.theme.colors[props.color]}`};
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  outline: none;
  :hover {
    opacity: ${(props) => (props.styleType === "primary" ? 0.7 : 1)};
    background-color: ${(props) =>
      props.styleType === "primary"
        ? props.theme.colors[props.color]
        : props.theme.colors[props.color]};
    color: ${(props) =>
      props.styleType === "primary"
        ? props.theme.colors[props.textColor]
        : "white"};
  }

  :active {
    opacity: 0.9;
  }

  :disabled {
    :hover {
      opacity: 1;
    }
    background-color: ${(props) =>
      props.styleType === "primary" ? props.theme.colors.disabled : "white"};
    border-color: ${(props) => props.theme.colors.disabled};
    color: ${(props) =>
      props.styleType === "primary"
        ? props.theme.colors[props.textColor]
        : props.theme.colors.disabled};
    cursor: not-allowed;
  }
`;

const LoaderWrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: ${(props) => (props.size === "small" ? "8px 0px" : "16px 0px")};
`;
