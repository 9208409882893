import React from "react";
import styled from "styled-components";
import { CardTendencies } from "components/Cards/CardTendencies.component";

export default function StatisticsBox() {
  return (
    <MainBox>
      <CardTendencies date={1635033600000} title="Project Completion" />
      <CardTendencies
        value={14}
        title="Avg Tasks Per Week"
        tendency="falling"
        change={8}
      />
      <CardTendencies
        value={90}
        title="Tasks Completed"
        tendency="growing"
        change={10}
      />
      <CardTendencies
        value={135}
        title="Total Tasks"
        tendency="falling"
        change={8}
      />
      <CardTendencies value={107} title="Originally Scoped" />
      <CardTendencies
        value={28}
        title="Total Added"
        tendency="falling"
        change={10}
      />
    </MainBox>
  );
}

const MainBox = styled.div`
  width: 316px;
  margin-left: 16;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
`;
