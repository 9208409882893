import React, { useState, useRef } from "react";
import styled from "styled-components";
import { CardCustom } from "../../../components";
import { editPropertyAction } from "../../../store/reducers/products/properties/pl-properties.actions";
import { editSubPropertyAction } from "../../../store/reducers/products/subproperties/pl-subproperties.actions";
import { editRuleTableAction } from "../../../store/reducers/products/rulestables/pl-rulestables.actions";
import { Property } from "../../../store/reducers/products/properties/pl-properties.types";
import { useDispatch } from "react-redux";
import { RulesTable } from "../../../store/reducers/products/rulestables/pl-rulestables.types";
import { secureTrim } from "helpers/string";
import sanitizeHtml from "sanitize-html";

enum CommentsAndSourcesTypes {
  MAIN_PROPERTY = "MAIN_PROPERTY",
  SUB_PROPERTY = "SUB_PROPERTY",
  RULETABLE = "RULETABLE",
}

export function CommentsAndSources({
  data,
  parentId,
}: {
  data: Property | RulesTable;
  parentId?: string;
}) {
  const commentRef = useRef<any>(null);
  const sourceRef = useRef<any>(null);
  const dispatch = useDispatch();

  const [type] = useState(() => {
    if (parentId) return CommentsAndSourcesTypes.SUB_PROPERTY;
    if ("tableType" in data) return CommentsAndSourcesTypes.RULETABLE;
    return CommentsAndSourcesTypes.MAIN_PROPERTY;
  });

  if (!("id" in data) || !("id" in data) || !("id" in data)) return null;

  const updateCommentObject = {
    MAIN_PROPERTY: function () {
      const modifiedProperty = {
        ...data,
        comments: sanitizeHtml(secureTrim(commentRef?.current?.innerHTML)),
      };

      dispatch(
        editPropertyAction({
          propertyId: data.id,
          property: modifiedProperty,
          updatePayload: {
            comments: sanitizeHtml(secureTrim(commentRef.current.innerHTML)),
          },
        })
      );
    },
    SUB_PROPERTY: function () {
      if (!parentId) return;
      dispatch(
        editSubPropertyAction({
          propertyId: data.id,
          updatePayload: {
            comments: sanitizeHtml(secureTrim(commentRef.current.innerHTML)),
          },
          parentId,
        })
      );
    },

    RULETABLE: function () {
      if (!data.id) return;
      dispatch(
        editRuleTableAction({
          ruleTableId: data.id,
          ruleTable: {
            comments: sanitizeHtml(secureTrim(commentRef.current.innerHTML)),
          },
        })
      );
    },
  };

  const updateSourceObject = {
    MAIN_PROPERTY: function () {
      const modifiedProperty = {
        ...data,
        sources: sanitizeHtml(secureTrim(sourceRef?.current?.innerHTML)),
      };

      dispatch(
        editPropertyAction({
          propertyId: data.id,
          property: modifiedProperty,
          updatePayload: {
            sources: sanitizeHtml(secureTrim(sourceRef.current.innerHTML)),
          },
        })
      );
    },
    SUB_PROPERTY: function () {
      if (!parentId) return;
      dispatch(
        editSubPropertyAction({
          propertyId: data.id,
          updatePayload: {
            sources: sanitizeHtml(secureTrim(sourceRef.current.innerHTML)),
          },
          parentId,
        })
      );
    },
    RULETABLE: function () {
      if (!data.id) return;
      dispatch(
        editRuleTableAction({
          ruleTableId: data.id,
          ruleTable: {
            sources: sanitizeHtml(secureTrim(sourceRef.current.innerHTML)),
          },
        })
      );
    },
  };

  const handleUpdateComment = () => {
    updateCommentObject[type]();
  };

  const handleUpdateSource = () => {
    updateSourceObject[type]();
  };

  return (
    <Wrapper>
      <StyledLabel>Comment</StyledLabel>
      <StyledCardCustom
        contentEditable={true}
        itemAlignment="left"
        onBlur={handleUpdateComment}
        textRef={commentRef}
        dangerouslySetInnerHTML={{
          __html: data?.comments || "",
        }}
      />
      <StyledLabel>Source</StyledLabel>
      <StyledCardCustom
        contentEditable={true}
        itemAlignment="left"
        onBlur={handleUpdateSource}
        textRef={sourceRef}
        dangerouslySetInnerHTML={{
          __html: data?.sources || "",
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  max-width: 535px;
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.colors.muttedHeadlines};
`;

const StyledCardCustom = styled<any>(CardCustom)`
  width: auto;
  height: auto;
  margin-bottom: 16px;
  cursor: pointer;
`;
