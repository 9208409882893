import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Paper,
  Popover,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Modal, ModalConfirmDialog } from "components";
import ValidationModal from "./Validation/ValidationModal.component";
import { ProcessState } from "modules/constants/enums";
import { getValidation } from "../../../../../store/reducers/products/validation/validation.action";
import { api } from "../../../../../api/api";
import { useTypedSelector } from "../../../../../store";
import {
  saveJsonToFile,
  saveJsonToZipFile,
  getFilenameFromContentDisposition,
} from "../../../../../api/helpers";
import { UploadProductLineRulesZip } from "api/rulesEngine/api";
import { RulesEngineApiUrl } from "api/rulesEngine/constants";

const ExportAndUploadProductLineButton = ({
  executeOnMenuItemSelect = false,
}: {
  executeOnMenuItemSelect?: boolean;
}) => {
  //State
  const anchorRef = useRef<HTMLDivElement>(null);
  const [confirmActionOpen, setConfirmActionOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [validationModalOpen, setValidationModalOpen] = useState<boolean>(
    false
  );
  const [actionState, setActionState] = useState(ProcessState.IDLE);
  const [actionError, setActionError] = useState<any>(undefined);
  const [selectedAction, setSelectedAction] = useState<number>(() => {
    // Check localStorage for the saved value, default to 0 if not found
    const saved = localStorage.getItem("productline.selectedExportActionIndex");
    return saved === null ? 0 : parseInt(saved, 10);
  });

  //Redux Store
  const dispatch = useDispatch();
  const { selectedProductLine: productLine } = useTypedSelector(
    (state) => state.products
  );

  //Define actions for the button
  const actions: string[] = [
    "Save Rules Engine JSON",
    "Zip and Save Rules Engine JSON",
    "Upload to Sandbox Rules Engine",
    "Upload to Dev Rules Engine",
  ];

  //useEffects

  useEffect(() => {
    //persist change of button action to localstorage
    localStorage.setItem(
      "productline.selectedExportActionIndex",
      selectedAction.toString()
    );
  }, [selectedAction]);

  //Private Methods

  const getUploadEnvironmentName = (actionIndex: number) => {
    switch (actionIndex) {
      case 2:
        return "Sandbox";
      case 3:
        return "Dev";
      default:
        return "";
    }
  };

  const getStartIcon = (actionIndex: number): JSX.Element | null => {
    switch (actionIndex) {
      case 0: //Save JSON
      case 1: //Save and Zip JSON
        return <FileDownloadIcon />;
      case 2: //Upload to Sandbox
      case 3: //Upload to DEV
        return <FileUploadIcon />;
      default:
        return null;
    }
  };

  const initiateAction = async (optionIndex: number) => {
    setActionState(ProcessState.PROCESSING);

    //Check if there are any validation errors.  Do not proceed if there are
    if (!(await isValidated())) {
      setActionState(ProcessState.IDLE);
      return;
    }

    //Show a confirmation dialog for upload productline actions
    //because these are destructive.
    if ([2, 3].includes(optionIndex)) {
      setConfirmActionOpen(true);
      return;
    } else {
      //Saving files to downloads folder needs no confirmation.  Just do it!
      await doAction(optionIndex);
    }

    //Restore to idle state
    setActionState(ProcessState.IDLE);
  };

  const isValidated = async (): Promise<boolean> => {
    //Retrieve current validations
    let validations;
    await dispatch(
      getValidation((result) => {
        validations = result;
      })
    );

    //If there are validations, flag validation dialog to open
    if (validations && validations.length) {
      setValidationModalOpen(true);
      return false;
    }

    setValidationModalOpen(false);
    return true;
  };

  const doAction = async (optionIndex: number) => {
    setActionState(ProcessState.PROCESSING);

    try {
      const sortedOutput = true;
      const res = await api.exportProductLineRuleEngineJson(
        productLine?.id,
        sortedOutput
      );
      if (res?.response?.status === 400) {
        setActionError(res.response.data);
        setActionState(ProcessState.ERROR);
        return;
      }

      const filename = getFilenameFromContentDisposition(
        // it is lowercase in local and capitalized when deployed
        res.headers["content-disposition"] || res.headers["Content-Disposition"]
      );

      switch (optionIndex) {
        case 0: //Save rules engine JSON
          //Save to a json file
          saveJsonToFile(res.data, filename || productLine?.name);
          toast.success(
            `'${filename}' successfully saved to downloads directory`
          );
          break;

        case 1: //Zip and Save rules engine JSON
          //Save to a json file
          saveJsonToZipFile(res.data, filename || productLine?.name);
          toast.success(
            `'${filename}' successfully zipped and saved to downloads directory`
          );
          break;

        case 2: //Upload to sandbox rules engine
          await UploadProductLineRulesZip(
            RulesEngineApiUrl.SANDBOX,
            res.data,
            filename
          )
            .then((response) => {
              const msg = `'${filename}' uploaded to Sandbox Rules Engine`;
              console.log(msg, response);
              toast.success(msg);
            })
            .catch((error) => {
              const errMsg = `'${filename}' upload to Sandbox Rules Engine failed`;
              console.error(errMsg, error);
              toast.error(errMsg);
            });
          break;

        case 3: //Upload to dev rules engine
          await UploadProductLineRulesZip(
            RulesEngineApiUrl.DEV,
            res.data,
            filename
          )
            .then((response) => {
              const msg = `'${filename}' uploaded to Dev Rules Engine`;
              console.log(msg, response);
              toast.success(msg);
            })
            .catch((error) => {
              const errMsg = `'${filename}' upload to Dev Rules Engine failed`;
              console.error(errMsg, error);
              toast.error(errMsg);
            });
          break;

        default:
          console.error("Illegal option chosen: optionIndex - ", optionIndex);
          break;
      }
    } catch (e) {
      console.error(e, "Error while exporting product line");
    }

    setActionState(ProcessState.COMPLETE);
  };

  //Event Handlers

  const handleClick = () => {
    initiateAction(selectedAction);
  };

  const handleConfirmCancel = () => {
    setConfirmActionOpen(false);
    setActionState(ProcessState.IDLE);
  };

  const handleConfirmOk = () => {
    setConfirmActionOpen(false);
    doAction(selectedAction);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedAction(index);
    setMenuOpen(false);

    //Execute the click action if props tell us to do that
    if (executeOnMenuItemSelect) initiateAction(index);
  };

  const handleMenuToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        size="small"
        color="primary"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <LoadingButton
          loading={actionState === ProcessState.PROCESSING}
          loadingPosition="start"
          startIcon={getStartIcon(selectedAction)}
          onClick={handleClick}
        >
          {actions[selectedAction]}
        </LoadingButton>
        <Button size="small" onClick={handleMenuToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popover
        id="split-button-menu-popover"
        open={menuOpen}
        onClose={handleMenuClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper>
          <MenuList id="split-button-menu" variant="selectedMenu" autoFocusItem>
            {actions.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === selectedAction}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popover>
      {validationModalOpen && (
        <ValidationModal
          open={validationModalOpen}
          closeModal={() => setValidationModalOpen(false)}
          handleExport={handleClick}
        />
      )}

      <ModalConfirmDialog
        open={confirmActionOpen}
        isWarning={false}
        title="Confirm Upload?"
        content={
          <div>
            You are about to upload productline &quot;{productLine?.name}
            &quot; to the &nbsp;
            {getUploadEnvironmentName(selectedAction)} rules engine.
            <p>
              <strong>
                This will overwrite any previous version of the product line.
              </strong>
            </p>
            Do you want to continue?
          </div>
        }
        defaultButton="CANCEL"
        cancelButtonText="Cancel"
        okButtonText="OK"
        onCancelButtonClick={handleConfirmCancel}
        onOKButtonClick={handleConfirmOk}
      />
      {actionState === ProcessState.ERROR && (
        <Modal
          open={actionState === ProcessState.ERROR}
          closeModal={() => {
            setActionState(ProcessState.IDLE);
            setActionError(undefined);
          }}
          title={"Export Error"}
          dialogType="error"
        >
          {typeof actionError !== undefined && actionError}
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ExportAndUploadProductLineButton;
