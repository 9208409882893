import { createContext, useContext } from "react";

export type ConfirmActionModalContextType = {
  display: boolean;
  setDisplay: (state) => void;
  confirmAction: any;
  setConfirmAction: any;
  message: string;
  setMessage: any;
};

export const ConfirmActionContext = createContext<ConfirmActionModalContextType>(
  {
    display: false,
    setDisplay: (state) => state,
    confirmAction: () => null,
    setConfirmAction: () => null,
    message: "confirm action",
    setMessage: () => null,
  }
);

export const useConfirmActionModalContext = () =>
  useContext(ConfirmActionContext);
