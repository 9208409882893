import { User } from "store/reducers/user/user.types";

export const teamMembers: User[] = [
  {
    name: "John Doe",
    firstName: "John",
    lastName: "Doe",
    role: "Program Owner",
    id: "113e4567-e89b-12d3-a456-426614174000",
    email: "john.doe@carrier.com",
  },
  {
    name: "Maria Doe",
    firstName: "Maria",
    lastName: "Doe",
    role: "Program Owner",
    id: "123e4567-e89b-12d3-a456-226614174000",
    email: "john.doe@carrier.com",
  },
  {
    name: "Brian Beloin",
    firstName: "Brian",
    lastName: "Beloin",
    role: "Team Member",
    id: "153e4567-e89b-12d3-a456-226614144000",
    email: "john.doe@carrier.com",
  },
  {
    name: "Wojciech Nowaczyk",
    firstName: "Wojciech",
    lastName: "Nowaczyk",
    role: "Team Member",
    id: "153e4567-e89b-12d3-a6556-226614144000",
    email: "john.doe@carrier.com",
  },
  {
    name: "Sotirios Koupas",
    firstName: "Sotirios",
    lastName: "Koupas",
    role: "Team Member",
    id: "153e4567-e89b-12d3-a6556-226614434000",
    email: "john.doe@carrier.com",
  },

  {
    name: "Phillip Lawniczak",
    firstName: "Phillip",
    lastName: "Lawniczak",
    role: "Team Member",
    id: "153e4967-e89b-12d3-a6556-226614434000",
    email: "john.doe@carrier.com",
  },
  {
    name: "Michael Housey",
    firstName: "Michael",
    lastName: "Housey",
    role: "Team Member",
    id: "153e4967-e32b-12d3-a6556-226614434000",
    email: "john.doe@carrier.com",
  },
  {
    name: "Mark Adamo",
    firstName: "Mark",
    lastName: "Adamo",
    role: "Team Member",
    id: "153e4967-e52b-12d3-a6556-226614434000",
    email: "john.doe@carrier.com",
  },
  {
    name: "Mathieu Berte",
    firstName: "Mathieu",
    lastName: "Berte",
    role: "Team Member",
    id: "153e4967-e52b-12d3-a6356-226614434000",
    email: "john.doe@carrier.com",
  },
  {
    name: "Jethro Masangya",
    firstName: "Jethro",
    lastName: "Masangya",
    role: "Team Member",
    id: "153e4967-e52b-12d3-a6756-226614434000",
    email: "john.doe@carrier.com",
  },
  {
    name: "Quentin Chantelot",
    firstName: "Quentin",
    lastName: "Chantelot",
    role: "Team Member",
    id: "153e4967-e75b-12d3-a6756-226614434000",
    email: "john.doe@carrier.com",
  },
];

export const shortTeamMembers: User[] = [
  {
    name: "John Doe",
    firstName: "John",
    lastName: "Doe",
    role: "Lumberjack",
    id: "113e4567-e89b-12d3-a456-426614174000",
    email: "john.doe@carrier.com",
  },
  {
    name: "Maria Doe",
    firstName: "Maria",
    lastName: "Doe",
    role: "Lumberjack",
    id: "123e4567-e89b-12d3-a456-226614174000",
    email: "john.doe@carrier.com",
  },
];

export {};
