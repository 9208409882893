import React from "react";
import { useTypedSelector } from "store";
import { CardPrograms } from "components";
import styled from "styled-components";
import { AddNewProgram } from "components/Cards/AddNewProgram.component";
import { useHistory } from "react-router-dom";

export default function DashboardPrograms() {
  const { programs } = useTypedSelector((state) => state.programs);
  const history = useHistory();

  return (
    <Wrapper>
      {programs.map((program) => {
        return (
          <CardWrapper key={program.name}>
            <CardPrograms
              {...program}
              onClick={() => history.push(`programs/${program.id}/overview`)}
            />
          </CardWrapper>
        );
      })}
      <CardWrapper>
        <AddNewProgram />
      </CardWrapper>
    </Wrapper>
  );
}

const CardWrapper = styled.div`
  margin-top: 24px;
  @media ${(props) => props.theme.device.mobile} {
    margin-right: 24px;
  }
  max-width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  max-width: 80vw;

  @media ${(props) => props.theme.device.mobile} {
    flex-direction: row;
  }
`;
