import colors from "theme/colors";
import { teamMembers, shortTeamMembers } from "../mocks/users";
import { Program } from "./programs.types";
import { supportingDocs } from "../mocks/documents";
import { mockedTasks as tasks } from "../mocks/tasks";

export const mappedStatusToElement = {
  late: {
    label: "Late",
    color: colors.projectLate,
  },
  complete: {
    color: colors.projectComplete,
    label: "Complete",
  },
  paused: {
    color: colors.projectPaused,
    label: "Paused",
  },
  started: {
    color: colors.muttedHeadlines,
    label: "Started",
  },
  onTime: {
    color: colors.secondary,
    label: "On Time",
  },
};

const productsImpacted = [
  {
    name: "40N",
    type: "Gas heat",
    capacityRange: "20-60",
    effciency: "10.3.14.0",
  },
  {
    name: "54N",
    type: "Gas heat",
    capacityRange: "20-60",
    effciency: "10.3.14.0",
  },
  {
    name: "48A",
    type: "Gas heat",
    capacityRange: "20-60",
    effciency: "10.3.14.0",
  },
];

const partsImpacted = [
  {
    name: "Fan Belt 231",
    type: "Gas heat",
    capacityRange: "20-60",
    effciency: "10.3.14.0",
  },
  {
    name: "Cooling Suppressor",
    type: "Gas heat",
    capacityRange: "20-60",
    effciency: "10.3.14.0",
  },
];

export const mockedPrograms: Program[] = [
  {
    name: "Program Title",
    status: "onTime",
    completion: 80,
    teamMembers,
    tasksRemaining: 11,
    daysLeft: 1634169600000, //2021-10-14 0:00:00
    region: "NA",
    type: "addOption",
    id: "1",
    requirements: [
      "First custom requirement",
      "Second custom requirement",
      "Third custom requirement",
    ],
    productsImpacted,
    partsImpacted,
    supportingDocs,
    tasks,
  },
  {
    name: "Appliances",
    status: "late",
    completion: 60,
    teamMembers: shortTeamMembers,
    tasksRemaining: 1,
    daysLeft: 1635033600000, //2021-10-24 0:00:00
    region: "NA",
    type: "addOption",
    id: "2",
    requirements: [
      "First custom requirement",
      "Second custom requirement",
      "Third custom requirement",
    ],
    productsImpacted,
    partsImpacted,
    supportingDocs,
    tasks,
  },
  {
    name: "Program Title that is really too long. Can you still read it?",
    status: "complete",
    completion: 100,
    teamMembers,
    tasksRemaining: 0,
    daysLeft: 1636848000000, //2021-11-14 0:00:00
    region: "NA",
    type: "addOption",
    id: "3",
    productsImpacted,
    partsImpacted,
    supportingDocs,
    tasks,
    requirements: [
      "First custom requirement",
      "Second custom requirement",
      "Third custom requirement",
    ],
  },
  {
    name: "Siberia 212B - V2",
    status: "paused",
    completion: 20,
    teamMembers,
    tasksRemaining: 1,
    daysLeft: 1637712000000, //2021-11-24 0:00:00
    region: "NA",
    type: "addOption",
    id: "4",
    productsImpacted,
    partsImpacted,
    supportingDocs,
    tasks,
    requirements: [
      "First custom requirement",
      "Second custom requirement",
      "Third custom requirement",
    ],
  },
  {
    name: "Alaska C30",
    status: "started",
    completion: 20,
    teamMembers,
    tasksRemaining: 11,
    daysLeft: 1609459200000, //2021-01-01 0:00:00
    region: "NA",
    type: "addOption",
    id: "5",
    productsImpacted,
    partsImpacted,
    supportingDocs,
    tasks,
    requirements: [
      "First custom requirement",
      "Second custom requirement",
      "Third custom requirement",
    ],
  },
];
