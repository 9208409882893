import { Length, IsEnum } from "class-validator";

export enum ProductRegion {
  NA = "NORTH AMERICA",
  EMEA = "EMEA",
  ASIA = "ASIA",
  GLOBAL = "GLOBAL",
}

export class NewProductDto {
  @Length(1, 30)
  name: string;

  @IsEnum(ProductRegion, { each: true })
  region: ProductRegion;

  //MRU: 2023-09-18: Made productline image optional for now.
  //@IsDefined()
  displayImage: Blob;
}

export class Product extends NewProductDto {
  updatedAt?: string;
  createdAt?: string;
  description?: string;
  img?: string;
  defaultRuleset: string;

  id: string;
}

export const productRegionOptions: {
  label: string;
  value: ProductRegion;
}[] = [
  {
    label: "Global",
    value: ProductRegion.GLOBAL,
  },
  {
    label: "Asia",
    value: ProductRegion.ASIA,
  },
  {
    label: "EMEA",
    value: ProductRegion.EMEA,
  },
  {
    label: "North America",
    value: ProductRegion.NA,
  },
];

export const productRegionOptionsName: {
  name: string;
  value: ProductRegion;
}[] = [
  {
    name: "Asia",
    value: ProductRegion.ASIA,
  },
  {
    name: "EMEA",
    value: ProductRegion.EMEA,
  },

  {
    name: "Global",
    value: ProductRegion.GLOBAL,
  },
  {
    name: "North America",
    value: ProductRegion.NA,
  },
];

export interface ProductDto {
  name: string;
  region: string;
  description?: string;
  img: any;
}

export interface ProductLinePVADto {
  name: string;
  dataType: string;
}

export type GetProductParams = Partial<{
  items: string;
  name: string;
  page?: number;
  regions: ProductRegion;
  sortBy: string;
}>;

export type GetPropertiesParams = Partial<{
  items: string;
  page: string;
}>;
