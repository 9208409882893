import React from "react";
import colors from "../../../../theme/colors";
import {
  DataTypeEnum,
  DomainEnum,
  Property,
} from "store/reducers/products/properties/pl-properties.types";
import { PropertiesDropdown } from "./PropertiesDropdown";
import { getEnumPropertiesOptions } from "components/Table/helper";
import {
  Ruleset,
  Tag,
} from "store/reducers/products/parameters/pl-parameters.types";
import NameCell from "./NameCell";
import { SearchableDropdown, ToolTip, TypeConstrainedCell } from "components";
import { editPropertyPvaCell } from "store/reducers/products/properties/pl-properties.actions";
import { determineDataTypeOptions } from "../../pages/ProductDetail/helpers";
import { editSubPropertyPvaCell } from "../../../../store/reducers/products/subproperties/pl-subproperties.actions";
import { api } from "../../../../api/api";

const dataCellStyles = {
  minHeight: "40px",
  minWidth: "276px",
  color: colors.muttedHeadlines,
  fontSize: "14px",
};

const headerCellStyles = {
  backgroundColor: colors.tables.columnsAndRowsTitles,
  color: colors.muttedHeadlines,
  minWidth: "180px",
  minHeight: "40px",
};

export const propertyHasValues = (property: Property) => {
  return property.propertyValues.some(
    (row) => row.valueAttributeCells[0].value !== ""
  );
};

const autoSelectionOptions = [
  { value: "TRUE", name: "TRUE" },
  { value: "FALSE", name: "FALSE" },
];

//getRelatedProperties through table via API
/**
 * Retrieves related properties based on the given product line ID and property ID.
 * @param productLineId - The ID of the product line.
 * @param propertyId - The ID of the property.
 * @returns A Promise that resolves to the result of the API call.
 */
export const getRelatedProperties = async (
  productLineId: string,
  propertyId: string
) => {
  const result = await api.getRulesTables(
    productLineId,
    "0", // fixed a high limit as there is no way to get all tables may cause performance issues
    0,
    undefined,
    undefined,
    [propertyId]
  );
  return result;
};

/**
 * Generates a Graphviz representation of tables with properties.
 * @param tables - The array of tables.
 * @param centerPropertyName - The name of the center property.
 * @param includeSubProperty - A flag indicating whether to include sub-properties.
 * @returns The Graphviz representation as a string.
 */
export const generateGraphviz = (
  tables,
  centerPropertyName,
  includeSubProperty
) => {
  let dotContent = `digraph "" {overlap=false;splines=true;`;
  const props = new Set();
  tables = tables.sort((a, b) => {
    if (a.tableType > b.tableType) {
      return -1;
    }
    if (a.tableType < b.tableType) {
      return 1;
    }
    return 0;
  });

  const addEdge = (from, to, options = "") => {
    const edge = `  "${from}" -> "${to}"${options};`;
    if (!dotContent.includes(edge)) {
      dotContent += edge;
    }
  };

  const addProperties = (properties) => {
    if (properties) {
      properties.forEach((prop) => {
        props.add(prop.name);
      });
    }
  };

  tables.forEach((table) => {
    if (table.tableType === "LOOKUP" || table.tableType === "ATTR_LOOKUP") {
      const { properties = [], lookUpFieldIndex } = table;
      const dependantPropertiesName = properties
        .filter((_, index) => index >= lookUpFieldIndex)
        .filter(
          (prop) =>
            includeSubProperty || prop.name.includes("::") || !prop.parentId
        )
        .map((prop) => prop.name);

      const affectedPropertiesName = properties
        .filter((_, index) => index < lookUpFieldIndex)
        .filter(
          (prop) =>
            includeSubProperty || prop.name.includes("::") || !prop.parentId
        )
        .map((prop) => prop.name);

      addProperties(properties);
      const isAffected = affectedPropertiesName.includes(centerPropertyName);
      if (isAffected || dependantPropertiesName.includes(centerPropertyName)) {
        const color = isAffected
          ? "red"
          : table.tableType === "ATTR_LOOKUP"
          ? "pink"
          : "blue";
        const direction = isAffected ? "forward" : "back";

        if (isAffected) {
          dependantPropertiesName.forEach((propName) => {
            addEdge(
              propName,
              centerPropertyName,
              `[dir="${direction}" color="${color}"]`
            );
          });
        } else {
          affectedPropertiesName.forEach((propName) => {
            addEdge(centerPropertyName, propName, `[color="${color}"]`);
          });
        }
      }
    } else if (table.tableType === "INCLUDE" || table.tableType === "EXCLUDE") {
      const color = table.tableType === "INCLUDE" ? "forestgreen" : "orange";
      const includePropertiesName = (table.properties || [])
        .filter((prop) => prop.name !== centerPropertyName)
        .filter(
          (prop) =>
            includeSubProperty || !prop.parentId || prop.name.includes("::")
        )
        .map((prop) => prop.name);

      includePropertiesName.forEach((propName) => {
        addEdge(centerPropertyName, propName, `[dir="both" color="${color}"]`);
      });

      addProperties(table.properties);
    }
  });
  //Center property emphasis
  dotContent += `  "${centerPropertyName}" [style=filled, fillcolor=yellow];`;
  dotContent += "}";
  return dotContent;
};

// export const propertyHasFloatValues = property;

export const GetTableData = (
  propertyData: Property,
  type?: any,
  ruleSetsOptions: Ruleset[] = [],
  tagsOptions: Tag[] = []
) => {
  const { ruleSets = [], tags = [] } = propertyData;
  const hasValues = propertyHasValues(propertyData);
  const dataTypeOptions = getEnumPropertiesOptions(
    determineDataTypeOptions(
      propertyData.domain,
      hasValues,
      propertyData.dataType
    )
  );

  return {
    header: [],
    rows: [
      [
        {
          content: "NAME",
          style: headerCellStyles,
          id: "nameTitleColumn",
        },
        {
          content: (
            <NameCell
              propertyData={propertyData}
              id={"UniqueNameCellUUID"}
              type={type}
            />
          ),
          id: "nameEditCell",
        },
      ],
      [
        {
          content: "DATATYPE",
          style: headerCellStyles,
          id: "dataTypeTitleColumn",
        },
        {
          content: (
            <ToolTip
              text={"remove values to enable changing the data type"}
              disabled={!dataTypeOptions.length}
              width={"100%"}
            >
              <PropertiesDropdown
                options={dataTypeOptions}
                currentValue={DataTypeEnum[propertyData.dataType]}
                multiselect={false}
                propertyData={propertyData}
                propertyParameterType={"dataType"}
                type={type}
              />
            </ToolTip>
          ),
          style: dataCellStyles,
          id: "dropdownDataTypeCellUUID",
        },
      ],
      [
        {
          content: "RULESETS",
          style: headerCellStyles,
          id: "rulesetTitleCellUUID",
        },
        {
          content: (
            <PropertiesDropdown
              propertyData={propertyData}
              propertyParameterType={"rulesetids"}
              options={ruleSetsOptions.map((ruleset) => ({
                value: ruleset.id,
                name: ruleset.name,
              }))}
              currentValue={ruleSets.map((ruleset) => {
                return { value: ruleset.id, name: ruleset.name };
              })}
              placeholder="select rulesets"
              multiselect={true}
              type={type}
            />
          ),
          style: dataCellStyles,
          id: "RulesetsUUIDDropdown",
        },
      ],
      [
        {
          content: "TAGS",
          style: headerCellStyles,
          id: "TagsUUIDTile",
        },
        {
          content: (
            <PropertiesDropdown
              propertyData={propertyData}
              propertyParameterType={"tagids"}
              options={tagsOptions.map((tag) => ({
                value: tag.id,
                name: tag.name,
              }))}
              currentValue={tags.map((tag) => {
                return { value: tag.id, name: tag.name };
              })}
              placeholder="select tags"
              multiselect={true}
              type={type}
            />
          ),
          id: "SelectTagsUUIDCell",
          style: dataCellStyles,
        },
      ],
      [
        {
          content: "DOMAIN",
          style: headerCellStyles,
          id: "DomainTitleUUID",
        },
        {
          content: (
            <PropertiesDropdown
              options={getEnumPropertiesOptions(DomainEnum)}
              currentValue={DomainEnum[propertyData.domain]}
              multiselect={false}
              propertyData={propertyData}
              propertyParameterType={"domain"}
              type={type}
            />
          ),
          style: dataCellStyles,
          id: "domainSelectCellUUID",
        },
      ],
      propertyData.domain === DomainEnum.DISCRETE && [
        {
          content: "AUTO-SELECTION",
          style: headerCellStyles,
          id: "AutoSelectTitleUUID",
        },
        {
          content: (
            <PropertiesDropdown
              options={autoSelectionOptions}
              currentValue={propertyData?.autoSelection ?? "FALSE"}
              multiselect={false}
              propertyData={propertyData}
              propertyParameterType={"autoSelection"}
              type={type}
            />
          ),
          style: dataCellStyles,
          id: "domainSelectCellUUID",
        },
      ],
      propertyData.ownerTool !== "" && [
        {
          content: "OWNER TOOL",
          style: headerCellStyles,
          id: "OwnerToolUUID",
        },
        {
          content: propertyData?.ownerTool ?? "empty",
          style: dataCellStyles,
          id: "ownerToolCellUUID",
        },
      ],
    ],
  };
};

const customHeaderCellsStyles = { ...headerCellStyles, fontWeight: 500 };

export const getPvaTableData = (
  propertyData: any,
  dispatch: any,
  formattedRulesets: any,
  propertyType: "main" | "subproperties" | "standardSubProperty",
  parentId = ""
) => {
  const {
    propertyValueAttributes: activePvas,
    propertyValues,
    dataType: propertyDataType,
  } = propertyData;

  if (!activePvas) return;
  return {
    header: activePvas.map((pva) => ({
      content: pva.name,
      style: customHeaderCellsStyles,
      id: "VALUEtitleCellUUID" + pva.id,
    })),
    rows: propertyValues.map((row) => {
      const { valueAttributeCells } = row;

      return {
        content: valueAttributeCells.map((cell) => {
          const { value, propertyValueAttribute } = cell;
          const { dataType, id, name } = propertyValueAttribute;
          const uniqueId = id + row.id;

          if (name === "RULESET") {
            let formattedValues = null;
            try {
              formattedValues = JSON.parse(value);
            } catch (e) {
              formattedValues = null;
            }

            return {
              content: (
                <SearchableDropdown
                  placeholder="Ruleset"
                  name="RulesetIds"
                  noShadow
                  noHoverBorder
                  options={formattedRulesets}
                  value={formattedValues}
                  backgroundColor={colors.tables.hoveredCell}
                  multiple={true}
                  handleChange={(e) => {
                    dispatch(
                      editPropertyPvaCell(
                        {
                          propertyId: propertyData.id,
                          propertyValueId: row.id,
                          propertyValueAttributeId: id,
                        },
                        // backend doesn't accept anything but strings
                        JSON.stringify(e).replace('\\"', "'"),
                        propertyData
                      )
                    );
                  }}
                  id={uniqueId}
                />
              ),
              id: uniqueId,
            };
          }

          return {
            content: (
              <TypeConstrainedCell
                dataType={name === "Value" ? propertyDataType : dataType}
                type="editCell"
                handleSubmit={(val) => {
                  let newVal = val === true ? "TRUE" : val;
                  newVal = newVal === false ? "FALSE" : newVal;
                  propertyType === "main"
                    ? dispatch(
                        editPropertyPvaCell(
                          {
                            propertyId: propertyData.id,
                            propertyValueId: row.id,
                            propertyValueAttributeId: id,
                          },
                          newVal,
                          propertyData
                        )
                      )
                    : dispatch(
                        editSubPropertyPvaCell({
                          parentId: parentId,
                          propertyId: propertyData.id,
                          propertyValueAttributeId: id,
                          propertyValueId: row.id,
                          newValue: newVal,
                          property: propertyData,
                        })
                      );
                }}
                value={value}
                id={`editCell${id}`}
              />
            ),
            propertyValueRowId: row.id,
          };
        }),
        id: row.id,
      };
    }),
  };
};
