import React from "react";
import styled from "styled-components";
import { NormalP, SmallP, Button, Label } from "components";
import { Notification } from "store/reducers/notifications/notifications.types";
import { statusMapping } from "store/reducers/notifications/utils";
import { Row } from "components/Row/Row.component";
import { ClockIcon } from "assets/logos/icons/ActionsSmall";

export interface CardNotificationProps extends Partial<Notification> {
  className?: any;
}

export function CardNotification({
  title,
  description,
  createdAt,
  className,
  status,
}: CardNotificationProps) {
  if (!status) return <></>;
  const { label, labelType, color } = statusMapping[status];

  return (
    <Wrapper className={className} color={color}>
      <Header>
        <Row>
          <ClockIcon color={color} />
          <SmallP color={color}>{createdAt}</SmallP>
        </Row>
        <Label type={labelType}>{label}</Label>
      </Header>
      <div>
        <TextContainer>
          <NormalP bold>{title}</NormalP>
          <StyledSmallP>{description}</StyledSmallP>
        </TextContainer>
        <Button size="small" color="primary" type="secondary" disabled>
          READ MORE
        </Button>
      </div>
    </Wrapper>
  );
}

const Header = styled.div``;

const TextContainer = styled.div``;

const StyledSmallP = styled(SmallP)`
  height: 5ex;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;

  width: 348px;
  height: 248px;
  max-width: 100%;

  background: ${(props) => props.theme.colors.white};

  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  border-top: 6px solid ${(props) => props.color};
  box-sizing: border-box;
  border-radius: 4px;
`;
