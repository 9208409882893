import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Name from "./Steps/Name/Name.container";
import Team from "./Steps/Team/Team.container";
import Actions from "./Containers/Actions.container";
import {
  Switch,
  Route,
  Redirect,
  matchPath,
  useLocation,
} from "react-router-dom";
import routes from "modules/router/routes";

export type Steps = keyof StepRoutes;

type RouteType = { path: string };

interface StepRoutes {
  name: RouteType;
  team: RouteType;
  completionDate: RouteType;
  models: RouteType;
  milestones: RouteType;
}

export const stepRoutes: any = {
  name: { path: routes.PROGRAMS_ADD_NEW + "/name", label: "Name", position: 1 },
  team: { path: routes.PROGRAMS_ADD_NEW + "/team", label: "Team", position: 2 },
  models: {
    path: routes.PROGRAMS_ADD_NEW + "/models",
    label: "Models",
    position: 3,
  },
  completionDate: {
    path: routes.PROGRAMS_ADD_NEW + "/completion-date",
    label: "Completion Date",
    position: 4,
  },
  milestones: {
    path: routes.PROGRAMS_ADD_NEW + "/milestones",
    label: "Milestones",
    position: 5,
  },
};

export const stepRoutesArray = Object.keys(stepRoutes).map((key) => {
  return { ...stepRoutes[key], step: key };
});

export default function ProgramsAddNewPage() {
  const { pathname } = useLocation();
  const [currentRoute, setCurrentRoute] = useState(stepRoutesArray[0]);

  useEffect(() => {
    setCurrentRoute(
      stepRoutesArray.find((route) => matchPath(pathname, route))
    );
  }, [pathname]);

  return (
    // submit logic is handled by <Actions /> children. <form> is provided for correct html syntax + handleKeyPress behavior
    <form onSubmit={(e) => e.preventDefault()}>
      <Wrapper>
        <Switch>
          <Route path={stepRoutes.name.path}>
            <Name />
          </Route>
          <Route path={stepRoutes.team.path}>
            <Team />
          </Route>
          <Route path={stepRoutes.models.path}>
            <div>Models</div>
          </Route>
          <Route path={stepRoutes.completionDate.path}>
            <div>Completion Date</div>
          </Route>
          <Route path={stepRoutes.milestones.path}>
            <div>Milestones</div>
          </Route>
          <Redirect to={stepRoutes.name.path} />
        </Switch>
        <Actions currentRoute={currentRoute} />
      </Wrapper>
    </form>
  );
}

const Wrapper = styled.div<any>`
  margin-top: 10vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
