import { setRestVerb } from "store/reducers/generalActions";
import { api } from "api/api";
import {
  DataTypeEnum,
  DomainEnum,
  Property,
} from "../properties/pl-properties.types";
import {
  standardSubPropertiesObject,
  SubProperty,
} from "./pl-subproperties.types";
import { toast } from "react-toastify";
import { containsStandardSubPropertyName } from "./pl-subproperties.helpers";
import { ObjectAssignedEnum } from "../validation/validation.types";
import cloneDeep from "lodash/cloneDeep";
import { reorder } from "../../../../components/Table/helper";

export const SET_PL_PROPERTIES = "products/plSubProperties/SET_PL_PROPERTIES";
export const SET_NEW_SUB_PROPERTY =
  "products/plProperties/SET_NEW_PL_SUB_PROPERTY";
export const SET_VERB = "products/plSubProperties/REST_VERB";
export const SET_PL_SUB_PROPERTIES =
  "products/plSubProperties/SET_PL_SUB_PROPERTIES";
export const ADD_NEW_SUBPROPERTY =
  "products/plSubProperties/ADD_NEW_SUBPROPERTY";
export const ADD_STANDARD_SUBPROPERTY =
  "products/plSubProperties/ADD_STANDARD_SUBPROPERTY";
export const REMOVE_STANDARD_SUBPROPERTY =
  "products/plSubProperties/REMOVE_STANDARD_SUBPROPERTY";
export const UPDATE_CUSTOM_SUBPROPERTY =
  "products/plSubProperties/UPDATE_CUSTOM_SUBPROPERTY";
export const EDIT_STANDARD_SUBPROPERTY =
  "products/plSubProperties/EDIT_STANDARD_SUBPROPERTY";
export const REMOVE_CUSTOM_SUBPROPERTY =
  "products/plSubProperties/REMOVE_CUSTOM_SUBPROPERTY";
export const EDIT_PL_SUB_PROPERTY =
  "products/plSubProperties/EDIT_PL_SUB_PROPERTY";
export const SET_NEW_PARENT_PROPERTY =
  "products/plSubProperties/SET_NEW_PARENT_PROPERTY";
export const GET_SUB_PROPERTY_VALIDATION_BY_OBJECT_ID =
  "products/plSubProperties/GET_SUB_PROPERTY_VALIDATION_BY_OBJECT_ID";
export const GET_STANDARD_SUB_PROPERTY_VALIDATION_BY_OBJECT_ID =
  "products/plSubProperties/GET_STANDARD_SUB_PROPERTY_VALIDATION_BY_OBJECT_ID";
export const UPDATE_STANDARD_SUB_PROPERTY =
  "products/plSubProperties/UPDATE_STANDARD_SUB_PROPERTY";

export const getSubPropertiesAction = ({
  productLineId,
  PLPropertyId,
}: {
  productLineId?: any;
  PLPropertyId?: string;
}) => {
  return async (dispatch) => {
    dispatch(setRestVerb("products/plSubProperties", true, "getting"));
    try {
      const { data } = await api.getPLSubProperties(
        productLineId,
        PLPropertyId
      );
      const newAllIds: Array<any> = [];
      const newById = {};
      const newStandardAllIds: Array<any> = [];
      const newStandardById = {};

      //sort alphabetically by name
      data.sort((a, b) => {
        // Convert names to lowercase for case-insensitive sorting
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        // Compare the names
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0; // Names are equal
        }
      });

      data.map((subproperty) => {
        if (!subproperty.isStandardSubProp) {
          newAllIds.push(subproperty.id);
          newById[subproperty.id] = subproperty;
        } else {
          newStandardAllIds.push(subproperty.id);
          newStandardById[subproperty.id] = subproperty;
        }
      });
      const subproperties = {
        byId: newById,
        allIds: newAllIds,
        standardById: newStandardById,
        standardAllIds: newStandardAllIds,
      };

      dispatch({
        type: SET_PL_SUB_PROPERTIES,
        payload: {
          subproperties: subproperties,
          parentId: PLPropertyId,
        },
      });
    } catch (e) {
      console.error(e, "error while getting all subproperties to product line");
    }
    dispatch(setRestVerb("products/plSubProperties", false, "getting"));
  };
};

export const addNewParentProperty = (parentPropertyId) => {
  return {
    type: SET_NEW_PARENT_PROPERTY,
    payload: { parentId: parentPropertyId },
  };
};

export const setNewSubProperty = (newProperty: Property) => {
  return {
    type: SET_NEW_SUB_PROPERTY,
    payload: newProperty,
  };
};

export const addSubPropertyPropertyPL = (property: Property, parentId) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plSubProperties", true, "posting"));

    try {
      const { selectedProductLine } = getState().products;
      const parentProperty: Property = getState().products.properties.byId[
        parentId
      ];

      //Get list of parent property ruleset ids
      const rulesetids = parentProperty.ruleSets.map((ruleset) => ruleset.id);

      //Return a list of the any UI tag IDs from the parent property
      const tagids = parentProperty.tags
        ?.filter((tag) => {
          return tag.category === "UI_LOCATION";
        })
        .map((tag) => {
          return tag.id;
        });

      // subproperties name validation
      const { name } = property;
      if (!name) return;

      const conflictingDefaultSubP = containsStandardSubPropertyName(name);
      if (conflictingDefaultSubP) {
        throw `"${name}" name cannot be used because it contains the standard subproperty name "${conflictingDefaultSubP}".`;
      }

      const { data } = await api.createSubProperty(
        { ...property, rulesetids, tagids, parentId, isStandardSubProp: false },
        selectedProductLine.id
      );

      dispatch({
        type: ADD_NEW_SUBPROPERTY,
        payload: {
          newSubProperty: data,
        },
      });
    } catch (e) {
      console.error(e, "error while adding a subproperty to property in PL");
      toast.error(e);
    }
    dispatch(setRestVerb("products/plSubProperties", false, "posting"));
  };
};

export const addStandardSubPropertyPL = ({
  standardSubPropertyName,
  parentPropertyName,
  parentId,
  ruleSetIds,
  ruleSetsObj,
  tagIds,
  parentDataType,
  parentDomain,
}: {
  standardSubPropertyName: string;
  parentPropertyName: string;
  parentId: string;
  ruleSetIds: any;
  ruleSetsObj: any;
  tagIds: any;
  parentDataType: DataTypeEnum;
  parentDomain: DomainEnum;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plSubProperties", true, "posting"));

    try {
      const { selectedProductLine } = getState().products;

      const newPropertyObject = {
        ...standardSubPropertiesObject[standardSubPropertyName],
        name: parentPropertyName + standardSubPropertyName,
        ruleSetIds: ruleSetIds,
        tagIds: tagIds,
        parentId,
      };

      if ([".DEFAULT", ".MIN", ".MAX"].includes(standardSubPropertyName)) {
        newPropertyObject.dataType = parentDataType;
      }

      if (
        [DomainEnum.OPEN, DomainEnum.RANGE].includes(parentDomain) &&
        standardSubPropertyName === ".DEFAULT"
      ) {
        newPropertyObject.domain = DomainEnum.OPEN;
      }

      if (
        [DomainEnum.DISCRETE].includes(parentDomain) &&
        standardSubPropertyName === ".DEFAULT"
      ) {
        newPropertyObject.domain = DomainEnum.DISCRETE;
      }

      const { data } = await api.createSubProperty(
        newPropertyObject,
        selectedProductLine.id
      );

      if (!data) return null;

      const {
        standardById,
        standardAllIds,
      } = getState().products.subproperties.byParentPropertyId[parentId];

      const newById = {
        ...standardById,
        [data.id]: {
          ...data,
          ruleSets: ruleSetsObj,
        },
      };
      const newAllIds = standardAllIds.concat(data.id);

      dispatch({
        type: ADD_STANDARD_SUBPROPERTY,
        payload: {
          newById,
          newAllIds,
          parentId,
        },
      });
    } catch (e) {
      console.error(
        e,
        "error while adding a standard subproperty to property in PL"
      );
    }
    dispatch(setRestVerb("products/plSubProperties", false, "posting"));
  };
};

export const editStandardSubPropertyPL = ({
  propertyId,
  changedProperty,
  parentId,
}: {
  propertyId: any;
  changedProperty;
  parentId: any;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plSubProperties", true, "posting"));

    try {
      const { selectedProductLine } = getState().products;
      const {
        standardById,
        standardAllIds,
      } = getState().products.subproperties.byParentPropertyId[parentId];

      const res = await api.editProperty(
        selectedProductLine.id,
        propertyId,
        changedProperty
      );

      if (!res) throw null;

      const newById = {
        ...standardById,
        [res.data.id]: {
          ...res.data,
        },
      };

      dispatch({
        type: EDIT_STANDARD_SUBPROPERTY,
        payload: { parentId, newById, newAllIds: standardAllIds },
      });
    } catch (e) {
      console.error(e, "error while editing standard sub property");
    }

    dispatch(setRestVerb("products/plSubProperties", false, "posting"));
  };
};

export const removeCustomSubProperty = ({
  customSubPropertyId,
  parentId,
}: {
  customSubPropertyId: any;
  parentId: any;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plSubProperties", true, "deleting"));

    try {
      const productLineId = getState().products.selectedProductLine.id;
      const {
        byId,
        allIds,
      } = getState().products.subproperties.byParentPropertyId[parentId];

      const res = await api.removeProperty(productLineId, customSubPropertyId);

      if (!res) throw null;

      const newById = { ...byId };
      delete newById[customSubPropertyId];

      const newAllIds = allIds.filter((id) => id !== customSubPropertyId);

      dispatch({
        type: REMOVE_CUSTOM_SUBPROPERTY,
        payload: { parentId, newById, newAllIds },
      });
    } catch (e: any) {
      console.error(e, "error while deleting custom subproperty");
      toast.error(e.response.data.message);
    }
    dispatch(setRestVerb("products/plSubProperties", false, "deleting"));
  };
};

export const removeCustomSubPropertyWithoutApiCall = ({
  customSubPropertyId,
  parentId,
}: {
  customSubPropertyId: any;
  parentId: any;
}) => {
  return async (dispatch, getState) => {
    const {
      byId,
      allIds,
    } = getState().products.subproperties.byParentPropertyId[parentId];

    const newById = { ...byId };
    delete newById[customSubPropertyId];

    const newAllIds = allIds.filter((id) => id !== customSubPropertyId);

    dispatch({
      type: REMOVE_CUSTOM_SUBPROPERTY,
      payload: { parentId, newById, newAllIds },
    });
  };
};

export const removeStandardSubPropertyPL = ({
  standardSubPropertyName,
  parentId,
}: {
  standardSubPropertyName: string;
  parentId: string;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plSubProperties", true, "deleting"));

    try {
      const parent = getState().products.subproperties.byParentPropertyId[
        parentId
      ];
      const { selectedProductLine } = getState().products;

      const standardSubPropertyId =
        Object.keys(parent.standardById).find((id) => {
          if (parent.standardById[id].name === standardSubPropertyName)
            return id;
        }) ?? "";

      const res = await api.removeProperty(
        selectedProductLine.id,
        standardSubPropertyId
      );

      if (!res) throw null;

      const {
        standardById,
        standardAllIds,
      } = getState().products.subproperties.byParentPropertyId[parentId];

      const newById = { ...standardById };
      delete newById[standardSubPropertyId];

      const newAllIds = standardAllIds.filter(
        (id) => id !== standardSubPropertyId
      );

      dispatch({
        type: REMOVE_STANDARD_SUBPROPERTY,
        payload: { parentId, newById, newAllIds },
      });
    } catch (e) {
      console.error(e, "error while deleting sub property");
    }
    dispatch(setRestVerb("products/plSubProperties", false, "deleting"));
  };
};

export const addPvaRowToSubProperty = (
  newPvaRow: any,
  property: any,
  parentId: string
) => {
  return async (dispatch, getState) => {
    try {
      const { selectedProductLine } = getState().products;

      const body = {
        valueAttributeCells: Object.keys(newPvaRow).map((key: string) => {
          return {
            PropertyValueAttributeId: key,
            value: newPvaRow[key],
          };
        }),
      };
      const { data } = await api.addPvaRowToProperty(
        property.id,
        body,
        selectedProductLine.id
      );

      const newProperty = { ...property };
      newProperty.propertyValues = newProperty.propertyValues.concat(data);

      const type = property.isStandardSubProp
        ? UPDATE_STANDARD_SUB_PROPERTY
        : UPDATE_CUSTOM_SUBPROPERTY;

      dispatch({
        type: type,
        payload: {
          subproperty: newProperty,
          parentId: parentId,
        },
      });

      toast.dismiss();
    } catch (e) {
      toast.error(e);
      console.error(e, "error while adding a pva row to a property");
    }
  };
};

export const removePvaRowFromSubProperty = (
  property,
  propertyValueId,
  parentId
) => {
  return async (dispatch, getState) => {
    const { id: productLineId } = getState().products.selectedProductLine;

    const type = property.isStandardSubProp
      ? UPDATE_STANDARD_SUB_PROPERTY
      : UPDATE_CUSTOM_SUBPROPERTY;
    try {
      const newProperty = cloneDeep(property);

      newProperty.propertyValues = newProperty.propertyValues.filter(
        (row) => row.id !== propertyValueId
      );

      dispatch({
        type: type,
        payload: {
          subproperty: newProperty,
          parentId: parentId,
        },
      });

      await api.removePropertyValueRowFromProperty(
        productLineId,
        property.id,
        propertyValueId
      );
    } catch (e) {
      console.error(e, "error while removing row from sub property pvas");

      dispatch({
        type: type,
        payload: {
          subproperty: property,
          parentId: parentId,
        },
      });
    }
  };
};

export const editSubPropertyAction = ({
  propertyId,
  updatePayload,
  parentId,
  propertyData,
}: {
  propertyId?: string;
  updatePayload: any;
  parentId: string;
  propertyData?: any;
}) => {
  return async (dispatch, getState) => {
    dispatch(setRestVerb("products/plSubProperties", true, "posting"));
    const { selectedProductLine } = getState().products;

    try {
      if (!propertyId) return;

      if ("domain" in updatePayload) {
        if (
          updatePayload.domain === DomainEnum.RANGE &&
          (propertyData.dataType === DataTypeEnum.BOOL ||
            propertyData.dataType === DataTypeEnum.STRING)
        ) {
          toast.error(
            `Change datatype of ${propertyData.name} to either INT or FLOAT`
          );
        }
      }

      const res = await api.editProperty(
        selectedProductLine.id,
        propertyId,
        updatePayload
      );

      if (!res) throw null;

      const { data } = res;

      dispatch({
        type: EDIT_PL_SUB_PROPERTY,
        payload: { parentId: parentId, subproperty: data },
      });
    } catch (e) {
      console.error(e, "error while editing a subproperty of a product line");
    }
    dispatch(setRestVerb("products/plSubProperties", false, "posting"));
  };
};

export const reOrderSubPropertyValueAction = ({
  parentId,
  isStandardSubProperty,
  propertyId,
  propertyValueId,
  destinationPosition,
  sourcePosition,
}) => {
  return async (dispatch, getState) => {
    const { selectedProductLine, subproperties } = getState().products;
    const property = isStandardSubProperty
      ? {
          ...subproperties.byParentPropertyId[parentId].standardById[
            propertyId
          ],
        }
      : { ...subproperties.byParentPropertyId[parentId].byId[propertyId] };

    const type = isStandardSubProperty
      ? UPDATE_STANDARD_SUB_PROPERTY
      : UPDATE_CUSTOM_SUBPROPERTY;

    const { propertyValues } = property;
    property.propertyValues = reorder(
      propertyValues,
      sourcePosition,
      destinationPosition
    );

    try {
      const newProperty = cloneDeep(property);

      dispatch({
        type: type,
        payload: { parentId, subproperty: newProperty },
      });

      await api.reOrderPropertyValueRows(
        propertyId,
        selectedProductLine.id,
        propertyValueId,
        destinationPosition
      );
    } catch (e) {
      // optimistic rollback
      dispatch({
        type: type,
        payload: { parentId, subproperty: property },
      });

      console.error(e);
    }
  };
};

export const removePvaFromSubPropertyAction = (
  propertyId: string,
  propertyPva: any,
  property: any,
  parentId: any
) => {
  return async (dispatch, getState) => {
    try {
      const { selectedProductLine } = getState().products;

      if (!propertyId || !propertyPva.id) return;
      const newProperty = {
        ...property,
        propertyValueAttributes: property.propertyValueAttributes.filter(
          (pva) => pva.id !== propertyPva.id
        ),
      };

      dispatch({
        type: EDIT_PL_SUB_PROPERTY,
        payload: { subproperty: newProperty, parentId: parentId },
      });
      await api.removePvasFromProperty(
        propertyId,
        [propertyPva.id],
        selectedProductLine.id
      );
      dispatch(
        getSubPropertiesAction({
          productLineId: selectedProductLine.id,
          PLPropertyId: parentId,
        })
      );
    } catch (e) {
      console.error(e, "error while editing a main property of a product line");

      // rollback
      dispatch({
        type: EDIT_PL_SUB_PROPERTY,
        payload: { subproperty: property, parentId: parentId },
      });
    }
  };
};

export const getSubPropertyValidation = ({
  objectId,
  parentId,
  objectAssigned,
  isStandardSubProperty = false,
}: {
  objectId: string;
  parentId: string;
  objectAssigned: ObjectAssignedEnum;
  isStandardSubProperty?: boolean;
}) => {
  return async (dispatch, getState) => {
    const selectedProductLineId = getState().products?.selectedProductLine?.id;

    try {
      const { data } = await api.getValidation(
        selectedProductLineId,
        objectId,
        objectAssigned
      );

      dispatch({
        type: isStandardSubProperty
          ? GET_STANDARD_SUB_PROPERTY_VALIDATION_BY_OBJECT_ID
          : GET_SUB_PROPERTY_VALIDATION_BY_OBJECT_ID,
        payload: { validations: data, objectId, parentId },
      });
    } catch (e) {
      console.error(e, "error while getting validation for specific object");
    }
  };
};

export const editSubPropertyPvaCell = ({
  parentId,
  propertyId,
  propertyValueAttributeId,
  propertyValueId,
  newValue,
  property,
}: {
  parentId: string;
  propertyId: string;
  propertyValueAttributeId: string;
  propertyValueId: string;
  newValue: any;
  property: SubProperty;
}) => {
  return async (dispatch, getState) => {
    const { id: productLineId } = getState().products.selectedProductLine;
    const newProperty = cloneDeep(property);
    const { isStandardSubProp } = property;
    const newPropertyValue = newProperty.propertyValues.find(
      (elem) => elem.id === propertyValueId
    );
    const newValueAttributeCell = newPropertyValue.valueAttributeCells.find(
      (elem) => elem.propertyValueAttribute.id === propertyValueAttributeId
    );

    const type = isStandardSubProp
      ? UPDATE_STANDARD_SUB_PROPERTY
      : UPDATE_CUSTOM_SUBPROPERTY;

    newValueAttributeCell.value = newValue;

    try {
      dispatch({
        type: type,
        payload: { parentId, subproperty: newProperty },
      });

      const res = await api.editPropertyPvaCell(
        {
          propertyId,
          productLineId,
          propertyValueId,
          propertyValueAttributeId,
        },
        { value: newValue }
      );

      if (!res) throw null;
    } catch (e) {
      console.error(e, "error while editing sub property pva cell");
      // rollback
      dispatch({
        type: type,
        payload: { parentId, subproperty: property },
      });
    }
  };
};

export const addPvaToSubPropertyAction = (
  propertyId: string,
  propertyPva: any,
  property: any,
  parentId: any
) => {
  return async (dispatch, getState) => {
    try {
      const { selectedProductLine } = getState().products;

      if (!propertyId || !propertyPva.id) return;

      const newProperty = {
        ...property,
        propertyValueAttributes: property.propertyValueAttributes.concat(
          propertyPva
        ),
      };

      dispatch({
        type: EDIT_PL_SUB_PROPERTY,
        payload: { subproperty: newProperty, parentId: parentId },
      });

      await api.addPvasToProperty(
        propertyId,
        [propertyPva.id],
        selectedProductLine.id
      );
      dispatch(
        getSubPropertiesAction({
          productLineId: selectedProductLine.id,
          PLPropertyId: parentId,
        })
      );
    } catch (e) {
      console.error(e, "error while editing a main property of a product line");

      // rollback
      dispatch({
        type: EDIT_PL_SUB_PROPERTY,
        payload: { subproperty: property, parentId: parentId },
      });
    }
  };
};
