import { Modal } from "components";
import React, { useState } from "react";
import Task from "../components/Task.component";
import AddNewTask from "../components/AddNewTask.component";

export default function TrackerBreakdown() {
  const [isAddTaskModalVisible, setAddTaskVisibility] = useState(false);
  const [isTaskModalVisible, setTaskVisibility] = useState(false);
  const [currentId, setCurrentId] = useState<any>();
  const addTaskVisibility = () => {
    setAddTaskVisibility((prevState) => !prevState);
  };

  const taskVisibility = () => {
    setTaskVisibility((prevState) => !prevState);
  };

  const closeAddTask = () => {
    setAddTaskVisibility(false);
    setCurrentId(null);
  };

  return (
    <>
      <div>Tracker Breakdown</div>
      <button onClick={addTaskVisibility}>Add Task</button>
      <Modal
        closeModal={closeAddTask}
        open={isAddTaskModalVisible}
        width={"950px"}
        height={"700px"}
      >
        <AddNewTask id={currentId} close={closeAddTask} />
      </Modal>
      <button onClick={taskVisibility}>Open Task</button>
      <Modal
        closeModal={() => setTaskVisibility(false)}
        open={isTaskModalVisible}
        width={"950px"}
        height={"700px"}
      >
        <Task
          id="1"
          close={() => setTaskVisibility(false)}
          openAddTask={() => setAddTaskVisibility(true)}
          setId={(newId) => setCurrentId(newId)}
        />
      </Modal>
    </>
  );
}
