import React from "react";
import styled from "styled-components";
import ParametersTable from "../../../components/ParametersTable/ParametersTable.component";
import { useTypedSelector } from "store";

export default function ProductLineRulesets() {
  const { getting } = useTypedSelector((state) => state.products);

  if (getting) return <></>;

  return (
    <ParametersWrapper>
      <ParametersTable
        header={"Rulesets"}
        parameterType="ruleSets"
        addRowButtonLabel="ADD RULESET"
      />
    </ParametersWrapper>
  );
}

const ParametersWrapper = styled.div`
  padding: 0px 24px 0 24px;
`;
