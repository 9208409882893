import { User } from "./user.types";

// Actions constants
export const USER_SET_USER = "user/SET_USER";
export const SET_PREFERENCES = "user/SET_PREFERENCES";

export const setName = (user: Partial<User>) => {
  const { firstName, lastName } = user;
  if (firstName && lastName) return firstName + " " + lastName;
  return "";
};

// Action creators
// Note: we don't type actions creators return, so we can create re-usable actions accross reducers later, which will be too hard and hacky to type
export const setUserAction = (user: Partial<User>) => {
  user.name = setName(user);
  return {
    type: USER_SET_USER,
    payload: user,
  };
};

export const setUserPreferences = (preferences: any) => {
  return {
    type: SET_PREFERENCES,
    payload: preferences,
  };
};
