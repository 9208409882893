import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Button } from "components/Buttons/Button.component";
import { SearchableDropdown } from "components/Forms/SearchableDropdown/SearchableDropdown.component";
import { H4, H3 } from "components/Typography/Headlines.component";
import { CalendarIcon, FilterIcon } from "assets/logos/icons/MediumIcons";
import StatisticsBox from "./components/StatisticsBox.component";
import colors from "theme/colors";
import OverviewSection from "./components/OverviewSection.component";
import { useParams } from "react-router-dom";
import { selectProgramById } from "store/reducers/programs/programs.selectors";
import { useTypedSelector } from "store";
import { setSelectedProgramAction } from "store/reducers/programs/programs.actions";
import TeamSection from "./components/TeamSection.component";
import ProductsAndPartsImpacted from "./containers/ProductsAndPartsImpacted.container";
import SupportingDocsSection from "./components/SupportingDocsSection.component";
import TrackerBreakdown from "./containers/TrackerBreakdown.component";
import Chart from "./components/Chart.component";

export default function ProgramDetails() {
  const options = [
    { name: "Milestone 1", value: "ms1" },
    { name: "Milestone 2", value: "ms2" },
    { name: "Milestone 3", value: "ms3" },
  ];
  const { id } = useParams();
  const dispatch = useDispatch();
  const programDetails = selectProgramById(
    useTypedSelector((state) => state.programs),
    id
  );
  useEffect(() => {
    dispatch(setSelectedProgramAction(programDetails));
  }, []);
  return (
    <Container>
      <H4 color={colors.muttedHeadlines}>Master Program Tracker</H4>
      <ButtonsRow>
        <StyledSearchableDropdown
          placeholder="All milestones"
          name="Milestones"
          options={options}
          height={"47px"}
          customFontSize={"18px"}
          search={false}
        />
        <CustomButton color="primary" type="secondary">
          <CustomCalendar />
          Date Range
        </CustomButton>
        <CustomButton color="primary" type="secondary">
          <CustomFilter />
          Filter
        </CustomButton>
      </ButtonsRow>
      <MainBox>
        <Chart />
        <StatisticsBox />
      </MainBox>
      <Row>
        <TeamSection selectedProgram={programDetails} />
        <OverviewSection requirements={programDetails.requirements} />
      </Row>
      <CustomSectionTitle>Supporting Docs</CustomSectionTitle>
      <SupportingDocsSection
        name={"Accessory and Kit Data"}
        category="accessory-and-kit-data"
        docs={programDetails.supportingDocs}
      />
      <SupportingDocsSection
        name={"Brochure"}
        category="brochure"
        docs={programDetails.supportingDocs}
      />
      <SupportingDocsSection
        name={"CAD Templates"}
        category="cad-templates"
        docs={programDetails.supportingDocs}
      />
      <SupportingDocsSection
        name={"Controls"}
        docs={programDetails.supportingDocs}
        category="controls"
      />
      <ProductsAndPartsImpacted
        productsImpacted={programDetails.productsImpacted}
        partsImpacted={programDetails.partsImpacted}
      />
      <TrackerBreakdown />
    </Container>
  );
}

const StyledSearchableDropdown = styled(SearchableDropdown)`
  border: 1px solid ${(props) => props.theme.colors.tables.borders};
  border-radius: 3px;
`;

const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  margin-left: 10px;
  &:hover path {
    fill: ${(props) => props.theme.colors.white};
  }
`;

const CustomCalendar = styled(CalendarIcon)`
  margin-right: 14px;
  fill: ${colors.primary};
  path {
    fill: ${(props) => props.theme.colors.primary};
  }
`;

const CustomFilter = styled(FilterIcon)`
  margin-right: 14px;
  fill: ${colors.primary};
  path {
    fill: ${(props) => props.theme.colors.primary};
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Row = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
`;

const Container = styled.div`
  margin-left: 48px;
  margin-top: 158px;
  max-width: 1116px;
  justify-content: center;
`;

const CustomSectionTitle = styled(H3)`
  margin-block-start: 40px;
  margin-block-end: 0px;
  max-width: 1092px;
  justify-content: center;
`;
