import React, { useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";

enum ActionStatus {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}

export const ConfirmActionButton = ({
  buttonTitle,
  onClick,
  disabled = false,
  dialogTitle = "Confirm Action",
  dialogText = "Are you sure?",
  statusTextSuccess = "Success!",
  variant = "contained",
}: {
  buttonTitle: string;
  onClick?: any;
  disabled?: boolean;
  dialogTitle?: string;
  dialogText?: string;
  statusTextSuccess?: string;
  variant?: "contained" | "outlined" | "text";
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<ActionStatus>(ActionStatus.IDLE);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleClickOpen = () => {
    setOpen(true);
    setStatus(ActionStatus.IDLE);
  };

  const handleDialogNo = () => {
    setOpen(false);
    setStatus(ActionStatus.IDLE);
  };

  const handleDialogYes = async () => {
    setOpen(false);

    if (onClick) {
      setStatus(ActionStatus.PENDING);

      try {
        //Run the callback supplied from props
        await onClick();

        setStatus(ActionStatus.SUCCESS);

        //Five seconds after success, we change back to idle status
        setTimeout(() => {
          setStatus(ActionStatus.IDLE);
        }, 5000);
      } catch (error) {
        setStatus(ActionStatus.ERROR);
        setErrorMessage(error);
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleDialogNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogYes} variant="outlined">
            Yes
          </Button>
          <Button onClick={handleDialogNo} variant="contained" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container rowSpacing={0}>
        <Grid xs={12} display="flex" justifyContent="center">
          <Button
            variant={variant}
            size="small"
            color="primary"
            startIcon={<BuildCircleIcon />}
            onClick={handleClickOpen}
            disabled={disabled}
          >
            {buttonTitle}
          </Button>
        </Grid>
        {status !== ActionStatus.IDLE && (
          <Grid xs={12} display="flex" justifyContent="center" padding={1}>
            {status === ActionStatus.PENDING && <CircularProgress />}
            {status === ActionStatus.SUCCESS && (
              <Alert severity="success">{statusTextSuccess}</Alert>
            )}
            {status === ActionStatus.ERROR && (
              <Alert severity="error">{errorMessage}</Alert>
            )}
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ConfirmActionButton;
