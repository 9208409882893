import React, { useEffect } from "react";
import styled from "styled-components";
import colors from "../../../../../theme/colors";
import { H3 } from "../../../../../components";
import { useDispatch } from "react-redux";
import { getPropertyPrioritiesList } from "../../../../../store/reducers/products/propertyPriorities/pl-propertyPriorities.action";
import { useTypedSelector } from "../../../../../store";
import { selectProductLineId } from "../../../../../store/reducers/products/products.selectors";
import PropertyPriority from "./PropertyPriority.component";
import AddPriorityListButton from "./AddPriorityListButton.component";

export function PropertyPrioritiesList() {
  const dispatch = useDispatch();
  const selectedProductLineId = useTypedSelector(selectProductLineId);
  const propertyPriorityList = useTypedSelector(
    (state) => state.products.propertyPriorities.byId
  );
  const getting = useTypedSelector(
    (state) => state.products.propertyPriorities.getting
  );

  useEffect(() => {
    if (!selectedProductLineId) return;
    dispatch(getPropertyPrioritiesList());
  }, [selectedProductLineId]);

  if (getting) return null;

  return (
    <Wrapper>
      <HeaderWrapper>
        <StyledHeader bold color={colors.muttedHeadlines}>
          Properties Priority List
        </StyledHeader>
        <AddPriorityListButton />
      </HeaderWrapper>
      <PropertyPrioritiesListWrapper>
        {Object.keys(propertyPriorityList).map((el) => (
          <PropertyPriority key={el} data={propertyPriorityList[el]} />
        ))}
      </PropertyPrioritiesListWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

const PropertyPrioritiesListWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  gap: 32px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeader = styled(H3)``;
