import { ErrorObject } from "../validation/validation.types";

export enum DataTypeEnum {
  STRING = "STRING",
  BOOL = "BOOL",
  FLOAT = "FLOAT",
  INT = "INT",
}

export const dataTypesOptions: DataTypeEnum[] = [
  DataTypeEnum.STRING,
  DataTypeEnum.BOOL,
  DataTypeEnum.FLOAT,
  DataTypeEnum.INT,
];

export enum DomainEnum {
  DISCRETE = "DISCRETE",
  OPEN = "OPEN",
  RANGE = "RANGE",
}

export const domainOptions: DomainEnum[] = [
  DomainEnum.DISCRETE,
  DomainEnum.OPEN,
  DomainEnum.RANGE,
];

export interface StandardSubProperty {
  name: string;
  ruleSet: string;
  default: string;
  id?: string;
  validations: ErrorObject[];
}

export interface Property {
  name: string;
  dataType: DataTypeEnum;
  domain: DomainEnum;
  ruleSets: any;
  tags: any;
  propertyValueAttributes;
  isStandardSubProp?: boolean;
  propertyValues: any;
  id?: string;
  parentId: string | null;
  comments: string;
  sources: string;
  autoSelection: "TRUE" | "FALSE";
  ownerTool: string;
  dbSchemaVersion: number;

  // TODO: put that in dto
  rulesetids?: any;
  tagids?: any;
}

export interface PropertiesState {
  byId: any;
  allIds: string[];
  total: number;
  page: number;
  newProperty: any;
  getting: boolean;
  deleting: boolean;
  posting: boolean;
}

export enum TagCategoryEnum {
  NONE = "",
  UI_LOCATION = "UI_LOCATION",
  LINEITEM = "LINEITEM",
  OPT_CATEGORY = "OPT_CATEGORY",
  ACC_CATEGORY = "ACC_CATEGORY",
  WAR_CATEGORY = "WAR_CATEGORY",
  QC_CATEGORY = "QC_CATEGORY",
  STARTUP_CATEGORY = "STARTUP_CATEGORY",
  MISC = "MISC",
}

export const TagCategoryOptions = [
  { name: "Uncategorized", value: TagCategoryEnum.NONE },
  { name: "UI Location", value: TagCategoryEnum.UI_LOCATION },
  { name: "Quote Line Item", value: TagCategoryEnum.LINEITEM },
  { name: "Option Category", value: TagCategoryEnum.OPT_CATEGORY },
  { name: "Accessory Category", value: TagCategoryEnum.ACC_CATEGORY },
  { name: "Warranty Category", value: TagCategoryEnum.WAR_CATEGORY },
  { name: "Quote Control Category", value: TagCategoryEnum.QC_CATEGORY },
  { name: "Startup Category", value: TagCategoryEnum.STARTUP_CATEGORY },
  { name: "Miscellaneous", value: TagCategoryEnum.MISC },
];
