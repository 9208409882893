import { SET_CANDIDATE_SELECTION, SET_VERB } from "./candidate.actions";
import { CandidateInitialState } from "./candidate.types";

export const candidateInitialState: CandidateInitialState = {
  data: undefined,
  getting: false,
  posting: false,
  deleting: false,
};

export function candidateReducer(
  candidateState: CandidateInitialState = candidateInitialState,
  action: any
) {
  switch (action.type) {
    case SET_CANDIDATE_SELECTION: {
      return {
        ...candidateState,
        data: action.payload,
      };
    }
    case SET_VERB: {
      return {
        ...candidateState,
        ...action.payload,
      };
    }
    default:
      return candidateState;
  }
}
