import React from "react";
import { SearchableDropdown } from "components";
import { Property } from "store/reducers/products/properties/pl-properties.types";
import colors from "theme/colors";
import { useDispatch } from "react-redux";
import { editPropertyAction } from "store/reducers/products/properties/pl-properties.actions";
import {
  editSubPropertyAction,
  editStandardSubPropertyPL,
} from "store/reducers/products/subproperties/pl-subproperties.actions";
import { useParentPropertyContext } from "../../pages/ProductDetail/ProductLineProperties/ParentPropertyContext";

export function PropertiesDropdown({
  options,
  placeholder,
  currentValue,
  multiselect,
  propertyData,
  propertyParameterType,
  type,
}: {
  options: any;
  placeholder?: any;
  currentValue: any;
  multiselect: any;
  propertyData: Property;
  propertyParameterType:
    | "dataType"
    | "rulesetids"
    | "tagids"
    | "domain"
    | "autoSelection";
  type?: string;
}) {
  const dispatch = useDispatch();
  const { parentId } = useParentPropertyContext();

  const handleChange = (e) => {
    const newProperty = {
      ...propertyData,
      [propertyParameterType]: e,
    };
    if (type === "main") {
      if (propertyParameterType === "rulesetids") {
        newProperty.ruleSets = options.filter((option) =>
          e.includes(option.value)
        );
      }

      if (propertyParameterType === "tagids") {
        newProperty.tags = options.filter((option) => e.includes(option.value));
      }
      dispatch(
        editPropertyAction({
          propertyId: propertyData.id,
          property: newProperty,
          updatePayload: { [propertyParameterType]: e },
        })
      );
    }
    if (type === "subproperties") {
      dispatch(
        editSubPropertyAction({
          propertyId: propertyData.id,
          updatePayload: { [propertyParameterType]: e },
          parentId: parentId ? parentId : "",
          propertyData: propertyData,
        })
      );
    }
    if (type === "standardSubProperty") {
      dispatch(
        editStandardSubPropertyPL({
          propertyId: propertyData.id,
          changedProperty: { [propertyParameterType]: e },
          parentId,
        })
      );
    }
  };

  return (
    <SearchableDropdown
      options={options || []}
      name={propertyParameterType}
      noShadow
      search={false}
      noHoverBorder
      handleChange={handleChange}
      value={currentValue}
      placeholder={placeholder || currentValue}
      multiple={multiselect}
      backgroundColor={colors.tables.hoveredCell}
      id={propertyParameterType + "customDropdownUUID"}
      disabled={!options.length}
    />
  );
}
