export const chartData = [
  {
    label: "1 Feb",
    completed: 100,
    total: 400,
    estimation: 20,
    original: 40,
  },
  {
    label: "15 Feb",
    completed: 200,
    total: 400,
    estimation: 40,
    original: 80,
  },
  {
    label: "1 Mar",
    completed: 300,
    total: 400,
    estimation: 60,
    original: 120,
  },
  {
    label: "15 Mar",
    completed: 300,
    total: 400,
    estimation: 80,
    original: 140,
  },
  {
    label: "1 Apr",
    completed: 300,
    total: 400,
    estimation: 100,
    original: 180,
  },
  {
    label: "15 Apr",
    completed: 300,
    total: 400,
    estimation: 120,
    original: 220,
  },
  {
    label: "1 May",
    completed: 400,
    total: 400,
    estimation: 140,
    original: 260,
  },
  {
    label: "15 May",
    completed: 300,
    total: 400,
    estimation: 160,
    original: 300,
  },
  {
    label: "1 May",
    completed: 300,
    total: 400,
    estimation: 180,
    original: 340,
  },
  {
    label: "15 May",
    completed: 300,
    total: 400,
    estimation: 200,
    original: 380,
  },
  {
    label: "1 June",
    completed: 300,
    total: 400,
    estimation: 220,
    original: 400,
  },
  {
    label: "15 June",
    completed: 300,
    total: 400,
    estimation: 240,
    original: 400,
  },
];
