import React, { useState } from "react";
import styled from "styled-components";
import { H4, Table } from "components";
import {
  addPVA,
  addRuleset,
  removeRuleset,
  removeTag,
  addTag,
} from "store/reducers/products/parameters/parameters.actions";
import { useDispatch } from "react-redux";
import { addPVAFields } from "./AddRows/PVARow";
import { removePVA } from "store/reducers/products/parameters/parameters.actions";
import { useTypedSelector } from "store";
import { getTableData } from "pages/Products/components/ParametersTable/helpers";
import { addRulesetFields } from "./AddRows/ruleSetsAddRow";
import { addTagFields } from "./AddRows/addTagsRow";
import { Add } from "../../../../assets/logos/icons/MicroIcons";

export function ParametersTable({
  parameterType,
  addRowButtonLabel,
  header,
  className,
}: {
  parameterType: "pva" | "ruleSets" | "tags";
  addRowButtonLabel?: string;
  header: string;
  className?: any;
}) {
  const [showAddRow, setShowAddRow] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handlePressAdd = () => {
    setShowAddRow((showAddRow) => !showAddRow);
  };

  const mapParameterTypeToTables = {
    pva: {
      deleteFunction: (id) => dispatch(removePVA(id)),
      addRowContent: addPVAFields,
      pressAdd: () => dispatch(addPVA()),
    },
    ruleSets: {
      deleteFunction: (id) => dispatch(removeRuleset(id)),
      addRowContent: addRulesetFields,
      pressAdd: () => dispatch(addRuleset()),
    },
    tags: {
      deleteFunction: (id) => dispatch(removeTag(id)),
      addRowContent: addTagFields,
      pressAdd: () => dispatch(addTag()),
    },
  };

  const tableData = getTableData(
    useTypedSelector((state) => state.products.parameters),
    parameterType,
    mapParameterTypeToTables[parameterType].deleteFunction
  );
  return (
    <Wrapper className={className}>
      <HeaderWrapper>
        <StyledH4 bold>{header}</StyledH4>
        <StyledAddIcon onClick={handlePressAdd} />
      </HeaderWrapper>
      <Table
        data={tableData}
        canAddRow={showAddRow}
        addRowAction={mapParameterTypeToTables[parameterType].pressAdd}
        addRowContent={mapParameterTypeToTables[parameterType].addRowContent}
        addRowButtonLabel={addRowButtonLabel}
        size={"small"}
      />
    </Wrapper>
  );
}

export default ParametersTable;

export const StyledH4 = styled(H4)<any>`
  color: ${(props) => props.theme.colors.muttedHeadlines};
  margin-right: 16px;
`;

const StyledAddIcon = styled(Add)<any>`
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Wrapper = styled.div<any>``;
