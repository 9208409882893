import React from "react";
import { Modal } from "../Modal/Modal.component";
import { Button } from "../Buttons/Button.component";

export function ConfirmActionModal({
  displayConfirmationModal,
  setDisplayConfirmationModal,
  confirmAction,
  message,
}: {
  displayConfirmationModal: boolean;
  setDisplayConfirmationModal: any;
  confirmAction: any;
  message: string;
}) {
  return (
    <>
      {displayConfirmationModal && (
        <Modal
          open={displayConfirmationModal}
          closeModal={() => setDisplayConfirmationModal(false)}
          title={"Confirm action"}
        >
          {message}
          <Button size={"small"} onClick={confirmAction}>
            confirm
          </Button>
        </Modal>
      )}
    </>
  );
}
