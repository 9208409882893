import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "./helper";

export type HeaderElement = {
  content: any;
  style?: any;
  id?: string;
  isDraggable?: boolean;
};

export function TableHeader({
  header = [],
  onDragEndAction,
}: {
  header: HeaderElement[];
  onDragEndAction?: any;
}) {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      header,
      result.source.index,
      result.destination.index
    );

    if (!onDragEndAction) {
      console.error("You tried to drag items without any action specified.");
    }

    onDragEndAction(items);
  };

  return (
    <thead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"list" + "header"} direction="horizontal">
          {(provided, snapshot) => (
            <tr
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {header.map(
                ({ content, style, id, isDraggable = false }, index) => {
                  const uniqueId = id || content;
                  return (
                    <Draggable
                      draggableId={uniqueId}
                      index={index}
                      key={uniqueId}
                      isDragDisabled={!isDraggable}
                    >
                      {(provided, snapshot) => {
                        return (
                          <HeaderCell
                            style={style}
                            key={uniqueId}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            isDraggable={isDraggable}
                          >
                            {content}
                          </HeaderCell>
                        );
                      }}
                    </Draggable>
                  );
                }
              )}
              {provided.placeholder}
            </tr>
          )}
        </Droppable>
      </DragDropContext>
    </thead>
  );
}

// this is only to accomodate specific conditional style, the TableHeader style is in ./Table.component.tsx
const HeaderCell = styled.th<any>`
  background-color: ${(props) =>
    props.isDragging ? "orange !important" : "inherit"};
`;
